/* ================================================================
   Page Meta Area
    - Page Tools/ Social Media
   ================================================================ */





/* Page Tools (Print | Email | Share)
   ================================================================ */
.top-social-share .social-share-container{
	text-align: right;
	margin: 15px 0 25px;
	padding: 20px 0;

	// 767
	@media (max-width: $bp-sw-max){
		display: none;
		visibility: hidden;
	}

	// 768
	@media (min-width: $bp-m){
		justify-content: flex-end;
	}
	@media(max-width:880px) {
		display:none;
	}

	// 768 - 959
	@media (min-width: $bp-m) and (max-width: $bp-m-max){
		.at-resp-share-element.at-mobile .at-share-btn {padding: 0 !important;}
	}
}

.top-social-share footer.social-share-container {
	justify-content: flex-start;
}

.social-share-container {
	display: flex;
	opacity: 0;
	transform: translateX(-15px);
	transition: all 0.35s ease-in 0.8s;

	&.loaded{
		opacity: 1;
        transform: translateX(0);
	}

	p {@include x-rem(font-size,14px);margin: 0;}

	// 767
	@media (max-width: $bp-sw-max){
		flex-direction: column;
	}

	// 768
	@media (min-width: $bp-m){
		align-items: center;
	}
}

.at-share-btn-elements {
	svg.at-icon {fill: #1286c0;}
}

.social-share {
	padding:0;
	text-align:right;
	display: flex;
	margin-top: 0;

	li { display:inline-block;
		margin: 0 7px;
		order: 2;

		a { display:block;
			svg { width:20px; height:20px }
		}

		&.share-print {
			display: none;
			visibility: hidden;
		}

		&.share-facebook {
			svg,path { fill:$color-secondary }
		}
		&.share-twitter {
			svg,path { fill:$color-secondary }
		}
		&.share-linkedin {
			svg,path { fill:$color-secondary; }
		}
		&.share-reddit {
			svg,path { fill:$color-secondary; }
		}
		&.share-digg {
			svg {  }
		}
		&.share-mail {
			order: 1;
			svg,path { fill:$color-secondary }
		}
		&.share-tumblr {
			svg,path { fill:$color-secondary }
		}
		&.share-googleplus {
			svg,path { fill:$color-secondary; }
		}
		&.share-stumbleupon {
			svg,path { fill:$color-secondary; }
		}
		&.share-bloggr {
			svg,path { fill:$color-secondary; }
		}
	}
}
