.sr-only {
	position: absolute;
	width: .0625rem;
	height: .0625rem;
	padding: 0;
	margin: -.0625rem;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0
}

.sr-only-focusable:active, .sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto
}

:root {
	--yxt-color-background-highlight: #fafafa;
	--yxt-color-background-dark: #a8a8a8;
	--yxt-color-brand-primary: #0f70f0;
	--yxt-color-brand-hover: #0c5ecb;
	--yxt-color-brand-white: #fff;
	--yxt-color-text-primary: #212121;
	--yxt-color-text-secondary: #757575;
	--yxt-color-text-neutral: #616161;
	--yxt-color-link-primary: var(--yxt-color-brand-primary);
	--yxt-color-borders: #dcdcdc;
	--yxt-color-error: #940000;
	--yxt-font-weight-bold: 700;
	--yxt-font-weight-semibold: 600;
	--yxt-font-weight-medium: 500;
	--yxt-font-weight-normal: 400;
	--yxt-font-weight-light: 300;
	--yxt-font-size-xs: 0.625rem;
	--yxt-font-size-sm: 0.75rem;
	--yxt-font-size-md: 0.875rem;
	--yxt-font-size-md-lg: 1rem;
	--yxt-font-size-lg: 1.125rem;
	--yxt-font-size-xlg: 1.25rem;
	--yxt-font-size-xxlg: 2.5rem;
	--yxt-line-height-xs: 1;
	--yxt-line-height-sm: 1.2;
	--yxt-line-height-md-sm: 1.33333;
	--yxt-line-height-md: 1.4;
	--yxt-line-height-lg: 1.5;
	--yxt-line-height-xlg: 1.66667;
	--yxt-line-height-xxlg: 1.7;
	--yxt-font-family: system-ui,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif;
	--yxt-base-spacing-sm: 0.75rem;
	--yxt-base-spacing: 1rem;
	--yxt-module-footer-height: 1.5rem;
	--yxt-module-container-height: 1.25rem;
	--yxt-border-default: 0.0625rem solid var(--yxt-color-borders);
	--yxt-border-hover: 0.0625rem solid var(--yxt-color-brand-hover);
	--yxt-border-legacy: 0.0625rem solid #e9e9e9;
	--yxt-z-index-nav-more-modal: 2;
	--yxt-button-focus-border-size: 0.1875rem;
	--yxt-cards-min-width: 13.125rem;
	--yxt-container-desktop-base: 25rem;
	--yxt-searchbar-form-background-color: #fff;
	--yxt-searchbar-form-outline-color-base: var(--yxt-color-borders);
	--yxt-searchbar-focus-shadow-height: 0.625rem;
	--yxt-searchbar-focus-shadow: 0 0 var(--yxt-searchbar-focus-shadow-height) 0 rgba(0,0,0,0.1);
	--yxt-searchbar-form-border-radius: calc(0.4*var(--yxt-base-spacing));
	--yxt-searchbar-text-color: var(--yxt-color-text-primary);
	--yxt-searchbar-text-font-size: var(--yxt-font-size-md-lg);
	--yxt-searchbar-text-line-height: var(--yxt-line-height-lg);
	--yxt-searchbar-text-font-weight: var(--yxt-font-weight-normal);
	--yxt-searchbar-button-background-color-base: #fff;
	--yxt-searchbar-button-background-color-hover: #e9e9e9;
	--yxt-searchbar-button-background-color-active: var(--yxt-searchbar-button-background-color-base);
	--yxt-searchbar-button-text-color-base: var(--yxt-color-text-primary);
	--yxt-searchbar-button-text-color-active: var(--yxt-searchbar-button-text-color-base)
}

.yxt-Answers-component {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.yxt-Answers-component :focus {
	outline: .0625rem solid #000
}

.yxt-Answers-component input[type=checkbox]:focus {
	outline: .0625rem solid #000
}

.yxt-GeoLocationFilter, .yxt-SearchBar {
	width: 100%;
	height: 3.1875rem;
	margin-bottom: 0
}

.yxt-GeoLocationFilter button[class*=yxt-], .yxt-GeoLocationFilter input[class*=yxt-], .yxt-SearchBar button[class*=yxt-], .yxt-SearchBar input[class*=yxt-] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none
}

.yxt-GeoLocationFilter-container, .yxt-SearchBar-container {
	position: relative;
	overflow: hidden;
	z-index: 1;
	border-radius: var(--yxt-searchbar-form-border-radius);
	border: .0625rem solid var(--yxt-searchbar-form-outline-color-base);
	background-color: var(--yxt-searchbar-form-background-color)
}

.yxt-GeoLocationFilter-container:focus-within, .yxt-GeoLocationFilter-container:hover, .yxt-SearchBar-container:focus-within, .yxt-SearchBar-container:hover {
	-webkit-box-shadow: var(--yxt-searchbar-focus-shadow);
	box-shadow: var(--yxt-searchbar-focus-shadow)
}

.yxt-GeoLocationFilter-form, .yxt-GeoLocationFilter-input-wrapper, .yxt-SearchBar-form, .yxt-SearchBar-input-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: 0
}

.yxt-GeoLocationFilter-input, .yxt-SearchBar-input {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-searchbar-text-font-size);
	line-height: var(--yxt-searchbar-text-line-height);
	font-weight: var(--yxt-searchbar-text-font-weight);
	font-style: normal;
	color: var(--yxt-searchbar-text-color);
	border: none;
	background-color: transparent;
	width: 100%;
	padding: calc(var(--yxt-base-spacing)/2) var(--yxt-base-spacing)
}

.yxt-GeoLocationFilter-input::-ms-clear, .yxt-SearchBar-input::-ms-clear {
	display: none;
	padding: 0;
	margin: 0
}

.yxt-GeoLocationFilter-input:-ms-input-placeholder, .yxt-SearchBar-input:-ms-input-placeholder {
	color: var(--yxt-color-text-secondary)
}

.yxt-GeoLocationFilter-clear, .yxt-SearchBar-clear {
	padding-left: calc(var(--yxt-base-spacing)/2);
	padding-right: calc(var(--yxt-base-spacing)/2)
}

.yxt-GeoLocationFilter-button, .yxt-SearchBar-button {
	padding-left: var(--yxt-base-spacing);
	padding-right: var(--yxt-base-spacing)
}

.yxt-GeoLocationFilter-button, .yxt-GeoLocationFilter-clear, .yxt-SearchBar-button, .yxt-SearchBar-clear {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font: inherit;
	padding-top: calc(var(--yxt-base-spacing)/2);
	padding-bottom: calc(var(--yxt-base-spacing)/2);
	background-color: var(--yxt-searchbar-button-background-color-base);
	color: var(--yxt-color-text-secondary);
	cursor: pointer;
	border: none
}

.yxt-GeoLocationFilter-button:focus, .yxt-GeoLocationFilter-clear:focus, .yxt-SearchBar-button:focus, .yxt-SearchBar-clear:focus {
	background-color: var(--yxt-searchbar-button-background-color-hover) !important;
}

.yxt-GeoLocationFilter--hidden, .yxt-SearchBar--hidden {
	display: none
}

.yxt-GeoLocationFilter-button:focus, .yxt-GeoLocationFilter-input:focus, .yxt-SearchBar-button:focus, .yxt-SearchBar-input:focus {
	outline: .0625rem solid #000
}

.yxt-GeoLocationFilter-label, .yxt-SearchBar-label {
	position: absolute;
	width: .0625rem;
	height: .0625rem;
	padding: 0;
	margin: -.0625rem;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0
}

.yxt-SearchBar-title {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-xlg);
	line-height: var(--yxt-line-height-md);
	font-weight: var(--yxt-font-weight-semibold);
	font-style: normal;
	color: var(--yxt-color-text-primary);
	margin-bottom: var(--yxt-base-spacing)
}

@media (max-width: 47.9375rem) {
	.yxt-SearchBar-title {
		margin-left: var(--yxt-base-spacing)
	}
}

.yxt-SearchBar-AnimatedIcon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.yxt-SearchBar-AnimatedIcon--inactive svg {
	display: none
}

.yxt-SearchBar-AnimatedIcon--paused svg > g {
	-webkit-animation-play-state: paused;
	animation-play-state: paused
}

.yxt-GeoLocationFilter-title {
	position: absolute;
	width: .0625rem;
	height: .0625rem;
	padding: 0;
	margin: -.0625rem;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0
}

.Icon {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center
}

.Icon svg {
	height: 1em;
	width: 1em;
	fill: currentColor
}

.Icon--sm img, .Icon--sm svg {
	height: .5em;
	width: .5em
}

.Icon--lg img, .Icon--lg svg {
	height: 2em;
	width: 2em
}

.Icon-collapseUp svg {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg)
}

.Icon-collapseDown svg {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg)
}

.Icon-image {
	width: 1em;
	height: 1em;
	-o-object-fit: contain;
	object-fit: contain
}

.Icon--yext_animated_reverse svg {
	color: #000;
	background: hsla(0,0%,100%,0)
}

@-webkit-keyframes reverse-a0_o {
	0% {
		opacity: 0
	}

	89.82% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

@keyframes reverse-a0_o {
	0% {
		opacity: 0
	}

	89.82% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

@-webkit-keyframes reverse-a1_o {
	0% {
		opacity: 0
	}

	80.24% {
		opacity: 0
	}

	89.82% {
		opacity: 1
	}

	to {
		opacity: 0
	}
}

@keyframes reverse-a1_o {
	0% {
		opacity: 0
	}

	80.24% {
		opacity: 0
	}

	89.82% {
		opacity: 1
	}

	to {
		opacity: 0
	}
}

@-webkit-keyframes reverse-a2_o {
	0% {
		opacity: 0
	}

	70.06% {
		opacity: 0
	}

	80.24% {
		opacity: 1
	}

	89.82% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@keyframes reverse-a2_o {
	0% {
		opacity: 0
	}

	70.06% {
		opacity: 0
	}

	80.24% {
		opacity: 1
	}

	89.82% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@-webkit-keyframes reverse-a3_o {
	0% {
		opacity: 0
	}

	59.88% {
		opacity: 0
	}

	70.06% {
		opacity: 1
	}

	80.24% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@keyframes reverse-a3_o {
	0% {
		opacity: 0
	}

	59.88% {
		opacity: 0
	}

	70.06% {
		opacity: 1
	}

	80.24% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@-webkit-keyframes reverse-a4_o {
	0% {
		opacity: 0
	}

	50.299% {
		opacity: 0
	}

	59.88% {
		opacity: 1
	}

	70.06% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@keyframes reverse-a4_o {
	0% {
		opacity: 0
	}

	50.299% {
		opacity: 0
	}

	59.88% {
		opacity: 1
	}

	70.06% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@-webkit-keyframes reverse-a5_o {
	0% {
		opacity: 0
	}

	40.12% {
		opacity: 0
	}

	50.299% {
		opacity: 1
	}

	59.88% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@keyframes reverse-a5_o {
	0% {
		opacity: 0
	}

	40.12% {
		opacity: 0
	}

	50.299% {
		opacity: 1
	}

	59.88% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@-webkit-keyframes reverse-a6_o {
	0% {
		opacity: 0
	}

	29.94% {
		opacity: 0
	}

	40.12% {
		opacity: 1
	}

	50.299% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@keyframes reverse-a6_o {
	0% {
		opacity: 0
	}

	29.94% {
		opacity: 0
	}

	40.12% {
		opacity: 1
	}

	50.299% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@-webkit-keyframes reverse-a7_o {
	0% {
		opacity: 0
	}

	20.359% {
		opacity: 0
	}

	29.94% {
		opacity: 1
	}

	40.12% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@keyframes reverse-a7_o {
	0% {
		opacity: 0
	}

	20.359% {
		opacity: 0
	}

	29.94% {
		opacity: 1
	}

	40.12% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@-webkit-keyframes reverse-a8_o {
	0% {
		opacity: 0
	}

	10.18% {
		opacity: 0
	}

	20.359% {
		opacity: 1
	}

	29.94% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@keyframes reverse-a8_o {
	0% {
		opacity: 0
	}

	10.18% {
		opacity: 0
	}

	20.359% {
		opacity: 1
	}

	29.94% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@-webkit-keyframes reverse-a9_o {
	0% {
		opacity: 0
	}

	10.18% {
		opacity: 1
	}

	20.359% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@keyframes reverse-a9_o {
	0% {
		opacity: 0
	}

	10.18% {
		opacity: 1
	}

	20.359% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@-webkit-keyframes reverse-a10_o {
	0% {
		opacity: 1
	}

	10.18% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@keyframes reverse-a10_o {
	0% {
		opacity: 1
	}

	10.18% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

.Icon--yext_animated_reverse svg > g {
	-webkit-animation-duration: .18s;
	animation-duration: .18s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both
}

.Icon--yext_animated_reverse svg > g:first-of-type {
	-webkit-animation-name: reverse-a0_o;
	animation-name: reverse-a0_o
}

.Icon--yext_animated_reverse svg > g:nth-of-type(2) {
	-webkit-animation-name: reverse-a1_o;
	animation-name: reverse-a1_o
}

.Icon--yext_animated_reverse svg > g:nth-of-type(3) {
	-webkit-animation-name: reverse-a2_o;
	animation-name: reverse-a2_o
}

.Icon--yext_animated_reverse svg > g:nth-of-type(4) {
	-webkit-animation-name: reverse-a3_o;
	animation-name: reverse-a3_o
}

.Icon--yext_animated_reverse svg > g:nth-of-type(5) {
	-webkit-animation-name: reverse-a4_o;
	animation-name: reverse-a4_o
}

.Icon--yext_animated_reverse svg > g:nth-of-type(6) {
	-webkit-animation-name: reverse-a5_o;
	animation-name: reverse-a5_o
}

.Icon--yext_animated_reverse svg > g:nth-of-type(7) {
	-webkit-animation-name: reverse-a6_o;
	animation-name: reverse-a6_o
}

.Icon--yext_animated_reverse svg > g:nth-of-type(8) {
	-webkit-animation-name: reverse-a7_o;
	animation-name: reverse-a7_o
}

.Icon--yext_animated_reverse svg > g:nth-of-type(9) {
	-webkit-animation-name: reverse-a8_o;
	animation-name: reverse-a8_o
}

.Icon--yext_animated_reverse svg > g:nth-of-type(10) {
	-webkit-animation-name: reverse-a9_o;
	animation-name: reverse-a9_o
}

.Icon--yext_animated_reverse svg > g:nth-of-type(11) {
	-webkit-animation-name: reverse-a10_o;
	animation-name: reverse-a10_o
}

.Icon--yext_animated_forward svg {
	color: #000;
	background: hsla(0,0%,100%,0)
}

@-webkit-keyframes forward-a0_o {
	0% {
		opacity: 1
	}

	9.29% {
		opacity: 1
	}

	18.033% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@keyframes forward-a0_o {
	0% {
		opacity: 1
	}

	9.29% {
		opacity: 1
	}

	18.033% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@-webkit-keyframes forward-a1_o {
	0% {
		opacity: 0
	}

	9.29% {
		opacity: 0
	}

	18.033% {
		opacity: 1
	}

	27.322% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@keyframes forward-a1_o {
	0% {
		opacity: 0
	}

	9.29% {
		opacity: 0
	}

	18.033% {
		opacity: 1
	}

	27.322% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@-webkit-keyframes forward-a2_o {
	0% {
		opacity: 0
	}

	18.033% {
		opacity: 0
	}

	27.322% {
		opacity: 1
	}

	36.612% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@keyframes forward-a2_o {
	0% {
		opacity: 0
	}

	18.033% {
		opacity: 0
	}

	27.322% {
		opacity: 1
	}

	36.612% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@-webkit-keyframes forward-a3_o {
	0% {
		opacity: 0
	}

	27.322% {
		opacity: 0
	}

	36.612% {
		opacity: 1
	}

	45.355% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@keyframes forward-a3_o {
	0% {
		opacity: 0
	}

	27.322% {
		opacity: 0
	}

	36.612% {
		opacity: 1
	}

	45.355% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@-webkit-keyframes forward-a4_o {
	0% {
		opacity: 0
	}

	36.612% {
		opacity: 0
	}

	45.355% {
		opacity: 1
	}

	54.645% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@keyframes forward-a4_o {
	0% {
		opacity: 0
	}

	36.612% {
		opacity: 0
	}

	45.355% {
		opacity: 1
	}

	54.645% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@-webkit-keyframes forward-a5_o {
	0% {
		opacity: 0
	}

	45.355% {
		opacity: 0
	}

	54.645% {
		opacity: 1
	}

	63.934% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@keyframes forward-a5_o {
	0% {
		opacity: 0
	}

	45.355% {
		opacity: 0
	}

	54.645% {
		opacity: 1
	}

	63.934% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@-webkit-keyframes forward-a6_o {
	0% {
		opacity: 0
	}

	54.645% {
		opacity: 0
	}

	63.934% {
		opacity: 1
	}

	72.678% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@keyframes forward-a6_o {
	0% {
		opacity: 0
	}

	54.645% {
		opacity: 0
	}

	63.934% {
		opacity: 1
	}

	72.678% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@-webkit-keyframes forward-a7_o {
	0% {
		opacity: 0
	}

	63.934% {
		opacity: 0
	}

	72.678% {
		opacity: 1
	}

	81.967% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@keyframes forward-a7_o {
	0% {
		opacity: 0
	}

	63.934% {
		opacity: 0
	}

	72.678% {
		opacity: 1
	}

	81.967% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@-webkit-keyframes forward-a8_o {
	0% {
		opacity: 0
	}

	72.678% {
		opacity: 0
	}

	81.967% {
		opacity: 1
	}

	91.257% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@keyframes forward-a8_o {
	0% {
		opacity: 0
	}

	72.678% {
		opacity: 0
	}

	81.967% {
		opacity: 1
	}

	91.257% {
		opacity: 0
	}

	to {
		opacity: 0
	}
}

@-webkit-keyframes forward-a9_o {
	0% {
		opacity: 0
	}

	81.967% {
		opacity: 0
	}

	91.257% {
		opacity: 1
	}

	to {
		opacity: 0
	}
}

@keyframes forward-a9_o {
	0% {
		opacity: 0
	}

	81.967% {
		opacity: 0
	}

	91.257% {
		opacity: 1
	}

	to {
		opacity: 0
	}
}

@-webkit-keyframes forward-a10_o {
	0% {
		opacity: 0
	}

	91.257% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

@keyframes forward-a10_o {
	0% {
		opacity: 0
	}

	91.257% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

.Icon--yext_animated_forward svg > g {
	-webkit-animation-duration: .18s;
	animation-duration: .18s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both
}

.Icon--yext_animated_forward svg > g:first-of-type {
	-webkit-animation-name: forward-a0_o;
	animation-name: forward-a0_o
}

.Icon--yext_animated_forward svg > g:nth-of-type(2) {
	-webkit-animation-name: forward-a1_o;
	animation-name: forward-a1_o
}

.Icon--yext_animated_forward svg > g:nth-of-type(3) {
	-webkit-animation-name: forward-a2_o;
	animation-name: forward-a2_o
}

.Icon--yext_animated_forward svg > g:nth-of-type(4) {
	-webkit-animation-name: forward-a3_o;
	animation-name: forward-a3_o
}

.Icon--yext_animated_forward svg > g:nth-of-type(5) {
	-webkit-animation-name: forward-a4_o;
	animation-name: forward-a4_o
}

.Icon--yext_animated_forward svg > g:nth-of-type(6) {
	-webkit-animation-name: forward-a5_o;
	animation-name: forward-a5_o
}

.Icon--yext_animated_forward svg > g:nth-of-type(7) {
	-webkit-animation-name: forward-a6_o;
	animation-name: forward-a6_o
}

.Icon--yext_animated_forward svg > g:nth-of-type(8) {
	-webkit-animation-name: forward-a7_o;
	animation-name: forward-a7_o
}

.Icon--yext_animated_forward svg > g:nth-of-type(9) {
	-webkit-animation-name: forward-a8_o;
	animation-name: forward-a8_o
}

.Icon--yext_animated_forward svg > g:nth-of-type(10) {
	-webkit-animation-name: forward-a9_o;
	animation-name: forward-a9_o
}

.Icon--yext_animated_forward svg > g:nth-of-type(11) {
	-webkit-animation-name: forward-a10_o;
	animation-name: forward-a10_o
}

:root {
	--yxt-nav-text-font-size: var(--yxt-font-size-md);
	--yxt-nav-text-line-height: var(--yxt-line-height-md);
	--yxt-nav-text-font-weight: var(--yxt-font-weight-semibold);
	--yxt-nav-text-color: var(--yxt-color-text-primary);
	--yxt-nav-text-hover-color: #c5cace;
	--yxt-nav-text-active-color: var(--yxt-color-brand-primary);
	--yxt-nav-text-active-border-width: 0.125rem;
	--yxt-nav-text-active-border: var(--yxt-nav-text-active-border-width) solid var(--yxt-color-brand-primary);
	--yxt-nav-text-focus-background-color: #e9e9e9;
	--yxt-nav-dropdown-font-size: 0.8125rem;
	--yxt-nav-dropdown-line-height: var(--yxt-line-height-sm);
	--yxt-nav-dropdown-font-weight: var(--yxt-font-weight-semibold);
	--yxt-nav-dropdown-color: var(--yxt-nav-text-color);
	--yxt-nav-dropdown-hover-color: var(--yxt-nav-dropdown-color);
	--yxt-nav-dropdown-hover-background-color: #eeeff0;
	--yxt-nav-dropdown-width: 15.625rem;
	--yxt-nav-border-color: var(--yxt-color-borders)
}

.yxt-Nav-container {
	position: relative;
	border-bottom: var(--yxt-nav-text-active-border-width) solid var(--yxt-nav-border-color)
}

.yxt-Nav-container, .yxt-Nav-expanded {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start
}

.yxt-Nav-expanded {
	white-space: nowrap;
	margin: 0;
	padding: 0;
	list-style-type: none
}

.yxt-Nav-item {
	margin-bottom: -.125rem;
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-nav-text-font-size);
	line-height: var(--yxt-nav-text-line-height);
	font-weight: var(--yxt-nav-text-font-weight);
	font-style: normal;
	color: var(--yxt-nav-text-color);
	padding-top: .375rem;
	padding-bottom: .375rem;
	padding-left: var(--yxt-base-spacing);
	padding-right: var(--yxt-base-spacing);
	text-transform: uppercase;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-decoration: none
}

.yxt-Nav-item:hover {
	color: var(--yxt-nav-text-active-color)
}

@media (hover: none) {
	.yxt-Nav-item:hover {
		color: var(--yxt-nav-text-color)
	}
}

.yxt-Nav-item:focus {
	background-color: var(--yxt-nav-text-focus-background-color);
	color: var(--yxt-nav-text-color)
}

.yxt-Nav-item:not(:last-child) {
	-ms-flex-negative: 0;
	flex-shrink: 0
}

.yxt-Nav-item--more {
	position: absolute;
	visibility: hidden
}

.yxt-Nav-item.is-active {
	color: var(--yxt-nav-text-active-color);
	border-bottom: var(--yxt-nav-text-active-border);
	font-weight: var(--yxt-font-weight-semibold);
	padding-bottom: calc(.375rem + var(--yxt-nav-text-active-border-width))
}

.yxt-Nav-moreContainer {
	margin-left: auto;
	position: relative
}

.yxt-Nav-more {
	position: relative;
	overflow: visible;
	border: none;
	background: transparent
}

.yxt-Nav-more, .yxt-Nav-moreTextWrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.yxt-Nav-moreIcon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-top: .0625rem;
	margin-right: .4375rem
}

.yxt-Nav-moreIcon .Icon--kabob {
	height: .6875rem;
	width: .1875rem;
	margin-top: .0625rem
}

.yxt-Nav-static {
	overflow: auto
}

.yxt-Nav-static::-webkit-scrollbar {
	display: none
}

.yxt-Nav-modal {
	display: none;
	position: absolute;
	background-color: #fff;
	-webkit-box-shadow: 0 .125rem .625rem 0 rgba(0,0,0,.2);
	box-shadow: 0 .125rem .625rem 0 rgba(0,0,0,.2);
	border-radius: .5rem;
	top: 1.875rem;
	right: 0;
	z-index: var(--yxt-z-index-nav-more-modal);
	list-style-type: none;
	margin: 0;
	padding: 0;
	width: var(--yxt-nav-dropdown-width);
	word-break: break-all;
	white-space: normal
}

.yxt-Nav-modal.is-active {
	display: block
}

.yxt-Nav-dropDownItem {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-nav-dropdown-font-size);
	line-height: var(--yxt-nav-dropdown-line-height);
	font-weight: var(--yxt-nav-dropdown-font-weight);
	font-style: normal;
	color: var(--yxt-nav-dropdown-color);
	display: block;
	padding: .375rem 1rem .25rem;
	text-align: left
}

.yxt-Nav-dropDownItem:focus, .yxt-Nav-dropDownItem:hover {
	color: var(--yxt-nav-dropdown-hover-color);
	background-color: var(--yxt-nav-dropdown-hover-background-color)
}

:root {
	--yxt-direct-answer-border: var(--yxt-border-default);
	--yxt-direct-answer-title-background-color: var(--yxt-color-brand-primary);
	--yxt-direct-answer-content-background-color: #fff;
	--yxt-direct-answer-footer-background-color: var(--yxt-color-background-highlight);
	--yxt-direct-answer-title-font-size: var(--yxt-font-size-md-lg);
	--yxt-direct-answer-title-line-height: var(--yxt-line-height-lg);
	--yxt-direct-answer-title-font-weight: var(--yxt-font-weight-semibold);
	--yxt-direct-answer-title-color: #fff;
	--yxt-direct-answer-content-font-size: var(--yxt-font-size-xlg);
	--yxt-direct-answer-content-line-height: var(--yxt-line-height-md);
	--yxt-direct-answer-content-font-weight: var(--yxt-font-weight-semibold);
	--yxt-direct-answer-content-color: var(--yxt-color-text-primary);
	--yxt-direct-answer-view-details-font-size: var(--yxt-font-size-md);
	--yxt-direct-answer-view-details-line-height: var(--yxt-line-height-xxlg);
	--yxt-direct-answer-view-details-font-weight: var(--yxt-font-weight-semibold);
	--yxt-direct-answer-footer-font-size: var(--yxt-font-size-md);
	--yxt-direct-answer-footer-line-height: var(--yxt-line-height-md);
	--yxt-direct-answer-footer-font-weight: var(--yxt-font-weight-normal);
	--yxt-direct-answer-footer-color: var(--yxt-color-text-secondary);
	--yxt-direct-answer-footer-height: var(--yxt-module-footer-height)
}

.yxt-DirectAnswer-title {
	padding: calc(var(--yxt-base-spacing)/2) var(--yxt-base-spacing);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: var(--yxt-direct-answer-title-background-color);
	border: var(--yxt-direct-answer-border);
	font-size: var(--yxt-font-size-md);
	line-height: var(--yxt-line-height-xs);
	font-weight: var(--yxt-font-weight-normal)
}

.yxt-DirectAnswer-title, .yxt-DirectAnswer-titleText {
	font-family: var(--yxt-font-family);
	font-style: normal;
	color: var(--yxt-direct-answer-title-color)
}

.yxt-DirectAnswer-titleText {
	margin: 0;
	font-size: var(--yxt-direct-answer-title-font-size);
	line-height: var(--yxt-direct-answer-title-line-height);
	font-weight: var(--yxt-direct-answer-title-font-weight)
}

.yxt-DirectAnswer-titleIconWrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-right: calc(var(--yxt-base-spacing)/2)
}

.yxt-DirectAnswer-titleIconWrapper.yxt-Results-titleIconWrapper {
	color: inherit
}

.yxt-DirectAnswer-entityName {
	text-transform: uppercase
}

.yxt-DirectAnswer-iconWrapper {
	margin-right: var(--yxt-base-spacing-sm);
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex
}

.yxt-DirectAnswer-content {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: var(--yxt-base-spacing);
	background-color: var(--yxt-direct-answer-content-background-color);
	border-left: var(--yxt-direct-answer-border);
	border-right: var(--yxt-direct-answer-border)
}

.yxt-DirectAnswer-footerWrapper {
	background-color: var(--yxt-direct-answer-footer-background-color)
}

.yxt-DirectAnswer-footer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	height: var(--yxt-direct-answer-footer-height);
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-left: var(--yxt-base-spacing);
	padding-right: var(--yxt-base-spacing);
	padding-top: .625rem;
	padding-bottom: .625rem;
	border: var(--yxt-direct-answer-border)
}

.yxt-DirectAnswer-footerText {
	margin-right: var(--yxt-base-spacing);
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-direct-answer-footer-font-size);
	line-height: var(--yxt-direct-answer-footer-line-height);
	font-weight: var(--yxt-direct-answer-footer-font-weight);
	font-style: normal;
	color: var(--yxt-direct-answer-footer-color);
	font-style: italic
}

.yxt-DirectAnswer-thumbs {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: 0
}

.yxt-DirectAnswer-thumbUpIcon {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg)
}

.yxt-DirectAnswer-thumbDownIcon, .yxt-DirectAnswer-thumbUpIcon {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	color: var(--yxt-color-text-secondary)
}

.yxt-DirectAnswer-thumb {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	cursor: pointer;
	font-size: 1.5rem
}

.yxt-DirectAnswer-thumb + .yxt-DirectAnswer-thumb {
	margin-left: .625rem
}

.yxt-DirectAnswer-fieldValue {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-direct-answer-content-font-size);
	line-height: var(--yxt-direct-answer-content-line-height);
	font-weight: var(--yxt-direct-answer-content-font-weight);
	font-style: normal;
	color: var(--yxt-direct-answer-content-color);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start
}

.yxt-DirectAnswer-fieldValueLink {
	font-weight: var(--yxt-font-weight-semibold)
}

.yxt-DirectAnswer-fieldValueLink:link, .yxt-DirectAnswer-fieldValueLink:visited {
	color: var(--yxt-color-brand-primary);
	text-decoration: none
}

.yxt-DirectAnswer-fieldValueLink:active, .yxt-DirectAnswer-fieldValueLink:focus, .yxt-DirectAnswer-fieldValueLink:hover {
	color: var(--yxt-color-brand-primary);
	text-decoration: underline
}

.yxt-DirectAnswer-feedback {
	display: none
}

.yxt-DirectAnswer-viewMore {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-direct-answer-view-details-font-size);
	line-height: var(--yxt-direct-answer-view-details-line-height);
	font-weight: var(--yxt-direct-answer-view-details-font-weight);
	font-style: normal;
	color: var(--yxt-color-text-primary);
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.yxt-DirectAnswer-viewMore:link, .yxt-DirectAnswer-viewMore:visited {
	color: var(--yxt-color-brand-primary);
	text-decoration: none
}

.yxt-DirectAnswer-viewMore:active, .yxt-DirectAnswer-viewMore:focus, .yxt-DirectAnswer-viewMore:hover {
	color: var(--yxt-color-brand-primary);
	text-decoration: underline
}

.yxt-DirectAnswer-viewMoreIcon {
	margin-left: .25rem
}

:root {
	--yxt-results-title-bar-background: var(--yxt-color-background-highlight);
	--yxt-results-filters-background: #fff;
	--yxt-results-view-more-background: var(--yxt-color-background-highlight);
	--yxt-results-title-bar-text-color: var(--yxt-color-text-primary);
	--yxt-results-title-bar-text-font-size: var(--yxt-font-size-md-lg);
	--yxt-results-title-bar-text-line-height: var(--yxt-line-height-lg);
	--yxt-results-title-bar-text-font-weight: var(--yxt-font-weight-bold);
	--yxt-results-title-bar-link-font-size: var(--yxt-font-size-md);
	--yxt-results-title-bar-link-line-height: var(--yxt-line-height-xxlg);
	--yxt-results-title-bar-link-font-weight: var(--yxt-font-weight-semibold);
	--yxt-results-filters-text-color: var(--yxt-color-text-primary);
	--yxt-results-filters-text-font-size: var(--yxt-font-size-md);
	--yxt-results-filters-text-line-height: var(--yxt-line-height-md);
	--yxt-results-filters-text-font-weight: var(--yxt-font-weight-normal);
	--yxt-results-filters-link-font-size: var(--yxt-font-size-md);
	--yxt-results-filters-link-line-height: var(--yxt-line-height-md);
	--yxt-results-filters-link-font-weight: var(--yxt-font-weight-normal);
	--yxt-results-border: var(--yxt-border-default);
	--yxt-results-cards-margin: calc(var(--yxt-base-spacing)/2)
}

.yxt-Results {
	border-bottom: 0;
	width: 100%
}

.yxt-Results + .yxt-Results {
	margin-top: var(--yxt-base-spacing)
}

.yxt-Results-left {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.yxt-Results-left, .yxt-Results-titleIconWrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.yxt-Results-titleIconWrapper {
	margin-right: calc(var(--yxt-base-spacing)/2);
	color: var(--yxt-color-brand-primary)
}

.yxt-Results-filters {
	margin-top: 0;
	margin-bottom: 0;
	padding: calc(var(--yxt-base-spacing)/2) var(--yxt-base-spacing);
	background-color: var(--yxt-results-filters-background);
	border-top: var(--yxt-results-border);
	border-right: var(--yxt-border-default);
	border-left: var(--yxt-border-default);
	border-bottom: none
}

.yxt-Results-filter, .yxt-Results-filters {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.yxt-Results-filter {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.yxt-Results-filter:not(:first-child):before {
	content: "";
	width: .0625rem;
	top: 0;
	height: 40%;
	margin-left: var(--yxt-base-spacing);
	margin-right: var(--yxt-base-spacing);
	display: inline-block
}

.yxt-Results-filterValue {
	font-size: var(--yxt-results-filters-text-font-size);
	line-height: var(--yxt-results-filters-text-line-height);
	font-weight: var(--yxt-results-filters-text-font-weight);
	color: var(--yxt-results-filters-text-color)
}

.yxt-Results-changeFilters, .yxt-Results-filterValue {
	font-family: var(--yxt-font-family);
	font-style: normal
}

.yxt-Results-changeFilters {
	font-size: var(--yxt-results-filters-link-font-size);
	line-height: var(--yxt-results-filters-link-line-height);
	font-weight: var(--yxt-results-filters-link-font-weight);
	color: var(--yxt-color-brand-primary);
	margin-left: calc(var(--yxt-base-spacing)/2)
}

.yxt-Results-changeFilters:link, .yxt-Results-changeFilters:visited {
	color: var(--yxt-color-brand-primary);
	text-decoration: underline
}

.yxt-Results-changeFilters:active, .yxt-Results-changeFilters:focus, .yxt-Results-changeFilters:hover {
	color: var(--yxt-color-brand-primary);
	text-decoration: none
}

.yxt-Results-map {
	height: 18.75rem;
	border-bottom: var(--yxt-results-border);
	border-left: var(--yxt-results-border);
	border-right: var(--yxt-results-border)
}

.yxt-Results-items {
	padding: 0;
	list-style: none;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap
}

.yxt-Results-items, .yxt-Results-viewAll {
	margin-top: 0;
	margin-bottom: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.yxt-Results-viewAll {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: calc(var(--yxt-base-spacing)/2) var(--yxt-base-spacing);
	background-color: var(--yxt-results-view-more-background);
	border-right: var(--yxt-results-border);
	border-left: var(--yxt-results-border);
	border-bottom: var(--yxt-results-border);
	text-decoration: none;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.yxt-Results-viewAll:focus .yxt-Results-viewAllLabel, .yxt-Results-viewAll:hover .yxt-Results-viewAllLabel {
	text-decoration: underline
}

.yxt-Results-viewAll svg {
	height: calc(var(--yxt-base-spacing)/2);
	width: calc(var(--yxt-base-spacing)/2);
	color: var(--yxt-color-brand-primary)
}

.yxt-Results-viewAllLink {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-results-title-bar-link-font-size);
	line-height: var(--yxt-results-title-bar-link-line-height);
	font-weight: var(--yxt-results-title-bar-link-font-weight);
	font-style: normal;
	color: var(--yxt-color-text-primary)
}

.yxt-Results-viewAllLink:link, .yxt-Results-viewAllLink:visited {
	color: var(--yxt-color-brand-primary);
	text-decoration: none
}

.yxt-Results-viewAllLink:active, .yxt-Results-viewAllLink:focus, .yxt-Results-viewAllLink:hover {
	color: var(--yxt-color-brand-primary);
	text-decoration: underline
}

.yxt-Results-viewAllLabel {
	margin-right: calc(var(--yxt-base-spacing)/2);
	font-family: var(--yxt-font-family);
	font-size: .875rem;
	line-height: 1.5;
	font-weight: var(--yxt-font-weight-semibold);
	font-style: normal;
	color: var(--yxt-color-brand-primary)
}

.yxt-Results-titleBar {
	border: var(--yxt-results-border);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: calc(var(--yxt-base-spacing)/2) var(--yxt-base-spacing);
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: var(--yxt-results-title-bar-background)
}

.yxt-Results-title {
	margin: 0;
	text-transform: uppercase;
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-results-title-bar-text-font-size);
	line-height: var(--yxt-results-title-bar-text-line-height);
	font-weight: var(--yxt-font-weight-semibold);
	font-style: normal;
	color: var(--yxt-results-title-bar-text-color)
}

.yxt-Results--universal {
	margin-top: var(--yxt-base-spacing)
}

.yxt-Results--universal .yxt-Results-items {
	margin-right: 0
}

.yxt-Results--universal.yxt-Accordion {
	border: var(--yxt-border-legacy)
}

.yxt-Results--universal .yxt-Accordion-list, .yxt-Results--universal.yxt-Accordion .yxt-Results-filters {
	border-left: var(--yxt-border-legacy);
	border-right: var(--yxt-border-legacy)
}

.yxt-Results--universal .yxt-Accordion-list {
	border-top: var(--yxt-border-legacy)
}

.yxt-Accordion .yxt-Results-titleBar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: calc(var(--yxt-base-spacing)/2) var(--yxt-base-spacing);
	background-color: var(--yxt-results-title-bar-background);
	border: var(--yxt-border-legacy);
	border-bottom: none
}

.yxt-Accordion .yxt-Results-title {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-results-title-bar-text-font-size);
	line-height: var(--yxt-results-title-bar-text-line-height);
	font-weight: var(--yxt-results-title-bar-text-font-weight);
	font-style: normal;
	color: var(--yxt-results-title-bar-text-color);
	text-transform: uppercase
}

.yxt-Accordion .yxt-Results-titleIconWrapper {
	color: inherit
}

:root {
	--yxt-noresults-font-size: var(--yxt-font-size-md);
	--yxt-noresults-line-height: var(--yxt-line-height-md);
	--yxt-noresults-font-weight: var(--yxt-font-weight-normal);
	--yxt-noresults-query-font-weight: var(--yxt-font-weight-semibold)
}

.yxt-NoResults-wrapper {
	font-size: var(--yxt-noresults-font-size);
	line-height: var(--yxt-noresults-line-height);
	font-weight: var(--yxt-noresults-font-weight)
}

.yxt-NoResults-query {
	font-weight: var(--yxt-noresults-query-font-weight)
}

.yxt-NoResults-returnLinkWrapper {
	margin-top: var(--yxt-base-spacing)
}

.yxt-NoResults-returnLink:link, .yxt-NoResults-returnLink:visited {
	color: var(--yxt-color-brand-primary);
	text-decoration: underline
}

.yxt-NoResults-returnLink:active, .yxt-NoResults-returnLink:focus, .yxt-NoResults-returnLink:hover {
	color: var(--yxt-color-brand-primary);
	text-decoration: none
}

.yxt-NoResults-suggestions {
	margin-top: var(--yxt-base-spacing)
}

.yxt-NoResults-suggestionsHeader {
	font-style: italic
}

.yxt-NoResults-suggestionsList {
	margin-top: calc(var(--yxt-base-spacing)/2)
}

.yxt-NoResults-suggestion {
	margin-left: calc(var(--yxt-base-spacing-sm)*2);
	list-style-type: disc;
	list-style-position: inside
}

:root {
	--yxt-alternative-verticals-emphasized-font-weight: var(--yxt-font-weight-medium)
}

.yxt-AlternativeVerticals {
	font-family: var(--yxt-font-family);
	font-weight: var(--yxt-font-weight-light);
	background-color: var(--yxt-color-brand-white);
	border: var(--yxt-border-default)
}

@media (min-width: 47.9375rem) {
	.yxt-AlternativeVerticals {
		margin-bottom: 1.5rem
	}
}

.yxt-AlternativeVerticals--notShowingResults {
	border-bottom: var(--yxt-border-default)
}

.yxt-AlternativeVerticals-noResultsInfo {
	background-color: var(--yxt-color-brand-primary);
	color: var(--yxt-color-brand-white);
	font-size: var(--yxt-font-size-md);
	padding: calc(var(--yxt-base-spacing)/2)
}

.yxt-AlternativeVerticals-noResultsInfo--emphasized {
	font-weight: var(--yxt-alternative-verticals-emphasized-font-weight)
}

@media (min-width: 47.9375rem) {
	.yxt-AlternativeVerticals-noResultsInfo {
		font-size: var(--yxt-font-size-xlg);
		padding: .625rem 1.25rem
	}
}

.yxt-AlternativeVerticals-suggestionsWrapper {
	padding: var(--yxt-base-spacing)
}

.yxt-AlternativeVerticals-details {
	font-size: var(--yxt-font-size-md)
}

.yxt-AlternativeVerticals-details--query {
	font-weight: var(--yxt-font-weight-normal)
}

@media (min-width: 47.9375rem) {
	.yxt-AlternativeVerticals-details {
		font-size: var(--yxt-font-size-md-lg)
	}
}

.yxt-AlternativeVerticals-suggestionsList {
	list-style: none;
	padding-left: 0;
	margin-top: 0;
	margin-bottom: 0
}

.yxt-AlternativeVerticals-suggestion {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: calc(var(--yxt-base-spacing)*0.75) 0
}

@media (max-width: 47.9375rem) {
	.yxt-AlternativeVerticals-suggestion {
		margin: calc(var(--yxt-base-spacing)/2) 0
	}
}

.yxt-AlternativeVerticals-suggestionLink {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	text-decoration: none
}

.yxt-AlternativeVerticals-suggestionLink > :not(:last-child) {
	margin-right: .3125rem
}

.yxt-AlternativeVerticals-suggestionLink:focus .yxt-AlternativeVerticals-suggestionLink--copy, .yxt-AlternativeVerticals-suggestionLink:hover .yxt-AlternativeVerticals-suggestionLink--copy {
	text-decoration: underline
}

.yxt-AlternativeVerticals-suggestionLink--copy {
	font-weight: var(--yxt-alternative-verticals-emphasized-font-weight)
}

.yxt-AlternativeVerticals-arrowIconWrapper, .yxt-AlternativeVerticals-verticalIconWrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.yxt-AlternativeVerticals-arrowIconWrapper, .yxt-AlternativeVerticals-arrowIconWrapper > * {
	width: .5625rem
}

.yxt-AlternativeVerticals-universalLink {
	text-decoration: none
}

.yxt-AlternativeVerticals-universalLink:focus, .yxt-AlternativeVerticals-universalLink:hover {
	text-decoration: underline
}

.yxt-AlternativeVerticals-universalDetails {
	font-size: var(--yxt-font-size-md)
}

@media (min-width: 47.9375rem) {
	.yxt-AlternativeVerticals-universalDetails {
		font-size: var(--yxt-font-size-md-lg)
	}
}

.yxt-AlternativeVerticals a {
	color: var(--yxt-color-brand-primary)
}

.yxt-AlternativeVerticals a:active, .yxt-AlternativeVerticals a:hover {
	color: var(--yxt-color-brand-hover)
}

:root {
	--yxt-autocomplete-background-color: #fff;
	--yxt-autocomplete-box-shadow: 0 0.0625rem 0.375rem 0 rgba(32,33,36,0.47);
	--yxt-autocomplete-selected-background-color: #f9f9f9;
	--yxt-autocomplete-option-hover-background-color: #f9f9f9;
	--yxt-autocomplete-separator-color: var(--yxt-color-borders);
	--yxt-autocomplete-text-font-size: var(--yxt-font-size-md-lg);
	--yxt-autocomplete-text-line-height: var(--yxt-line-height-md);
	--yxt-autocomplete-text-font-weight: var(--yxt-font-weight-normal);
	--yxt-autocomplete-text-color: var(--yxt-color-text-primary);
	--yxt-autocomplete-prompt-header-font-weight: var(--yxt-font-weight-light)
}

.yxt-AutoComplete {
	padding-bottom: .625rem
}

@media (min-width: 48rem) {
	.yxt-AutoComplete:before {
		margin-left: var(--yxt-base-spacing);
		margin-right: var(--yxt-base-spacing);
		margin-bottom: calc(0.625*var(--yxt-base-spacing));
		height: .0625rem;
		background-color: var(--yxt-autocomplete-separator-color);
		content: "";
		display: block
	}
}

.yxt-AutoComplete-results {
	list-style-type: none;
	padding: 0;
	margin: 0
}

.yxt-AutoComplete-wrapper {
	background-color: var(--yxt-autocomplete-background-color)
}

.yxt-AutoComplete-option {
	padding: calc(var(--yxt-base-spacing)/4) var(--yxt-base-spacing)
}

.yxt-AutoComplete-option--promptHeader {
	font-weight: var(--yxt-autocomplete-prompt-header-font-weight);
	font-style: italic
}

.yxt-AutoComplete-option--item, .yxt-AutoComplete-option--promptHeader {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-autocomplete-text-font-size);
	line-height: var(--yxt-autocomplete-text-line-height);
	color: var(--yxt-autocomplete-text-color)
}

.yxt-AutoComplete-option--item {
	cursor: pointer;
	font-weight: var(--yxt-autocomplete-text-font-weight);
	font-style: normal
}

.yxt-AutoComplete-option--item.yxt-selected {
	background-color: var(--yxt-autocomplete-selected-background-color)
}

.yxt-AutoComplete-option--item strong {
	font-weight: var(--yxt-font-weight-semibold)
}

.yxt-AutoComplete-option--item:hover {
	background-color: var(--yxt-autocomplete-option-hover-background-color)
}

:root {
	--yxt-accordion-result-border: var(--yxt-border-default);
	--yxt-accordion-result-background-color: #fff;
	--yxt-accordion-result-hover-color: var(--yxt-color-background-highlight);
	--yxt-accordion-result-title-font-size: var(--yxt-font-size-md-lg);
	--yxt-accordion-result-title-line-height: var(--yxt-line-height-lg);
	--yxt-accordion-result-title-font-weight: var(--yxt-font-weight-semibold);
	--yxt-accordion-result-title-color: var(--yxt-color-brand-primary);
	--yxt-accordion-result-details-font-size: var(--yxt-font-size-md);
	--yxt-accordion-result-details-line-height: var(--yxt-line-height-md-sm);
	--yxt-accordion-result-details-font-weight: var(--yxt-font-weight-normal);
	--yxt-accordion-result-details-color: var(--yxt-color-text-primary);
	--yxt-accordion-result-cta-font-size: var(--yxt-font-size-md);
	--yxt-accordion-result-cta-line-height: var(--yxt-line-height-xxlg);
	--yxt-accordion-result-cta-font-weight: var(--yxt-font-weight-normal)
}

.yxt-AccordionResult {
	background-color: var(--yxt-accordion-result-background-color);
	width: 100%
}

.yxt-AccordionResult + .yxt-AccordionResult {
	border-top: var(--yxt-accordion-result-border)
}

.yxt-AccordionResult:hover, .yxt-AccordionResult:not(.is-collapsed) {
	background-color: var(--yxt-accordion-result-hover-color)
}

.yxt-AccordionResult-title {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-accordion-result-title-font-size);
	line-height: var(--yxt-accordion-result-title-line-height);
	font-weight: var(--yxt-accordion-result-title-font-weight);
	font-style: normal;
	color: var(--yxt-accordion-result-title-color)
}

.yxt-AccordionResult-indicatorWrapper {
	-ms-flex-negative: 0;
	flex-shrink: 0
}

.yxt-AccordionResult-details {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-accordion-result-details-font-size);
	line-height: var(--yxt-accordion-result-details-line-height);
	font-weight: var(--yxt-accordion-result-details-font-weight);
	font-style: normal;
	color: var(--yxt-accordion-result-details-color);
	white-space: pre-line
}

.yxt-AccordionResult-ctasWrapper {
	margin-top: calc(var(--yxt-base-spacing)/2)
}

.yxt-AccordionResult-ctas {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start
}

.yxt-AccordionResult-cta, .yxt-AccordionResult-ctas {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.yxt-AccordionResult-cta {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-accordion-result-cta-font-size);
	line-height: var(--yxt-accordion-result-cta-line-height);
	font-weight: var(--yxt-accordion-result-cta-font-weight);
	font-style: normal;
	color: var(--yxt-color-text-primary);
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.yxt-AccordionResult-cta:link, .yxt-AccordionResult-cta:visited {
	color: var(--yxt-color-brand-primary);
	text-decoration: none
}

.yxt-AccordionResult-cta:active, .yxt-AccordionResult-cta:focus, .yxt-AccordionResult-cta:hover {
	color: var(--yxt-color-brand-primary);
	text-decoration: underline
}

.yxt-AccordionResult-toggle {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: left;
	width: 100%;
	padding-top: calc(var(--yxt-base-spacing)/2);
	padding-bottom: .625rem;
	padding-left: 1em;
	padding-right: 1em
}

.yxt-AccordionResult-indicator {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
	-webkit-transition: -webkit-transform .4s ease-in;
	transition: -webkit-transform .4s ease-in;
	transition: transform .4s ease-in;
	transition: transform .4s ease-in,-webkit-transform .4s ease-in
}

.yxt-AccordionResult-content {
	padding-top: calc(var(--yxt-base-spacing-sm)/2);
	padding-bottom: 1em;
	padding-right: 1em;
	padding-left: 1em
}

.yxt-AccordionResult-body {
	-webkit-transition: all .4s ease-in;
	transition: all .4s ease-in;
	overflow: hidden
}

.yxt-AccordionResult.is-collapsed .yxt-AccordionResult-body {
	height: 0;
	border-top: 0
}

.yxt-AccordionResult.is-collapsed .yxt-AccordionResult-indicator {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg)
}

:root {
	--yxt-spellCheck-text-font-size: var(--yxt-font-size-md-lg);
	--yxt-spellCheck-text-font-weight: var(--yxt-font-weight-normal);
	--yxt-spellCheck-text-color: var(--yxt-color-text-primary);
	--yxt-spellCheck-container-height: var(--yxt-module-container-height)
}

.yxt-SpellCheck {
	margin-top: calc(var(--yxt-base-spacing)*2);
	padding-bottom: var(--yxt-base-spacing);
	display: block;
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-spellCheck-text-font-size);
	line-height: var(--yxt-line-height-xs);
	font-weight: var(--yxt-spellCheck-text-font-weight);
	font-style: normal;
	color: var(--yxt-spellCheck-text-color)
}

@media (max-width: 47.9375rem) {
	.yxt-SpellCheck {
		margin-left: var(--yxt-base-spacing)
	}
}

.yxt-SpellCheck .yxt-SpellCheck-helpText {
	font-size: var(--yxt-font-size-lg)
}

.yxt-SpellCheck .yxt-SpellCheck-container {
	height: var(--yxt-spellCheck-container-height)
}

.yxt-SpellCheck strong {
	font-style: italic;
	font-weight: var(--yxt-font-weight-semibold)
}

.yxt-SpellCheck a {
	text-decoration: none
}

.yxt-SpellCheck a:link, .yxt-SpellCheck a:visited {
	color: var(--yxt-color-brand-primary);
	text-decoration: none
}

.yxt-SpellCheck a:active, .yxt-SpellCheck a:focus, .yxt-SpellCheck a:hover {
	color: var(--yxt-color-brand-primary);
	text-decoration: underline
}

:root {
	--yxt-pagination-text-color: var(--yxt-color-text-neutral);
	--yxt-pagination-text-color-hover: var(--yxt-color-brand-white);
	--yxt-pagination-text-font-size: var(--yxt-font-size-md-lg);
	--yxt-pagination-text-line-height: var(--yxt-line-height-md);
	--yxt-pagination-text-font-weight: var(--yxt-font-weight-normal);
	--yxt-pagination-color-active-page: var(--yxt-color-borders);
	--yxt-pagination-color-hover: var(--yxt-color-text-secondary)
}

.yxt-Pagination {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-pagination-text-font-size);
	line-height: var(--yxt-pagination-text-line-height);
	font-weight: var(--yxt-pagination-text-font-weight);
	font-style: normal;
	color: var(--yxt-pagination-text-color)
}

.yxt-Pagination--hidden {
	visibility: hidden
}

.yxt-Pagination-link, .yxt-Pagination-page {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 1.5rem;
	min-width: 1.5rem;
	margin-right: .125rem;
	margin-left: .125rem;
	border-radius: .125rem;
	padding: 0 .25rem;
	-webkit-box-sizing: border-box;
	box-sizing: border-box
}

.yxt-Pagination-icon {
	font-size: var(--yxt-font-size-sm)
}

.yxt-Pagination-icon span {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

@media (max-width: 47.9375rem) {
	.yxt-Pagination--activeMobile {
		background-color: var(--yxt-pagination-color-active-page)
	}
}

@media (min-width: 48rem) {
	.yxt-Pagination--activeDesktop {
		background-color: var(--yxt-pagination-color-active-page)
	}
}

.yxt-Pagination-link:focus, .yxt-Pagination-link:hover {
	cursor: pointer;
	color: var(--yxt-pagination-text-color-hover);
	background-color: var(--yxt-pagination-color-hover)
}

.yxt-Pagination-chevron--left svg, .yxt-Pagination-doubleChevron--left svg {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg)
}

.yxt-Pagination-doubleChevron, .yxt-Pagination-doubleChevron--left {
	margin-right: -.25rem
}

.yxt-Pagination-chevron, .yxt-Pagination-chevron--left, .yxt-Pagination-doubleChevron, .yxt-Pagination-doubleChevron--left {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

@media (max-width: 47.9375rem) {
	.yxt-Pagination .mobile-hidden {
		display: none
	}
}

@media (min-width: 47.9375rem) {
	.yxt-Pagination .desktop-hidden {
		display: none
	}
}

:root {
	--yxt-locationBias-text-font-size: var(--yxt-font-size-md);
	--yxt-locationBias-text-font-weight: var(--yxt-font-weight-normal);
	--yxt-locationBias-text-color: var(--yxt-color-text-secondary);
	--yxt-locationBias-bullet-size: calc(var(--yxt-font-size-sm)/2);
	--yxt-locationBias-bullet-color-default: var(--yxt-locationBias-text-color)
}

.yxt-LocationBias {
	padding-bottom: var(--yxt-base-spacing);
	display: block;
	text-align: center;
	padding-top: var(--yxt-base-spacing);
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-locationBias-text-font-size);
	line-height: var(--yxt-line-height-xs);
	font-weight: var(--yxt-locationBias-text-font-weight);
	font-style: normal;
	color: var(--yxt-locationBias-text-color)
}

.yxt-LocationBias strong {
	font-weight: var(--yxt-font-weight-semibold)
}

.yxt-LocationBias-bullet {
	height: var(--yxt-locationBias-bullet-size);
	width: var(--yxt-locationBias-bullet-size);
	display: inline-block;
	border-radius: 100%;
	background: var(--yxt-locationBias-bullet-color-default);
	margin-bottom: .125rem
}

.yxt-LocationBias-unknownLoc {
	background-color: transparent;
	border: .0625rem solid var(--yxt-locationBias-bullet-color-default)
}

.yxt-LocationBias-preciseLoc {
	background: var(--yxt-color-brand-primary);
	border: .0625rem solid var(--yxt-color-brand-primary)
}

@media (max-width: 47.9375rem) {
	.yxt-LocationBias-locSource {
		display: block
	}
}

.yxt-LocationBias-updateLoc {
	cursor: pointer;
	border: none;
	font-size: var(--yxt-locationBias-text-font-size);
	padding: 0;
	background: none
}

.yxt-LocationBias-updateLoc, .yxt-LocationBias-updateLoc:link, .yxt-LocationBias-updateLoc:visited {
	color: var(--yxt-color-brand-primary);
	text-decoration: none
}

.yxt-LocationBias-updateLoc:active, .yxt-LocationBias-updateLoc:focus, .yxt-LocationBias-updateLoc:hover {
	color: var(--yxt-color-brand-primary);
	text-decoration: underline
}

:root {
	--yxt-filter-options-checkmark-color: #0f70f0;
	--yxt-filter-options-checkbox-focus-color: #000;
	--yxt-filter-options-options-max-height: none
}

.yxt-FilterOptions-container {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-md);
	line-height: var(--yxt-line-height-md);
	font-weight: var(--yxt-font-weight-normal);
	font-style: normal;
	color: var(--yxt-color-text-primary)
}

.yxt-FilterOptions-container--searching .yxt-FilterOptions-showToggle {
	display: none
}

.yxt-FilterOptions-container--searching .yxt-FilterOptions-option.displaySearch {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.yxt-FilterOptions-container--searching .yxt-FilterOptions-option.hiddenSearch {
	display: none
}

.yxt-FilterOptions-controls {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column
}

.yxt-FilterOptions-buttonWrapper, .yxt-FilterOptions-controls {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.yxt-FilterOptions-filter {
	font-size: .875rem;
	border: .0625rem solid var(--yxt-color-borders);
	border-radius: .25rem;
	padding: .25rem .5rem;
	width: 100%
}

.yxt-FilterOptions-filter:active, .yxt-FilterOptions-filter:focus, .yxt-FilterOptions-filter:hover {
	border: var(--yxt-border-hover)
}

.yxt-FilterOptions-filter::-ms-clear {
	display: none;
	height: 0;
	width: 0
}

.yxt-FilterOptions-fieldSet {
	border: 0;
	padding: 0;
	margin: .75rem 0
}

.yxt-FilterOptions-legend {
	width: 100%;
	padding: 0
}

.yxt-FilterOptions-legendLabel {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-md-lg);
	line-height: var(--yxt-line-height-lg);
	font-weight: var(--yxt-font-weight-semibold);
	font-style: normal;
	color: var(--yxt-color-text-primary);
	text-align: left;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.yxt-FilterOptions-legendLabel:hover button {
	color: var(--yxt-color-text-secondary)
}

.yxt-FilterOptions-option {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.yxt-FilterOptions-option:not(:first-child) {
	margin-top: .5rem
}

.yxt-FilterOptions-option.hidden {
	display: none
}

@media (max-width: 47.9375rem) {
	.yxt-FilterOptions-option {
		margin: 1.375rem 0
	}
}

.yxt-FilterOptions-options {
	margin: .75rem 0;
	padding-left: 0;
	max-height: var(--yxt-filter-options-options-max-height);
	overflow-y: auto
}

.yxt-FilterOptions-search {
	position: relative;
	margin-top: .625rem
}

.yxt-FilterOptions-clearSearch {
	position: absolute;
	right: 0;
	top: 50%;
	max-height: 100%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	border: none;
	background: none;
	padding: 0;
	font-size: 1.5rem;
	cursor: pointer;
	color: var(--yxt-color-text-secondary)
}

.yxt-FilterOptions-clearSearch.js-hidden {
	display: none
}

.yxt-FilterOptions-optionLabel, .yxt-FilterOptions-radioButtonLabel {
	cursor: pointer;
	word-break: break-word;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto
}

.yxt-FilterOptions-optionLabel {
	position: relative;
	font-size: .875rem;
	line-height: 1.25rem;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-left: 1.375rem
}

.yxt-FilterOptions-optionLabel:before {
	content: "";
	position: absolute;
	bottom: 50%;
	left: -1.375rem;
	height: .75rem;
	width: .75rem;
	-webkit-transform: translateY(50%);
	transform: translateY(50%);
	border: .0625rem solid var(--yxt-color-borders);
	border-radius: .125rem
}

.yxt-FilterOptions-optionLabel:after {
	content: none;
	position: absolute;
	top: calc(50% - .1875rem);
	left: -1.25rem;
	width: .1875rem;
	height: .5625rem;
	-webkit-transform: rotate(45deg) translateY(-50%);
	transform: rotate(45deg) translateY(-50%);
	border-left-color: var(--yxt-filter-options-checkmark-color);
	border-bottom: .0625rem solid #0c5ecb;
	border-bottom-color: var(--yxt-filter-options-checkmark-color);
	border-right: .0625rem solid #0c5ecb;
	border-right-color: var(--yxt-filter-options-checkmark-color);
	border-top-color: var(--yxt-filter-options-checkmark-color)
}

.yxt-FilterOptions-selectedCount {
	font-size: var(--yxt-font-size-xs);
	font-weight: var(--yxt-font-weight-bold);
	font-style: normal;
	color: #fff;
	width: 1.25rem;
	height: 1.25rem;
	margin-left: .75rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background: var(--yxt-color-background-dark);
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: .25rem
}

.yxt-FilterOptions-reset, .yxt-FilterOptions-selectedCount {
	font-family: var(--yxt-font-family);
	line-height: var(--yxt-line-height-xs)
}

.yxt-FilterOptions-reset {
	font-size: var(--yxt-font-size-md);
	font-weight: var(--yxt-font-weight-normal);
	font-style: italic;
	color: var(--yxt-color-brand-primary);
	background: none;
	border: .0625rem solid transparent;
	border-radius: .25rem;
	padding: 0
}

.yxt-FilterOptions-reset.js-hidden {
	display: none
}

.yxt-FilterOptions-reset:not(:disabled) {
	cursor: pointer
}

.yxt-FilterOptions-reset:not(:disabled):hover {
	text-decoration: underline
}

.yxt-FilterOptions-reset:not(:disabled):focus {
	color: var(--yxt-color-brand-hover);
	border: .0625rem solid var(--yxt-color-brand-hover)
}

@media (max-width: 47.9375rem) {
	.yxt-FilterOptions-reset {
		margin-top: .75rem
	}
}

.yxt-FilterOptions-showToggle {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-md);
	line-height: var(--yxt-line-height-xs);
	font-weight: var(--yxt-font-weight-normal);
	font-style: italic;
	color: var(--yxt-color-brand-primary);
	background: none;
	border: .0625rem solid transparent;
	border-radius: .25rem;
	padding: 0
}

.yxt-FilterOptions-showToggle:not(:disabled) {
	cursor: pointer
}

.yxt-FilterOptions-showToggle:not(:disabled):hover {
	text-decoration: underline
}

.yxt-FilterOptions-showToggle:not(:disabled):focus {
	color: var(--yxt-color-brand-hover);
	border: .0625rem solid var(--yxt-color-brand-hover)
}

.yxt-FilterOptions-showToggle.hidden {
	display: none
}

.yxt-FilterOptions-showToggle svg {
	width: .375rem;
	margin-left: .3125rem
}

.yxt-FilterOptions-clickableLegend {
	border: .0625rem solid transparent;
	border-radius: .25rem;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer
}

.yxt-FilterOptions-clickableLegend:focus {
	border: .0625rem solid var(--yxt-color-brand-hover)
}

.yxt-FilterOptions-clickableLegend:not(:disabled):hover {
	color: var(--yxt-color-text-secondary)
}

.yxt-FilterOptions-input {
	margin-left: 0
}

.yxt-FilterOptions-radioButtonInput {
	cursor: pointer
}

.yxt-FilterOptions-radioButtonInput:focus {
	outline: .0625rem solid #000
}

.yxt-FilterOptions-checkboxInput {
	opacity: 0;
	height: 0;
	width: 0;
	margin: 0
}

.yxt-FilterOptions-checkboxInput:checked + .yxt-FilterOptions-optionLabel:after {
	content: ""
}

.yxt-FilterOptions-checkboxInput:checked + .yxt-FilterOptions-optionLabel:before {
	border: var(--yxt-border-hover);
	border-color: var(--yxt-filter-options-checkmark-color)
}

.yxt-FilterOptions-checkboxInput:focus + .yxt-FilterOptions-optionLabel:before {
	border: .0625rem solid var(--yxt-filter-options-checkbox-focus-color);
	-webkit-box-shadow: var(--yxt-searchbar-focus-shadow);
	box-shadow: var(--yxt-searchbar-focus-shadow)
}

.yxt-FilterOptions-reset--right {
	margin-left: auto;
	padding: 0
}

.yxt-FilterOptions-expand {
	margin-left: auto
}

.yxt-FilterOptions-expand > .Icon--chevron svg {
	height: .75rem;
	width: .75rem
}

.yxt-FilterOptions--collapsed {
	display: none
}

.yxt-FilterOptions-collapseDown, .yxt-FilterOptions-collapseUp {
	vertical-align: middle
}

.yxt-FilterOptions-collapseUp {
	padding-top: .125rem
}

.yxt-FilterOptions-collapseUp svg {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg)
}

.yxt-FilterOptions-collapseDown svg {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg)
}

.yxt-Facets-container {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 1.5625rem;
	width: 18.75rem
}

@media (max-width: 47.9375rem) {
	.yxt-Facets-container {
		width: 100%
	}
}

.yxt-FilterBox-container {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%
}

.yxt-FilterBox-titleContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 0 .25rem
}

.yxt-FilterBox-titleContainer svg {
	width: 1.125rem;
	height: 1.125rem
}

.yxt-FilterBox-title {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-md-lg);
	line-height: var(--yxt-line-height-lg);
	font-weight: var(--yxt-font-weight-semibold);
	font-style: normal;
	color: var(--yxt-color-text-primary);
	text-transform: uppercase;
	margin-left: .5rem
}

.yxt-FilterBox-filter + .yxt-FilterBox-filter {
	border-top: .0625rem solid var(--yxt-color-borders)
}

.yxt-FilterBox-apply {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-md);
	line-height: var(--yxt-line-height-xs);
	font-weight: var(--yxt-font-weight-semibold);
	font-style: normal;
	color: #fff;
	border: 0;
	border-radius: .1875rem;
	margin-top: 1rem;
	background: var(--yxt-color-brand-primary);
	letter-spacing: .03125rem;
	text-transform: uppercase;
	width: 5.625rem;
	height: 2.5rem
}

.yxt-FilterBox-apply:not(:disabled) {
	cursor: pointer
}

.yxt-FilterBox-apply:not(:disabled):focus, .yxt-FilterBox-apply:not(:disabled):hover {
	background: padding-box var(--yxt-color-brand-hover)
}

.yxt-FilterBox-apply:not(:disabled):focus {
	border: var(--yxt-button-focus-border-size) double #0c5ecb
}

.yxt-FilterBox-reset {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-md);
	line-height: var(--yxt-line-height-xs);
	font-weight: var(--yxt-font-weight-semibold);
	font-style: normal;
	color: var(--yxt-color-brand-primary);
	background: none;
	border: .0625rem solid transparent;
	border-radius: .25rem;
	padding: .3125rem .625rem .3125rem 0;
	text-decoration: underline;
	letter-spacing: .03125rem
}

.yxt-FilterBox-reset:not(:disabled) {
	cursor: pointer
}

.yxt-FilterBox-reset:not(:disabled):hover {
	text-decoration: underline
}

.yxt-FilterBox-reset:not(:disabled):focus {
	color: var(--yxt-color-brand-hover);
	border: .0625rem solid var(--yxt-color-brand-hover)
}

.yxt-FilterBox-reset:not(:disabled):hover {
	text-decoration: none
}

.yxt-FilterBox-apply + .yxt-FilterBox-reset {
	padding-left: .3125rem;
	margin-left: .625rem
}

:root {
	--yxt-question-submission-font-size: var(--yxt-font-size-md);
	--yxt-question-submission-line-height: var(--yxt-line-height-md);
	--yxt-question-submission-font-weight: var(--yxt-font-weight-normal);
	--yxt-question-submission-color: var(--yxt-color-text-primary);
	--yxt-question-submission-border: var(--yxt-border-default);
	--yxt-question-submission-title-font-size: var(--yxt-font-size-md-lg);
	--yxt-question-submission-title-line-height: var(--yxt-line-height-lg);
	--yxt-question-submission-title-font-weight: var(--yxt-font-weight-semibold);
	--yxt-question-submission-title-color: var(--yxt-color-text-primary);
	--yxt-question-submission-title-bar-background: var(--yxt-color-background-highlight);
	--yxt-question-submission-description-font-size: var(--yxt-font-size-md);
	--yxt-question-submission-description-line-height: var(--yxt-line-height-md);
	--yxt-question-submission-description-font-weight: var(--yxt-font-weight-normal);
	--yxt-question-submission-description-color: var(--yxt-color-text-primary);
	--yxt-question-submission-label-font-size: var(--yxt-font-size-md);
	--yxt-question-submission-label-line-height: var(--yxt-line-height-md);
	--yxt-question-submission-label-font-weight: var(--yxt-font-weight-bold);
	--yxt-question-submission-label-color: var(--yxt-color-text-primary);
	--yxt-question-submission-input-font-size: var(--yxt-font-size-md);
	--yxt-question-submission-input-line-height: var(--yxt-line-height-md);
	--yxt-question-submission-input-font-weight: var(--yxt-font-weight-normal);
	--yxt-question-submission-input-color: var(--yxt-color-text-primary);
	--yxt-question-submission-inputError-font-size: var(--yxt-font-size-sm);
	--yxt-question-submission-inputError-line-height: var(--yxt-line-height-md-sm);
	--yxt-question-submission-inputError-font-weight: var(--yxt-font-weight-normal);
	--yxt-question-submission-inputError-color: var(--yxt-color-error);
	--yxt-question-submission-formSubmitError-color: var(--yxt-color-error);
	--yxt-question-submission-acknowledgement-bar-bg: var(--yxt-color-background-highlight)
}

.yxt-QuestionSubmission {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-question-submission-font-size);
	line-height: var(--yxt-question-submission-line-height);
	font-weight: var(--yxt-question-submission-font-weight);
	font-style: normal;
	color: var(--yxt-question-submission-color);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	border: var(--yxt-question-submission-border);
	background-color: var(--yxt-color-brand-white);
	margin-top: var(--yxt-base-spacing)
}

.yxt-QuestionSubmission-titleBar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: calc(var(--yxt-base-spacing)/2) var(--yxt-base-spacing);
	background-color: var(--yxt-question-submission-title-bar-background);
	border: 0;
	border-bottom: var(--yxt-question-submission-border);
	cursor: pointer
}

.yxt-QuestionSubmission-titleBar:focus {
	outline: .0625rem solid var(--yxt-color-text-primary)
}

.yxt-QuestionSubmission-left, .yxt-QuestionSubmission-right {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between
}

.yxt-QuestionSubmission-titleIconWrapper {
	margin-right: var(--yxt-base-spacing-sm)
}

.yxt-QuestionSubmission-titleIconWrapper, .yxt-QuestionSubmission-toggle {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 0;
	color: var(--yxt-color-brand-primary);
	background: none;
	border: none
}

.yxt-QuestionSubmission-titleIconWrapper svg, .yxt-QuestionSubmission-toggle svg {
	height: 1.125rem;
	width: 1.125rem
}

.yxt-QuestionSubmission-toggle--expanded {
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg)
}

.yxt-QuestionSubmission-toggle--collapsed {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg)
}

.yxt-QuestionSubmission-title {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-question-submission-title-font-size);
	line-height: var(--yxt-question-submission-title-line-height);
	font-weight: var(--yxt-question-submission-title-font-weight);
	font-style: normal;
	color: var(--yxt-question-submission-title-color);
	text-transform: uppercase;
	width: 100%
}

.yxt-QuestionSubmission-titleDescription {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-question-submission-description-font-size);
	line-height: var(--yxt-question-submission-description-line-height);
	font-weight: var(--yxt-question-submission-description-font-weight);
	font-style: normal;
	color: var(--yxt-question-submission-description-color);
	margin-right: calc(var(--yxt-base-spacing)/2);
	font-style: italic
}

@media (max-width: 47.9375rem) {
	.yxt-QuestionSubmission-titleDescription {
		display: none
	}
}

.yxt-QuestionSubmission-form {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-bottom: 0
}

.yxt-QuestionSubmission-formRow {
	margin: var(--yxt-base-spacing)
}

.yxt-QuestionSubmission-formRow + .yxt-QuestionSubmission-formRow {
	margin-top: 0
}

.yxt-QuestionSubmission-formFieldWrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row
}

@media (max-width: 35.9375rem) {
	.yxt-QuestionSubmission-formFieldWrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column
	}
}

.yxt-QuestionSubmission-formField {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-right: var(--yxt-base-spacing);
	width: 100%
}

@media (max-width: 35.9375rem) {
	.yxt-QuestionSubmission-formField {
		margin-bottom: 0
	}

	.yxt-QuestionSubmission-formField + .yxt-QuestionSubmission-formField {
		margin-top: var(--yxt-base-spacing)
	}
}

.yxt-QuestionSubmission-formLabel {
	font-size: var(--yxt-question-submission-label-font-size);
	line-height: var(--yxt-question-submission-label-line-height);
	font-weight: var(--yxt-question-submission-label-font-weight);
	color: var(--yxt-question-submission-label-color);
	margin-bottom: calc(var(--yxt-base-spacing)/4)
}

.yxt-QuestionSubmission-formInput, .yxt-QuestionSubmission-formLabel {
	font-family: var(--yxt-font-family);
	font-style: normal;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.yxt-QuestionSubmission-formInput {
	font-size: var(--yxt-question-submission-input-font-size);
	line-height: var(--yxt-question-submission-input-line-height);
	font-weight: var(--yxt-question-submission-input-font-weight);
	color: var(--yxt-question-submission-input-color);
	width: 100%;
	border: var(--yxt-question-submission-border);
	border-radius: .1875rem;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: calc(var(--yxt-base-spacing)/2)
}

.yxt-QuestionSubmission-formInput:focus {
	border: .0625rem solid var(--yxt-color-text-primary)
}

.yxt-QuestionSubmission-formInput::-webkit-input-placeholder {
	font-style: italic
}

.yxt-QuestionSubmission-formInput::-ms-input-placeholder {
	font-style: italic
}

.yxt-QuestionSubmission-formInput::placeholder {
	font-style: italic
}

.yxt-QuestionSubmission-formInput:-ms-input-placeholder {
	font-style: italic
}

.yxt-QuestionSubmission-formInput.yxt-required-error {
	border: .0625rem solid var(--yxt-color-error)
}

.yxt-QuestionSubmission-formInput.yxt-required-error::-webkit-input-placeholder {
	color: var(--yxt-color-error)
}

.yxt-QuestionSubmission-formInput.yxt-required-error::-ms-input-placeholder {
	color: var(--yxt-color-error)
}

.yxt-QuestionSubmission-formInput.yxt-required-error::placeholder {
	color: var(--yxt-color-error)
}

.yxt-QuestionSubmission-formInput.yxt-required-error:-ms-input-placeholder {
	color: var(--yxt-color-error)
}

.yxt-QuestionSubmission-formError {
	color: var(--yxt-question-submission-formSubmitError-color);
	font-style: italic
}

.yxt-QuestionSubmission-acknowledgementBar {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: var(--yxt-base-spacing);
	border-top: var(--yxt-question-submission-border);
	background-color: var(--yxt-question-submission-acknowledgement-bar-bg)
}

.yxt-QuestionSubmission-acknowledgement, .yxt-QuestionSubmission-acknowledgementBar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.yxt-QuestionSubmission-ackCheckboxInput {
	min-height: 1rem;
	min-width: 1rem;
	margin: 0;
	margin-right: calc(var(--yxt-base-spacing)/2);
	margin-top: .2rem
}

.yxt-QuestionSubmission-privacyPolicy {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	margin-right: var(--yxt-base-spacing)
}

.yxt-QuestionSubmission-privacyPolicyUrl:link, .yxt-QuestionSubmission-privacyPolicyUrl:visited {
	color: var(--yxt-color-brand-primary);
	text-decoration: none
}

.yxt-QuestionSubmission-privacyPolicyUrl:active, .yxt-QuestionSubmission-privacyPolicyUrl:focus, .yxt-QuestionSubmission-privacyPolicyUrl:hover {
	color: var(--yxt-color-brand-primary);
	text-decoration: underline
}

.yxt-QuestionSubmission-inputError {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-question-submission-inputError-font-size);
	line-height: var(--yxt-question-submission-inputError-line-height);
	font-weight: var(--yxt-question-submission-inputError-font-weight);
	font-style: normal;
	color: var(--yxt-question-submission-inputError-color);
	margin-top: calc(var(--yxt-base-spacing)/4);
	display: block
}

.yxt-QuestionSubmission-submitButton {
	border: 0;
	border-radius: .1875rem;
	background: var(--yxt-color-brand-primary);
	letter-spacing: .03125rem;
	text-transform: uppercase;
	color: #fff;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin-top: 0;
	padding-top: .625rem;
	padding-bottom: .625rem;
	padding-left: var(--yxt-base-spacing);
	padding-right: var(--yxt-base-spacing);
	letter-spacing: .5px;
	font-size: .875rem;
	font-weight: var(--yxt-font-weight-semibold);
	overflow-wrap: break-word
}

.yxt-QuestionSubmission-submitButton:not(:disabled) {
	cursor: pointer
}

.yxt-QuestionSubmission-submitButton:not(:disabled):focus, .yxt-QuestionSubmission-submitButton:not(:disabled):hover {
	background: padding-box var(--yxt-color-brand-hover)
}

.yxt-QuestionSubmission-submitButton:not(:disabled):focus {
	border: var(--yxt-button-focus-border-size) double #0c5ecb
}

.yxt-QuestionSubmission-submitButton:focus {
	padding: .4375rem .8125rem
}

.yxt-SortOptions-container {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-md);
	line-height: var(--yxt-line-height-md);
	font-weight: var(--yxt-font-weight-normal);
	font-style: normal;
	color: var(--yxt-color-text-primary)
}

.yxt-SortOptions-fieldSet {
	border: 0;
	padding: 0;
	margin: var(--yxt-base-spacing) 0
}

.yxt-SortOptions-legend {
	width: 100%;
	padding: 0
}

.yxt-SortOptions-legendLabel {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-md-lg);
	line-height: var(--yxt-line-height-lg);
	font-weight: var(--yxt-font-weight-semibold);
	font-style: normal;
	color: var(--yxt-color-text-primary);
	text-align: left;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.yxt-SortOptions-option {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	margin: .125rem 0
}

@media (max-width: 47.9375rem) {
	.yxt-SortOptions-option {
		margin: 1.375rem 0
	}
}

.yxt-SortOptions-options {
	margin: var(--yxt-base-spacing) 0
}

.yxt-SortOptions-optionLabel {
	display: block;
	margin-left: .3125rem
}

.yxt-SortOptions-optionSelector:focus {
	outline: .0625rem auto #000
}

.yxt-SortOptions-optionSelector:focus _:-ms-fullscreen, :root .yxt-SortOptions-optionSelector:focus {
	outline: .0625rem solid #000
}

.yxt-SortOptions-reset {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-md);
	line-height: var(--yxt-line-height-xs);
	font-weight: var(--yxt-font-weight-normal);
	font-style: italic;
	color: var(--yxt-color-brand-primary);
	background: none;
	border: .0625rem solid transparent;
	border-radius: .25rem;
	padding: 0
}

.yxt-SortOptions-reset:not(:disabled) {
	cursor: pointer
}

.yxt-SortOptions-reset:not(:disabled):hover {
	text-decoration: underline
}

.yxt-SortOptions-reset:not(:disabled):focus {
	color: var(--yxt-color-brand-hover);
	border: .0625rem solid var(--yxt-color-brand-hover)
}

@media (max-width: 47.9375rem) {
	.yxt-SortOptions-reset {
		margin-top: var(--yxt-base-spacing)
	}
}

.yxt-SortOptions-reset.js-hidden {
	display: none
}

.yxt-SortOptions-showToggle {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-md);
	line-height: var(--yxt-line-height-xs);
	font-weight: var(--yxt-font-weight-normal);
	font-style: italic;
	color: var(--yxt-color-brand-primary);
	background: none;
	border: .0625rem solid transparent;
	border-radius: .25rem;
	padding: 0
}

.yxt-SortOptions-showToggle:not(:disabled) {
	cursor: pointer
}

.yxt-SortOptions-showToggle:not(:disabled):hover {
	text-decoration: underline
}

.yxt-SortOptions-showToggle:not(:disabled):focus {
	color: var(--yxt-color-brand-hover);
	border: .0625rem solid var(--yxt-color-brand-hover)
}

.yxt-SortOptions-showToggle svg {
	width: .375rem;
	margin-left: .3125rem
}

.yxt-SortOptions-input {
	margin-left: 0
}

.yxt-SortOptions-collapseDown, .yxt-SortOptions-collapseUp {
	vertical-align: middle
}

.yxt-SortOptions-collapseUp {
	padding-top: .125rem
}

.yxt-SortOptions-collapseUp svg {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg)
}

.yxt-SortOptions-collapseDown svg {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg)
}

.yxt-SortOptions-apply {
	font-family: var(--yxt-font-family);
	line-height: var(--yxt-line-height-xs);
	font-style: normal;
	border: 0;
	border-radius: .1875rem;
	margin-top: 1rem;
	background: var(--yxt-color-brand-primary);
	letter-spacing: .03125rem;
	text-transform: uppercase;
	color: #fff;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: var(--yxt-base-spacing-sm);
	letter-spacing: .5px;
	font-size: var(--yxt-font-size-md);
	font-weight: var(--yxt-font-weight-semibold);
	overflow-wrap: break-word;
	margin-top: var(--yxt-base-spacing-sm)
}

.yxt-SortOptions-apply:not(:disabled) {
	cursor: pointer
}

.yxt-SortOptions-apply:not(:disabled):focus, .yxt-SortOptions-apply:not(:disabled):hover {
	background: padding-box var(--yxt-color-brand-hover)
}

.yxt-SortOptions-apply:not(:disabled):focus {
	border: var(--yxt-button-focus-border-size) double #0c5ecb
}

.yxt-SortOptions-apply:focus {
	padding: calc(var(--yxt-base-spacing-sm) - var(--yxt-button-focus-border-size))
}

:root {
	--yxt-standard-card-base-spacing: var(--yxt-base-spacing);
	--yxt-standard-card-link-color: var(--yxt-color-brand-primary);
	--yxt-standard-card-subtitle-color: var(--yxt-color-text-secondary);
	--yxt-standard-card-link-hover-color: var(--yxt-color-brand-hover);
	--yxt-standard-card-cta-width: calc(8*var(--yxt-standard-card-base-spacing));
	--yxt-standard-card-wrapper-width: calc(10*var(--yxt-standard-card-base-spacing));
	--yxt-standard-card-ordinal-size: var(--yxt-base-spacing);
	--yxt-standard-card-ordinal-font-size: var(--yxt-font-size-xs)
}

.yxt-StandardCard {
	padding: var(--yxt-standard-card-base-spacing);
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-md);
	line-height: var(--yxt-line-height-xs);
	font-weight: var(--yxt-font-weight-normal);
	font-style: normal;
	color: var(--yxt-color-text-primary)
}

.yxt-StandardCard, .yxt-StandardCard-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.yxt-StandardCard-wrapper {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-line-pack: start;
	align-content: flex-start;
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1
}

.yxt-StandardCard-ordinalWrapper {
	margin-right: calc(var(--yxt-standard-card-base-spacing)*3/4)
}

.yxt-StandardCard-ordinal {
	padding: calc(var(--yxt-standard-card-ordinal-size)*3/16) calc(var(--yxt-standard-card-ordinal-size)*1/8) calc(var(--yxt-standard-card-ordinal-size)*5/16);
	border-radius: var(--yxt-standard-card-ordinal-size);
	min-width: calc(var(--yxt-standard-card-ordinal-size)*7/8);
	height: calc(var(--yxt-standard-card-ordinal-size)*5/8);
	background-color: var(--yxt-color-brand-primary);
	border: .0625rem solid #000;
	text-align: center;
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-standard-card-ordinal-font-size);
	line-height: var(--yxt-line-height-xs);
	font-weight: var(--yxt-font-weight-bold);
	font-style: normal;
	color: #fff
}

.yxt-StandardCard-imgTagWrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: #000;
	padding-top: calc(var(--yxt-base-spacing-sm)/4);
	padding-bottom: calc(var(--yxt-base-spacing-sm)/4);
	position: relative;
	top: 10%;
	width: 80%;
	left: 10%
}

.yxt-StandardCard-imgTag {
	text-align: center;
	letter-spacing: .03125rem;
	text-transform: uppercase;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	word-break: break-word;
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-md);
	line-height: 1.25rem;
	font-weight: var(--yxt-font-weight-semibold);
	font-style: normal;
	color: #fff
}

.yxt-StandardCard-img {
	height: calc(var(--yxt-standard-card-base-spacing)*10);
	min-width: calc(var(--yxt-standard-card-base-spacing)*10);
	max-width: calc(var(--yxt-standard-card-base-spacing)*15);
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 0 0;
	margin-bottom: calc(var(--yxt-standard-card-base-spacing)/2);
	margin-right: var(--yxt-standard-card-base-spacing)
}

.yxt-StandardCard-contentWrapper {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 3;
	-ms-flex-positive: 3;
	flex-grow: 3;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap
}

.yxt-StandardCard-title {
	font-weight: var(--yxt-font-weight-bold);
	color: var(--yxt-color-text-primary)
}

.yxt-StandardCard-title, .yxt-StandardCard-titleLink {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-md-lg);
	line-height: var(--yxt-line-height-lg);
	font-style: normal
}

.yxt-StandardCard-titleLink {
	text-decoration: none;
	display: block;
	font-weight: var(--yxt-font-weight-semibold);
	color: var(--yxt-standard-card-link-color)
}

.yxt-StandardCard-titleLink:focus, .yxt-StandardCard-titleLink:hover {
	color: var(--yxt-standard-card-link-hover-color);
	text-decoration: underline
}

.yxt-StandardCard-subtitle {
	padding-bottom: calc(var(--yxt-standard-card-base-spacing)/2);
	text-decoration: none;
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-md);
	line-height: 1.25rem;
	font-weight: var(--yxt-font-weight-normal);
	font-style: normal;
	color: var(--yxt-standard-card-subtitle-color)
}

.yxt-StandardCard-content {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between
}

.yxt-StandardCard-content, .yxt-StandardCard-details {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.yxt-StandardCard-details {
	min-width: calc(var(--yxt-standard-card-base-spacing)*6);
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-bottom: calc(var(--yxt-standard-card-base-spacing)/2);
	margin-right: var(--yxt-standard-card-base-spacing);
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 2;
	-ms-flex-positive: 2;
	flex-grow: 2;
	word-break: break-word;
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-md);
	line-height: var(--yxt-line-height-md);
	font-weight: var(--yxt-font-weight-normal);
	font-style: normal;
	color: var(--yxt-color-text-primary)
}

.yxt-StandardCard-details--noImage {
	-ms-flex-preferred-size: calc(var(--yxt-standard-card-base-spacing)*16);
	flex-basis: calc(var(--yxt-standard-card-base-spacing)*16)
}

@media (max-width: 47.9375rem) {
	.yxt-StandardCard-details {
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%
	}
}

.yxt-StandardCard-toggle {
	text-align: left;
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-md);
	line-height: var(--yxt-line-height-md);
	font-weight: var(--yxt-font-weight-normal);
	font-style: italic;
	color: var(--yxt-color-brand-primary);
	background: none;
	border: .0625rem solid transparent;
	border-radius: .25rem;
	padding: 0
}

.yxt-StandardCard-toggle:not(:disabled) {
	cursor: pointer
}

.yxt-StandardCard-toggle:not(:disabled):hover {
	text-decoration: underline
}

.yxt-StandardCard-toggle:not(:disabled):focus {
	color: var(--yxt-color-brand-hover);
	border: .0625rem solid var(--yxt-color-brand-hover)
}

.yxt-StandardCard-ctas {
	min-width: calc(var(--yxt-standard-card-base-spacing)*8);
	width: 30%;
	-ms-flex-item-align: start;
	align-self: flex-start;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-preferred-size: 0;
	flex-basis: 0
}

:root {
	--yxt-result-background-color: #fff;
	--yxt-result-event-background-color: var(--yxt-color-background-highlight);
	--yxt-result-day-font-size: var(--yxt-font-size-xxlg);
	--yxt-result-day-line-height: var(--yxt-line-height-sm);
	--yxt-result-day-font-weight: var(--yxt-font-weight-semibold);
	--yxt-result-day-color: var(--yxt-color-text-primary);
	--yxt-result-month-font-size: var(--yxt-font-size-md);
	--yxt-result-month-line-height: var(--yxt-line-height-md);
	--yxt-result-month-font-weight: var(--yxt-font-weight-normal);
	--yxt-result-month-color: var(--yxt-color-text-primary);
	--yxt-result-ordinal-background-color: var(--yxt-color-brand-primary);
	--yxt-result-ordinal-font-size: var(--yxt-font-size-xs);
	--yxt-result-ordinal-line-height: var(--yxt-line-height-xs);
	--yxt-result-ordinal-font-weight: var(--yxt-font-weight-bold);
	--yxt-result-ordinal-color: #fff;
	--yxt-result-details-font-size: var(--yxt-font-size-md);
	--yxt-result-details-line-height: var(--yxt-line-height-md);
	--yxt-result-details-font-weight: var(--yxt-font-weight-normal);
	--yxt-result-details-color: var(--yxt-color-text-primary);
	--yxt-result-title-font-size: var(--yxt-font-size-lg);
	--yxt-result-title-line-height: var(--yxt-line-height-lg);
	--yxt-result-title-font-weight: var(--yxt-font-weight-semibold);
	--yxt-result-title-color: var(--yxt-color-text-primary);
	--yxt-result-subtitle-font-size: var(--yxt-font-size-md);
	--yxt-result-subtitle-line-height: var(--yxt-line-height-md);
	--yxt-result-subtitle-font-weight: var(--yxt-font-weight-normal);
	--yxt-result-subtitle-color: var(--yxt-color-text-secondary);
	--yxt-result-cta-font-size: var(--yxt-font-size-md);
	--yxt-result-cta-line-height: var(--yxt-line-height-md);
	--yxt-result-cta-font-weight: var(--yxt-font-weight-semibold);
	--yxt-result-cta-color: var(--yxt-color-link-primary)
}

.yxt-Result {
	list-style: none
}

.yxt-Result + .yxt-Result {
	border-top: var(--yxt-border-default)
}

.yxt-Result-title {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-result-title-font-size);
	line-height: var(--yxt-result-title-line-height);
	font-weight: var(--yxt-result-title-font-weight);
	font-style: normal;
	color: var(--yxt-result-title-color);
	margin-top: 0;
	margin-bottom: calc(var(--yxt-base-spacing)/2)
}

.yxt-Result-titleLink {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.yxt-Result-titleLink:link, .yxt-Result-titleLink:visited {
	color: var(--yxt-color-brand-primary);
	text-decoration: none
}

.yxt-Result-titleLink:active, .yxt-Result-titleLink:focus, .yxt-Result-titleLink:hover {
	color: var(--yxt-color-brand-primary);
	text-decoration: underline
}

@media (max-width: 47.9375rem) {
	.yxt-Result-titleLink {
		display: inline
	}
}

.yxt-Result-ordinalWrapper {
	margin-right: var(--yxt-base-spacing-sm)
}

.yxt-Result-ordinal {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-result-ordinal-font-size);
	line-height: var(--yxt-result-ordinal-line-height);
	font-weight: var(--yxt-result-ordinal-font-weight);
	font-style: normal;
	color: var(--yxt-result-ordinal-color);
	background-color: var(--yxt-result-ordinal-background-color);
	height: 1.125rem;
	width: 1.125rem;
	border-radius: 50%;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-top: .1875rem
}

.yxt-Result-ordinal, .yxt-Result-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.yxt-Result-wrapper {
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	max-width: 100%;
	padding: var(--yxt-base-spacing);
	background-color: var(--yxt-result-background-color)
}

.yxt-Result-imageWrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	width: 6.25rem;
	min-width: 6.25rem;
	margin-right: 1rem
}

.yxt-Result-image {
	width: 100%
}

.yxt-Result-info {
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%
}

@media (max-width: 47.9375rem) {
	.yxt-Result-info {
		-ms-flex-preferred-size: auto;
		flex-basis: auto
	}
}

.yxt-Result-infoCtasWrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%
}

@media (max-width: 47.9375rem) {
	.yxt-Result-infoCtasWrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column
	}
}

.yxt-Result-details {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-result-details-font-size);
	line-height: var(--yxt-result-details-line-height);
	font-weight: var(--yxt-result-details-font-weight);
	font-style: normal;
	color: var(--yxt-result-details-color)
}

.yxt-Result-ctasWrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: top;
	-ms-flex-align: top;
	align-items: top;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-negative: 0;
	flex-shrink: 0
}

@media (min-width: 48rem) {
	.yxt-Result-ctasWrapper {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		margin-left: calc(var(--yxt-base-spacing-sm)*2)
	}
}

@media (max-width: 47.9375rem) {
	.yxt-Result-ctasWrapper {
		margin-top: calc(var(--yxt-base-spacing)/2)
	}
}

.yxt-Result-ctas {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

@media (max-width: 47.9375rem) {
	.yxt-Result-ctas {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column
	}
}

.yxt-Result-cta {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
	padding: calc(var(--yxt-base-spacing)/2);
	border-radius: .25rem;
	min-width: 3.75rem;
	text-align: center
}

.yxt-Result-cta:link, .yxt-Result-cta:visited {
	color: var(--yxt-color-brand-primary);
	text-decoration: none
}

.yxt-Result-cta:active, .yxt-Result-cta:focus, .yxt-Result-cta:hover {
	color: var(--yxt-color-brand-primary);
	text-decoration: underline
}

@media (max-width: 47.9375rem) {
	.yxt-Result-cta {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		padding-left: 0
	}
}

.yxt-Result-cta + .yxt-Result-cta {
	margin-left: calc(var(--yxt-base-spacing)/2)
}

.yxt-Result-ctaIconWrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-left: calc(var(--yxt-base-spacing)*2);
	padding-right: calc(var(--yxt-base-spacing)*2);
	margin-bottom: calc(var(--yxt-base-spacing)/2)
}

@media (max-width: 47.9375rem) {
	.yxt-Result-ctaIconWrapper {
		padding-left: 0;
		padding-right: 0;
		margin-right: .5rem;
		margin-bottom: 0
	}
}

.yxt-Result-ctaIcon {
	width: 1.5rem;
	height: 1.5rem
}

.yxt-Result-ctaLabel {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-result-cta-font-size);
	line-height: var(--yxt-result-cta-line-height);
	font-weight: var(--yxt-result-cta-font-weight);
	font-style: normal;
	color: var(--yxt-result-cta-color);
	letter-spacing: .03125rem;
	text-transform: uppercase
}

.yxt-Result-dateWrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-left: calc(var(--yxt-base-spacing)*2);
	margin-right: calc(var(--yxt-base-spacing)*3);
	padding-left: var(--yxt-base-spacing);
	padding-right: var(--yxt-base-spacing)
}

@media (max-width: 47.9375rem) {
	.yxt-Result-dateWrapper {
		display: none
	}
}

.yxt-Result-dateInnerWrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	border-radius: .5rem;
	padding: calc(var(--yxt-base-spacing)/4);
	text-align: center;
	background-color: var(--yxt-result-event-background-color)
}

.yxt-Result-day {
	font-size: var(--yxt-result-day-font-size);
	line-height: var(--yxt-result-day-line-height);
	font-weight: var(--yxt-result-day-font-weight);
	color: var(--yxt-result-day-color)
}

.yxt-Result-day, .yxt-Result-month {
	font-family: var(--yxt-font-family);
	font-style: normal
}

.yxt-Result-month {
	font-size: var(--yxt-result-month-font-size);
	line-height: var(--yxt-result-month-line-height);
	font-weight: var(--yxt-result-month-font-weight);
	color: var(--yxt-result-month-color)
}

.yxt-Result-subtitle {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-result-subtitle-font-size);
	line-height: var(--yxt-result-subtitle-line-height);
	font-weight: var(--yxt-result-subtitle-font-weight);
	font-style: normal;
	color: var(--yxt-result-subtitle-color);
	margin-bottom: calc(var(--yxt-base-spacing)/2)
}

.yxt-Result-features {
	list-style: disc;
	padding-left: 1.125rem
}

:root {
	--yxt-standard-card-cta-icon-size: calc(1.125*var(--yxt-base-spacing));
	--yxt-standard-card-cta-icon-size-solo: calc(1.5*var(--yxt-base-spacing));
	--yxt-standard-card-cta-spacing: var(--yxt-base-spacing);
	--yxt-accordion-card-cta-spacing: var(--yxt-base-spacing);
	--yxt-accordion-card-cta-icon-size: var(--yxt-base-spacing);
	--yxt-accordion-card-cta-font-size: var(--yxt-font-size-md);
	--yxt-accordion-card-cta-font-weight: var(--yxt-font-weight-normal);
	--yxt-accordion-card-cta-color: var(--yxt-color-brand-primary)
}

.yxt-CTA {
	text-decoration: none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	word-break: break-word;
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-md);
	line-height: var(--yxt-line-height-xs);
	font-weight: var(--yxt-font-weight-semibold);
	font-style: normal;
	color: var(--yxt-color-brand-primary)
}

.yxt-CTA:active, .yxt-CTA:focus, .yxt-CTA:hover {
	text-decoration: underline
}

.yxt-CTA .Icon svg {
	height: calc(var(--yxt-base-spacing)*1.125);
	width: calc(var(--yxt-base-spacing)*1.125)
}

.yxt-CTA--StandardCard {
	margin-bottom: calc(var(--yxt-standard-card-cta-spacing)*0.5)
}

.yxt-CTA--StandardCard .Icon {
	height: var(--yxt-standard-card-cta-icon-size);
	width: var(--yxt-standard-card-cta-icon-size);
	margin-right: calc(var(--yxt-base-spacing)/2)
}

@media (min-width: 48rem) {
	.yxt-CTA--StandardCard.yxt-CTA--solo {
		text-align: center;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column
	}

	.yxt-CTA--StandardCard.yxt-CTA--solo .Icon {
		height: var(--yxt-standard-card-cta-icon-size-solo);
		width: var(--yxt-standard-card-cta-icon-size-solo);
		margin-bottom: calc(var(--yxt-standard-card-cta-spacing)/2);
		margin-right: 0
	}
}

.yxt-CTA--AccordionCard {
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-accordion-card-cta-font-size);
	line-height: var(--yxt-line-height-xs);
	font-weight: var(--yxt-accordion-card-cta-font-weight);
	font-style: normal;
	color: var(--yxt-accordion-card-cta-color)
}

.yxt-CTA--AccordionCard .Icon {
	height: var(--yxt-accordion-card-cta-icon-size);
	width: var(--yxt-accordion-card-cta-icon-size);
	margin-right: calc(var(--yxt-accordion-card-cta-spacing)*0.5)
}

:root {
	--yxt-cards-margin: calc(var(--yxt-base-spacing)/2)
}

.yxt-Card {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-bottom: var(--yxt-cards-margin)
}

.yxt-Card, .yxt-Card-placeholder {
	min-width: var(--yxt-cards-min-width);
	border: var(--yxt-border-default);
	margin-right: var(--yxt-cards-margin)
}

.yxt-Card-placeholder {
	visibility: hidden;
	border-top: none;
	border-bottom: none
}

.yxt-Card-child {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	min-height: .0625rem
}

.yxt-Card--universal {
	margin-bottom: 0
}

.yxt-Card--universal + .yxt-Card--universal {
	border-top: 0
}

.yxt-Card _:-ms-fullscreen, :root .yxt-Card {
	padding-left: .0625rem
}

@media (max-width: 47.9375rem) {
	.yxt-Card {
		margin-bottom: 0
	}

	.yxt-Card + .yxt-Card {
		border-top: 0
	}
}

.yxt-Results-items > .yxt-Card, .yxt-Results-items > .yxt-Card-placeholder {
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1
}

.yxt-Results-items--1 > .yxt-Card {
	margin-right: 0
}

@media (min-width: 47.9375rem) {
	.yxt-Results-items--2 > .yxt-Card .yxt-Results-items--2 > .yxt-Card-placeholder {
		-ms-flex-preferred-size: 45%;
		flex-basis: 45%
	}

	.yxt-Results-items--3 > .yxt-Card, .yxt-Results-items--3 > .yxt-Card-placeholder {
		-ms-flex-preferred-size: 30%;
		flex-basis: 30%
	}

	.yxt-Results-items--4 > .yxt-Card, .yxt-Results-items--4 > .yxt-Card-placeholder {
		-ms-flex-preferred-size: 22%;
		flex-basis: 22%
	}
}

.yxt-Card--universal {
	border: var(--yxt-border-default);
	border-top: 0;
	margin: 0
}

:root {
	--yxt-accordion-card-spacing: var(--yxt-base-spacing);
	--yxt-accordion-card-background-color: #fff;
	--yxt-accordion-card-content-background-color: #fff;
	--yxt-accordion-card-toggle-icon-height: var(--yxt-accordion-card-spacing);
	--yxt-accordion-card-toggle-icon-width: calc(var(--yxt-accordion-card-spacing)*7/10);
	--yxt-accordion-card-title-size: var(--yxt-font-size-md-lg);
	--yxt-accordion-card-title-color: var(--yxt-color-brand-primary);
	--yxt-accordion-card-title-color-hover: var(--yxt-color-background-highlight);
	--yxt-accordion-card-title-line-height: var(--yxt-line-height-lg);
	--yxt-accordion-card-title-weight: var(--yxt-font-weight-normal);
	--yxt-accordion-card-subtitle-size: var(--yxt-font-size-md);
	--yxt-accordion-card-subtitle-color: var(--yxt-color-text-secondary);
	--yxt-accordion-card-details-size: var(--yxt-font-size-md);
	--yxt-accordion-card-details-weight: var(--yxt-font-weight-normal);
	--yxt-accordion-card-details-line-height: calc(var(--yxt-accordion-card-details-size)*10/7);
	--yxt-accordion-card-details-color: var(--yxt-color-text-primary);
	--yxt-accordion-card-margin: calc(var(--yxt-base-spacing)/2);
	--yxt-accordion-card-border: var(--yxt-border-default);
	--yxt-accordion-card-background-color--expanded: var(--yxt-base-spacing);
	--yxt-accordion-card-spacing--expanded: var(--yxt-base-spacing)
}

.yxt-AccordionCard {
	background-color: var(--yxt-accordion-card-background-color)
}

@media (max-width: 47.9375rem) {
	.yxt-AccordionCard {
		margin-bottom: 0;
		border-top: 0
	}
}

.yxt-AccordionCard-toggle {
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	cursor: pointer;
	background: none;
	border: 0;
	padding: 0;
	padding-right: calc(var(--yxt-accordion-card-spacing)*0.5);
	-webkit-tap-highlight-color: rgba(0,0,0,0)
}

.yxt-AccordionCard-toggle:focus, .yxt-AccordionCard-toggle:hover {
	background-color: var(--yxt-accordion-card-title-color-hover)
}

.yxt-AccordionCard-toggle svg {
	height: var(--yxt-accordion-card-toggle-icon-height);
	width: var(--yxt-accordion-card-toggle-icon-width);
	color: var(--yxt-color-brand-primary)
}

.yxt-AccordionCard-title {
	margin: calc(var(--yxt-accordion-card-spacing)/2) var(--yxt-accordion-card-spacing) calc(var(--yxt-accordion-card-spacing)*5/8);
	text-align: left;
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-accordion-card-title-size);
	line-height: var(--yxt-accordion-card-title-line-height);
	font-weight: var(--yxt-accordion-card-title-weight);
	font-style: normal;
	color: var(--yxt-accordion-card-title-color)
}

.yxt-AccordionCard-icon {
	margin-left: auto;
	margin-right: calc(var(--yxt-accordion-card-spacing)/2);
	margin-top: calc(var(--yxt-accordion-card-spacing)*0.75);
	-ms-flex-negative: 0;
	flex-shrink: 0
}

.yxt-AccordionCard-icon svg {
	height: .75rem;
	width: .5rem;
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg)
}

.yxt-AccordionCard-body, .yxt-AccordionCard-icon svg {
	-webkit-transition: all .4s ease-in;
	transition: all .4s ease-in
}

.yxt-AccordionCard-body {
	overflow: hidden
}

.yxt-AccordionCard-content {
	padding: calc(var(--yxt-accordion-card-spacing)/2) var(--yxt-accordion-card-spacing);
	height: 0
}

.yxt-AccordionCard-subtitle {
	font-size: var(--yxt-accordion-card-subtitle-size);
	line-height: var(--yxt-line-height-xs);
	font-weight: var(--yxt-font-weight-normal);
	color: var(--yxt-accordion-card-subtitle-color)
}

.yxt-AccordionCard-details, .yxt-AccordionCard-subtitle {
	font-family: var(--yxt-font-family);
	font-style: normal;
	padding-bottom: calc(var(--yxt-accordion-card-spacing)/2)
}

.yxt-AccordionCard-details {
	font-size: var(--yxt-accordion-card-details-size);
	line-height: var(--yxt-accordion-card-details-line-height);
	font-weight: var(--yxt-accordion-card-details-weight);
	color: var(--yxt-accordion-card-details-color)
}

.yxt-AccordionCard-ctas .yxt-CTACollection-item {
	padding-bottom: calc(var(--yxt-accordion-card-spacing)/2)
}

.yxt-AccordionCard--expanded {
	background-color: var(--yxt-accordion-card-background-color--expanded)
}

.yxt-AccordionCard--expanded .yxt-AccordionCard-icon svg {
	height: .75rem;
	width: .5rem;
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg)
}

.yxt-AccordionCard--expanded .yxt-AccordionCard-body, .yxt-AccordionCard--expanded .yxt-AccordionCard-icon svg {
	-webkit-transition: all .4s ease-in;
	transition: all .4s ease-in
}

.yxt-AccordionCard--expanded .yxt-AccordionCard-content {
	height: auto;
	overflow: hidden
}

.yxt-AccordionCard--expanded .yxt-AccordionCard-ctas {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column
}

:root {
	--yxt-results-header-font-size: var(--yxt-font-size-md);
	--yxt-results-header-spacing: var(--yxt-base-spacing);
	--yxt-results-header-color: var(--yxt-color-text-secondary);
	--yxt-results-header-font-weight: var(--yxt-font-weight-normal);
	--yxt-results-header-line-height: var(--yxt-line-height-md);
	--yxt-results-header-margin-bottom: calc(var(--yxt-base-spacing)/4);
	--yxt-results-header-count-color: var(--yxt-color-text-secondary);
	--yxt-results-header-count-weight: var(--yxt-font-weight-bold);
	--yxt-results-header-filters-font-size: var(--yxt-font-size-md);
	--yxt-results-header-filters-color: var(--yxt-color-text-secondary);
	--yxt-results-header-filters-line-height: var(--yxt-line-height-md);
	--yxt-results-header-universal-background: var(--yxt-color-brand-white)
}

.yxt-ResultsHeader {
	padding: calc(var(--yxt-results-header-spacing)/4) var(--yxt-results-header-spacing);
	padding-bottom: 0
}

.yxt-ResultsHeader, .yxt-ResultsHeader-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: baseline;
	-ms-flex-align: baseline;
	align-items: baseline
}

.yxt-ResultsHeader-wrapper {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap
}

.yxt-ResultsHeader-resultsCount {
	margin-right: calc(var(--yxt-results-header-spacing)/4);
	margin-bottom: .25rem;
	white-space: nowrap;
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-results-header-font-size);
	line-height: var(--yxt-results-header-line-height);
	font-weight: var(--yxt-results-header-count-weight);
	font-style: normal;
	color: var(--yxt-results-header-count-color)
}

.yxt-ResultsHeader-appliedFilters {
	margin-right: var(--yxt-results-header-spacing);
	border-top: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.yxt-ResultsHeader-filterLabel {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-results-header-filters-font-size);
	line-height: var(--yxt-results-header-filters-line-height);
	font-weight: var(--yxt-font-weight-normal);
	font-style: normal;
	color: var(--yxt-results-header-filters-color)
}

.yxt-ResultsHeader-filterLabel, .yxt-ResultsHeader-filterValue {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-right: calc(var(--yxt-results-header-spacing)/4);
	margin-bottom: .25rem
}

.yxt-ResultsHeader-filterValueComma, .yxt-ResultsHeader-filterValueText {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-results-header-filters-font-size);
	line-height: var(--yxt-results-header-filters-line-height);
	font-weight: var(--yxt-font-weight-normal);
	font-style: italic;
	color: var(--yxt-results-header-filters-color)
}

.yxt-ResultsHeader-filterSeparator, .yxt-ResultsHeader-resultsCountSeparator {
	margin-right: calc(var(--yxt-results-header-spacing)/4);
	margin-bottom: calc(var(--yxt-results-header-spacing)/4);
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-results-header-filters-font-size);
	line-height: var(--yxt-results-header-filters-line-height);
	font-weight: var(--yxt-font-weight-normal);
	font-style: normal;
	color: var(--yxt-results-header-filters-color)
}

.yxt-ResultsHeader-changeFilters {
	margin-left: calc(var(--yxt-results-header-spacing)/2);
	margin-bottom: calc(var(--yxt-results-header-spacing)/4);
	padding-right: var(--yxt-results-header-spacing);
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-results-filters-link-font-size);
	line-height: var(--yxt-results-filters-link-line-height);
	font-weight: var(--yxt-results-filters-link-font-weight);
	font-style: normal;
	color: var(--yxt-color-brand-primary)
}

.yxt-ResultsHeader-changeFilters:active, .yxt-ResultsHeader-changeFilters:focus, .yxt-ResultsHeader-changeFilters:hover, .yxt-ResultsHeader-changeFilters:link, .yxt-ResultsHeader-changeFilters:visited {
	color: var(--yxt-color-brand-primary);
	text-decoration: underline
}

.yxt-ResultsHeader-removableFilterTag {
	background-color: var(--yxt-color-borders);
	border-radius: .125rem;
	border-width: 0;
	margin-bottom: .25rem;
	padding-left: .3125rem;
	padding-right: .25rem;
	margin-right: calc(var(--yxt-results-header-spacing)/2);
	white-space: nowrap;
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-sm);
	line-height: 1.25rem;
	font-weight: var(--yxt-font-weight-normal);
	font-style: italic;
	color: var(--yxt-color-text-neutral)
}

.yxt-ResultsHeader-removableFilterTag:focus, .yxt-ResultsHeader-removableFilterTag:hover {
	color: var(--yxt-color-brand-white);
	background-color: var(--yxt-color-text-secondary);
	cursor: pointer
}

.yxt-ResultsHeader-removableFilterX {
	font-style: normal
}

.yxt-ResultsHeader--universal {
	margin-bottom: 0;
	border: var(--yxt-border-default);
	border-top: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	background-color: var(--yxt-results-header-universal-background)
}

.yxt-ResultsHeader--removable .yxt-ResultsHeader-filterLabel, .yxt-ResultsHeader--removable .yxt-ResultsHeader-filterSeparator, .yxt-ResultsHeader--removable .yxt-ResultsHeader-filterValue, .yxt-ResultsHeader--removable .yxt-ResultsHeader-resultsCount, .yxt-ResultsHeader--removable .yxt-ResultsHeader-resultsCountSeparator {
	margin-right: calc(var(--yxt-results-header-spacing)/2)
}

.yxt-VerticalResultsCount {
	white-space: nowrap;
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-md);
	line-height: var(--yxt-line-height-md);
	font-weight: var(--yxt-font-weight-bold);
	font-style: normal;
	color: var(--yxt-color-text-secondary)
}

.yxt-AppliedFilters {
	border-top: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.yxt-AppliedFilters-filterLabel, .yxt-AppliedFilters-filterSeparator, .yxt-AppliedFilters-filterValue {
	margin-right: calc(var(--yxt-base-spacing)/4);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex
}

.yxt-AppliedFilters-filterLabel, .yxt-AppliedFilters-filterSeparator, .yxt-AppliedFilters-filterValueComma, .yxt-AppliedFilters-filterValueText {
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-md);
	line-height: var(--yxt-line-height-md);
	font-weight: var(--yxt-font-weight-normal);
	font-style: normal;
	color: var(--yxt-color-text-secondary)
}

.yxt-AppliedFilters-filterValueComma, .yxt-AppliedFilters-filterValueText {
	font-style: italic
}

.yxt-AppliedFilters-filterSeparator {
	color: var(--yxt-color-text-secondary)
}

.yxt-AppliedFilters-removableFilterTag {
	background-color: var(--yxt-color-borders);
	border-radius: .125rem;
	border-width: 0;
	margin-bottom: .25rem;
	padding-left: .3125rem;
	padding-right: .25rem;
	margin-right: calc(var(--yxt-base-spacing)/2);
	white-space: nowrap;
	font-family: var(--yxt-font-family);
	font-size: var(--yxt-font-size-sm);
	line-height: 1.25rem;
	font-weight: var(--yxt-font-weight-normal);
	font-style: italic;
	color: var(--yxt-color-text-neutral)
}

.yxt-AppliedFilters-removableFilterTag:focus, .yxt-AppliedFilters-removableFilterTag:hover {
	color: var(--yxt-color-brand-white);
	background-color: var(--yxt-color-text-secondary);
	cursor: pointer
}

.yxt-AppliedFilters-removableFilterX {
	font-style: normal
}
