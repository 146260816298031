/* ================================================================
   Variables
   ================================================================ */

$input-height: 30px !default;
$input-background: #ffffff !default;
$input-border-color: #d4d4d4 !default;
$input-border-width: 1px !default;
$input-border-radius: 5px !default;
$input-box-shadow: inset 0 1px 5px hsla(0%, 0%, 0%, 0.22) !default;
$input-box-shadow-active: inset 0 1px 5px hsla(0%, 0%, 0%, 0.22), inset 0 0 0 2px $color-primary !default;


/* ================================================================
   Wraps
   ================================================================ */

.form, form {
	margin-bottom: 2em;
}

.form-group {
	padding:10px 0; 
}

/* ================================================================
   Labels/Indicators
   ================================================================ */

.form-control-label { 
	display:block;
}

.required-indicator {  
	position:relative;
	padding-left:10px;

	&:before { 
		display:block; content:'*';
		position:absolute; left:0; top:0;
		color:red;
	}
}

.error-message {
	margin-top:5px;
	@include x-rem(font-size, 12px); color: red;
}

 



/* ================================================================
   Controls/Inputs
   ================================================================ */

input[type="text"], input[type="search"] {
    -webkit-appearance: none !important;
    border-radius: 0;
}

input[type="text"],
input[type="password"],
input[type="tel"],
input[type="email"] {
	font-size: 16px;
	font-size: 1rem;
	width: 100%;
	padding: 10px 16px;
	border-radius: 2px;
	display: block;
	border: 1px solid #949494;
	background: #fff;
	transition:right 0.2s ease-out;

	&:focus {
		border: 1px solid $color-primary;
	}
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
}

input[type="checkbox"],  
input[type="radio"] { 
	position: absolute;
	opacity: 0;
	margin-right: 2px;
	border-radius: 0;
	background: none;
}

.sfPageEditor {
	input[type="checkbox"] { 
		position: relative;
		opacity: 1;
	}

	input[type="radio"] {
		position: absolute;
		top: 2px;
		opacity: 1; 
	}
}

.checkbox-input + label, .radio-input + label {
	position: relative;
	display: inline-block;
	margin: 5px 0;
	cursor: pointer;
}

.checkbox-input + label ,.radio-input + label {
	&:before {
		content: '';
		display: inline-block;
		margin-right: 5px;
    vertical-align: bottom;
    width: 24px;
    height: 24px;
    background-color: #fff;
		border: 1px solid #949494;
	}
}

.radio-input + label {
	&:before {		
		border-radius: 50%;
	}
}


.checkbox-input:checked + label, .radio-input:checked + label {
	&:before {
		background-color: #007faa;
		border-color: #4c4c4c;
		box-shadow: inset 0 0 0 5px #fff;
		transition: all 0.4s ease-out;
	}
}


select {
	font-size: 16px;
	font-size: 1rem;
	width: 100%;
	padding: 10px 16px;
	border-radius: 2px;
	display: block;
	border: 1px solid #949494;
	background: #fff;
	transition:right 0.2s ease-out;

	
	&:focus {
		border: 1px solid #949494;
	}
}

textarea {
	min-height: 100px;
	font-size: 16px;
	font-size: 1rem;
	width: 100%;
	padding: 10px 16px;
	border-radius: 2px;
	display: block;
	border: 1px solid #949494;
	background: #fff;
	transition:right 0.2s ease-out;

	&:focus {
		border: 1px solid $color-primary;
	}
}

// Text input areas
.sfreContentArea {
	padding: 10px 13px;
	background: none;
}

.sf-FileField .sf-icon-remove {
	display: inline-block;
	cursor: pointer;
}

input[type=file].sf-FileField-input { display: inline-block; }

.sf-SubmitButton {
	padding: 0.8em 1em;
	text-align: left;
	min-width: 250px;
	margin-bottom: 1em;
}

input, textarea, select {
	&:focus {
		box-shadow: 0px 0px 5px 2px rgba(0,118,214,1);
	}
}

/* ================================================================
   Sitefinity Form Styles
   ================================================================ */

// Resets a bunch of sign up forms and makes them block instead of list style.
.sfLoginFieldsWrp, .sfprofileFieldsList, .sfregisterFieldsList {
	display: block;
	margin:1em 0; padding:0;

	li {
		display: block;
		margin:0; padding:0;

		+ li { margin-top:1em; }
	}
}

// RadEditor is Sitefinity's special textarea/lite WYSIWYG. Trying to catch most of the styling here but I'm sure there's more to be done.

.Sitefinity.RadEditor {
	td { background:none; }

	// Unnecessary padding cell to the left. No idea what's inside so hiding if empty;

	.reTlbVertical {
		&:empty { display:none; }
	}
    
    //can't do :empty as empty for this means &nbps; :(
	.reLeftVerticalSide { display:none;  }

	.reContentCell {
		border: none;

		iframe {
			border: $input-border-width solid $input-border-color; 	
			box-shadow:$input-box-shadow;	
			background: $input-background;
		}
	}

	// Adjust the "formatting options box"
	.reToolbarWrapper {
		position: absolute; top: -6px;  right: 0;
		margin-right: 0 !important; // overrides inline styles
	}
 
	.sfMoreTools { min-width: 0 !important; } //overrides inline styles 
}


// Textbox sizes as defined by the backend editor
.sfTxtBoxSmall .sfTxt,
.sfDdlSmall select { max-width:120px; }

.sfTxtBoxMedium .sfTxt,
.sfDdlMedium select  { max-width:300px }

.sfTxtBoxLarge .sfTxt,
.sfDdlLarge select  { max-width:400px }

.sfExample {	
    margin: 0.5em 0 1em;
	font-size: 11px;
	line-height: 1.4em;
}


/* Error Message
   ========================================================================== */

.sfError {
	margin-top: $grid-gutter / 4; padding: $grid-gutter / 2 $grid-gutter;
	@include x-rem(font-size, 12px); color: #fff;
	background: red;
}

/* Hide Input
   ========================================================================== */
   .partner-input--hidden {
	display: none;
  }