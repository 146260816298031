﻿/* ================================================================
	Popup
	================================================================ */

.lga_popup {
	display: none;
}

.modaal-container {
	border: 3px solid $color-blue;
}

.popup-content {
	padding: 20px;

	.title {
		font-size: 2rem;
		color: $color-blue;
		font-weight: $fontWeight-semibold;
		margin-top: 0;
	}

	.subText {
		font-size: 0.9rem;
		color: $color-taupe-grey;
		margin: 1.4rem 0;
	}

	.cta {
	}

	.img_container {
		width: 250px;
		height: 150px;
		overflow: hidden;

		img {
			box-shadow: 2px 1px 5px -1px #000;
		}
	}

	@media (min-width: $bp-m) {
		.img_container {
			height: 250px;
			float: right;
			margin-left: 1rem;
		}
	}
}