
.testimonials {
    margin: 20px 0;

    .slick-dots {
        margin: 0 !important;
        bottom: 10px;

        button {
            &::before {
                font-size: 46px;
            }
        }
    }

    .slick-next, .slick-prev {
        height: 20px;
        width: 20px;
        transition: border .3s ease-in-out;

        &:hover, &:focus {
            border-width: 3px 3px 0 0;
            border-color: $color-primary $color-primary transparent transparent;
        } 
    }
}

.testimonials--slide-list {
    padding: 50px 0;
    margin: 0;

    .testimonial-item {
        & + .testimonial-item {
            border-top: 1px solid $color-platinum;
            margin-top: 50px;
            padding-top: 50px;
        }
    }
}

.testimonials--list {
    padding: 40px 0;
    margin: 0;
    
    .testimonials__slider-item {
        & + .testimonials__slider-item {
            margin-top: 40px;
            padding-top: 40px;
        }
    }
}

//Testimonial Slide / Content Type

.testimonial-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.testimonial-item__image {
    background: 50% 50% no-repeat;
    background-size: cover;
    height: 200px;
    width: 200px;
}

.testimonial-item__byline {
    margin: 1.5em 0 0.5em;
    font-weight: $fontWeight-medium;
}

.testimonial-item__text {
    font-family: $font-heading;
    color: $color-blue-alt;
    font-size: 1.25rem;
    
    p {
        display: inline;

       & + p::before {
            content: "";
            display: block; 
            margin-top: 1em;
        }
    }
}

@media (min-width:$bp-mw) {
    .testimonials--slide-list {
        padding: 50px 0;
        margin: 0;
        
        .testimonial-item {
            padding: 0 20px 0;
        }
    }    
    .testimonial-item {
        padding: 20px;
        flex-direction: row;
        align-items: flex-start;
        
        &.testimonial-item--image-right {
            flex-direction: row-reverse;
        }
    }
    
    .testimonial-item__content {
        width: calc(100% - 220px);
    }
    
    .testimonial-item__text {
        font-family: $font-heading;
        color: $color-blue-alt;
        font-size: 1.5rem;
    }    
}
@media (min-width:$bp-l) {
    .testimonial-item__text {
        font-size: 2rem;
    }    
}


/* ================================================================
   TrustPilot Testimonials
   ================================================================ */

   .testimonials--trustpilot {
    padding-top: 3em;
    position: relative;
    border-bottom: 5px solid $color-secondary;
}

.testimonial-rating {
    text-align: center;
}

.testimonial-image {
    display: inline-block;
    position: relative;
    margin-top: 2em;
}

.testimonial-title {
    padding: 0 1em;

    h2 {
        color: $color-green;
    }
}

.testimonial-embed {
    position: absolute;
    top: 9%;
    right: 7%;
    width: 130px;
}

@media(min-width: $bp-mw) {
    .testimonials>.container {
        display: flex;
        flex-flow: row wrap;
    }

    .testimonial-slider {
        width: 60%;
    }

    .testimonial-rating {
        width: 40%;
    }
}

.testimonial-slider {
    .item {
        text-align: center;

        .content {
            padding: 1em;
        }

        .quote {
            font-size: 20px;

            &::before {
                content: "	\201C";
            }

            &::after {
                content: "	\201D";
            }
        }

        .name {
            color: $color-green;
            font-weight: 500;
            font-size: 18px;
            font-style: italic;
            margin-top: 1em;
            padding: 0 0.5em;

            &::before {
                content: "-";
            }
        }
    }
}

@media(min-width: $bp-mw) {
    .testimonial-slider {
        .item {
            text-align: left;

            .content {
                .name {
                    text-align: right;
                }
            }
        }
    }

    .testimonial-rating {
        text-align: right;
    }
}

@media(min-width: $bp-l) {
    .testimonial-slider {
        .item {
            .content {
                padding: 0 1em;
            }
        }

    }
}
