/* ================================================================
   Variables
   ================================================================ */

// Color Variables
$body-bg: #fff;
$color-base: #595959;
$color-font: #333;
$color-invert: #fff;
$color-primary: #004E86;
$color-secondary: #0072CF;
$color-white: #fff;
$color-black: #000;

//Blues
$color-blue: #0072CF;
$color-blue-alt: #0076D6;
$color-light-blue: #71CBF4;
$color-pastel-blue: #C9E8FB;
$color-dark-blue: #004E86;
$color-darkest-blue: #002a5a;


//Greens
$color-green: #028844;
$color-green-secondary: #005629;
$color-light-green: #BBD143;
$color-dark-green: #003519;
$color-pastal-green: #D9E6B1;

//Reds
$color-red: #DC241D;
$color-red-secondary: #E22922;
$color-dark-red: #A81815;
$color-light-red: #EC6084;
$color-pastel-red: #F6B2B6;

//Greys
$color-taupe-grey: #717171;
$color-warm-grey: #A3A3A3;
$color-dark-grey: #333333;
$color-platinum: #E3E3E3;
$color-white-smoke: #F6F6F6;
$color-advisor-grey: #F4F4F4;

//Yellow
$color-yellow: #FFD500;
$color-dark-yellow: #DD9C00;
$color-pastel-yellow: #FFEEA4;


// Typography 


@font-face {
  font-family: 'Lyon Display Web';
  src: url('/ResourcePackages/Talon/assets/dist/fonts/LyonDisplay-BoldItalic-Web.woff2') format('woff2'),
       url('/ResourcePackages/Talon/assets/dist/fonts/LyonDisplay-BoldItalic-Web.woff') format('woff');
  font-weight:  700;
  font-style:   italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lyon Display Web';
  src: url('/ResourcePackages/Talon/assets/dist/fonts/LyonDisplay-Bold-Web.woff2') format('woff2'),
       url('/ResourcePackages/Talon/assets/dist/fonts/LyonDisplay-Bold-Web.woff') format('woff');
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lyon Display Web';
  src: url('/ResourcePackages/Talon/assets/dist/fonts/LyonDisplay-RegularItalic-Web.woff2') format('woff2'),
       url('/ResourcePackages/Talon/assets/dist/fonts/LyonDisplay-RegularItalic-Web.woff') format('woff');
  font-weight:  400;
  font-style:   italic;
  font-stretch: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Lyon Display Web';
  src: url('/ResourcePackages/Talon/assets/dist/fonts/LyonDisplay-Regular-Web.woff2') format('woff2'),
       url('/ResourcePackages/Talon/assets/dist/fonts/LyonDisplay-Regular-Web.woff') format('woff');
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/ResourcePackages/Talon/assets/dist/fonts/roboto-v27-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/ResourcePackages/Talon/assets/dist/fonts/roboto-v27-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/ResourcePackages/Talon/assets/dist/fonts/roboto-v27-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('/ResourcePackages/Talon/assets/dist/fonts/roboto-v27-latin-300.woff') format('woff'), /* Modern Browsers */
}

@font-face {
  font-family: 'Roboto Regular';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/ResourcePackages/Talon/assets/dist/fonts/roboto-v27-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/ResourcePackages/Talon/assets/dist/fonts/roboto-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/ResourcePackages/Talon/assets/dist/fonts/roboto-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/ResourcePackages/Talon/assets/dist/fonts/roboto-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
}

$font-primary: 'Roboto Regular',sans-serif;
$font-secondary: 'Lyon Display Web';
$font-light: 'Roboto', sans-serif;
$font-heading: $font-secondary;
$font-open: 'Open Sans', sans-serif;
 
$fontWeight-light: 300;
$fontWeight-normal: 400;
$fontWeight-medium: 500;
$fontWeight-semibold: 600;
$fontWeight-bold: 700;
$fontWeight-extrabold: 800;
 
$typography-margin-bottom: 15px;
 

// Breakpoints 
// You can convert these at http://pxtoem.com 
$bp-s: 20em; // 320px 
$bp-s-max: 29.938em; // 479px
$bp-sw: 30em; // 480px
$bp-sw-max: 47.938em; // 767px
$bp-m: 48em; // 768px  
$bp-m-max: 59.938em; // 959px
$bp-mw: 60em; // 960px
$bp-mw-max: 71.188em; // 1139px 
$bp-l: 72em; // 1140px
$bp-l-max: 81.188em; // 1299px 
$bp-xl: 81.25em; // 1300px 
$bp-xxl: 100em; // 1600px 


// Navigation Variables
$nav-transition-speed: 0.4s;
$nav-barBackground: $color-invert; 
$nav-mainLinkBackground: $color-invert;  
$nav-subLinkBackground: $color-invert; 
  

// Grid
$grid-gutter: 15px; 
$grid-gutter-em: 0.938em; // Changing grid gutter to em causes a compiling error :/
