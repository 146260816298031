/* ================================================================
   Tab Styles
   ================================================================ */

.talon-tabs {
    margin-bottom:20px;

    .talon-tab-nav {
        ul {
            list-style:none;
            display:flex;
            margin:0; padding:0;

            li {
                a {
                    display:block;
                    padding:10px;
                    border-bottom:4px solid transparent;
                    transition:all .3s;

                    &.active {
                        background:#eee;
                        border-bottom:4px solid $color-primary;
                    }
                }
            }
        }
    }
    .talon-tab-content { padding:20px; background:#eee; }
    .talon-tab-pane { }
}

/* ================================================================
   Custom Tab Layouts
   ================================================================ */

   .tab-container {
       background-color: $color-invert;
       padding: 1em;
       margin: 3em 0 1em 0;
    .container {
        position: relative;
        padding: 0;
    }

    .tabs-wrapper {
        display: none;
    }    

    .tab-header, .tab-title {
        &.active {
            position: relative;                
        }
    }

    .tab-content {
        display: none;
        padding: 2em 1em;
        width: 100%;

        .sfContentBlock {
            margin: 0;
            padding: 0;
        }
    }

    .tab-title {
        position: relative;
        padding: 0.5em 0;
        margin: 0.5em 0;
        transition: $nav-transition-speed all ease-in;
        border-bottom: 1px solid $color-platinum;

        &:after {
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            border-top: 2px solid #4a4a4a;
            border-right: 2px solid #4a4a4a;
            position: absolute;
            right: 20px;
            top: 42%;
            -webkit-transform: rotate(135deg);
            -ms-transform: rotate(135deg);
            transform: rotate(135deg);
        }

        &.active:after {
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }

        h3 {
            font-weight: $fontWeight-bold;
            text-transform: uppercase;
        }

    }

}

@media(min-width: $bp-mw) {
    .tab-container {
        .tab {
            display: none;
        }

        .tabs-wrapper {
            display: block;
            border-bottom: 1px solid $color-platinum;
            > .container {
                display: flex;
                -ms-flex-flow: row wrap;
                flex-flow: row wrap;
            }
    
             a {
                display: inline-block;
                font-family: $font-secondary;
                font-size: 18px;
                font-weight: $fontWeight-bold;
                padding: 0.5em 1em;
                color: $color-base;
                transition:all $nav-transition-speed;

                &:hover, &:focus, &.active {
                    text-decoration: none;
                    border-bottom: 2px solid $color-secondary;
                }
            }
        } 
    }
}

.sfPageEditor  .tab,
.sfPageEditor .tab-content {
display: block !important; 
float: none; 
height: auto;
margin: 0; 
padding: 10px; 
clear:both; 
}

.sfPageEditor .tab-container {
display: block;
}
