/* ================================================================
   Site Header
   ================================================================ */

   .site-header {
    padding: 0;
    position: relative;
    z-index: 101;
    width: 100%;
    top: 0;
    left: 0;
    background: #fff;
    min-height: 70px;
    transition: all 0.2s linear;
    border-bottom: 1px solid $color-taupe-grey;

    &.sticky {
        position: fixed;
        top: 0;
        width: 100%;

        + .site-main {
            padding-top: 100px;
        }
    }


    ul {
        li:before {
            display: none;
        }
    }

    .grey-layout {
        padding: 0;
    }

    .col-md-12 {
        position: relative;
    }

    .logo {
        position: absolute;
        top: 0px;
        left: 50%;
        z-index: 1;
        transform: translateX(-50%);
        margin: 0;
        padding: 5px 0;

        svg {
            width: 60px;
            height: 45px;
            transition: all 0.2s linear;
        }

        &.advisor-logo img {
            width: 60px;
            
            @media screen and (min-width: $bp-l) {
                width: 140px;
                margin-bottom: 10px;
            }
        }
    }

    .header-logo {
        @media screen and (max-width: $bp-m-max) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 117px !important;
        }
    }

    .header-top {

        .sub-nav {
            padding: 0;
            display: flex;
            list-style: none;
            justify-content: flex-end;
            margin: 0.5em 0 -9px 0;
            transition: all 0.5s linear;

            li {
                margin-left: 20px;

                &:before {
                    display: none;
                }
            }

            a {
                @include x-rem(font-size, 14px);
                line-height: 1.8;
                color: $color-font;
                transition: font-size 0.2s linear;

                &:hover, &:focus {
                    color: $color-secondary;
                    text-decoration: none;
                }
            }

            svg {
                width: 17px;
                height: 17px;
                fill: $color-font;
            }
        }
    }

    .header-bottom {
        position: relative;
        // max-width: 200px;
    }

    .phone-href {
        display: block;
        margin: 0.3em 0;

        h2 {
            font-size: 1rem;
        }
    }

    .simple-base-header {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        max-width: 82em;

        .simple-logo {
            max-width: 30%;

            &:nth-child(n + 2) {
                border-left: 1px solid $color-platinum;
                margin-left: 0.5em;
                padding-left: 0.5em;

                img {
                    max-height: 60px;
                }
            }
        }

        .simple-contact {
            width: 70%;
        }
    }

    .simple-base-header__quote {
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        &:before, &:after {
            content: none;
        }

        .nav-right {
            margin: 0 2em 1em 0;

            & .sign-in__link,
            & .phone-href {
                color: $color-font;
                font-size: .875rem;
            }
        }

        .sign-in__link {
            margin-right: 21px;
            position: relative;

            @media (min-width: 300px) {
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 3px;
                    right: -13px;
                    height: 15px;
                    width: 1px;
                    background-color: $color-warm-grey;
                }
            }

        }

        .phone-href {
            display: inline-block;
            font-weight: $fontWeight-bold;
        }
    }

    .simple-logo .logo {
        position: relative;
        left: 0;
        top: 0;
        transform: none;

        svg {
            width: 77px;
            height: 65px;
        }
    }

    .simple-contact-us {
        display: block;
    }

    @media(min-width:$bp-l) {

        .header-top {
            display: block;
            visibility: visible;
            position: absolute;
            right: 15px;
            top: 0;
            z-index: 5;
        }


        .logo {
            display: block;
            @include x-rem(font-size, 12px);
            position: relative;
            left: 0;
            transform: translateX(-16%);
            margin: 0;
            padding: 15px 15px 5px;

            svg {
                width: 90px;
                height: 68px;
            }
        }

        &.sticky {
            .nav-bar > ul > li > a > span {
                height: 40px;
            }
        }

        &.sticky {
            box-shadow: 0 2px 12px rgba(0, 0, 0, 0.5);

            .header-bottom .logo svg {
                width: 80px;
                height: 70px;
            }

            .logo {
                padding: 15px 15px 0;
            }

            .header-top .sub-nav {
                position: absolute;
                z-index: 1;
                right: 10px;
                top: 2px;
                margin: 0;
                width: 80px;

                a {
                    font-size: 0;

                    svg {
                        fill: $color-secondary;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        .header-bottom {
            display: flex;
            position: unset;
        }

        .simple-base-header .logo svg {
            width: 120px;
            height: 100px;
        }

        .simple-contact-us {
            display: inline-block;
        }

        .phone-href {
            margin: 0.5em 0 0.3em 0;
        }

        .phone-href h2 {
            font-size: 1.3rem;
        }
    }

    @media(min-width: $bp-l) {

        .logo {

            &.advisor-logo svg {
                width: 180px;
                height: 120px;
            }
        }
    }




    .local-dropdown {
        display: none;
    }
    // 1140
    @media (min-width: $bp-l) {

        .header-bottom {
            align-items: flex-end;

            .st-cont {
                margin-left: auto;
                position: relative;
                top: 0px;
            }
        }

        .visible-xs {
            display: block !important;
        }

        .local-dropdown {
            display: block;
        }
    }
}

.sfPageEditorWrp {

    .header-bottom {
        display: block;
    }

    .site-header {
        position: static;
    }
}

.nav-right {
    margin-top: 0.5rem;
    text-align: right;

    .nav-right-upper {
        font-size: .8313rem;

        .icon {
            height: 1rem;
            width: 1rem;
            margin-left: 2rem;
        }
    }

    p,
    h2 {
        margin: 0;
    }

    h2 {
        font-size: 1.5rem;
    }
}

@media(min-width: $bp-l) {
    .nav-right {

        .nav-right-upper {
            font-size: .975rem;
        }
    }

}

#phone-number {
    color: #333333;
    font-family: Roboto;
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.75rem;
}


.modal-dialog,
.sfWidgetsWrp,
.rdCommands,
.sfPageEditorToolbarWrapper,
.RadMenu {
    li:before {
        display: none !important;
    }
}

/* ================================================================
			Site Main
	================================================================ */

.callouts-grid {
    .btn-box {
        margin: 0 auto;
    }

    // 959
    @media (max-width: $bp-m-max) {

        h3 {
            margin-bottom: 0;
            padding: 25px 0;
            @include x-rem(font-size, 16px);
            border-bottom: 2px solid #eaf4fd;
        }

        .col-md-3 {
            padding: 0;
        }

        br {
            display: none;
        }

        .btn-box {
            border: 0;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            font-size: 0;
            width: 100%;
            max-width: none;
            text-align: center;
            padding-bottom: 20px;
            color: $color-primary;
            background: transparent;
        }
    }

    // 960
    @media (min-width: $bp-mw) {
        padding: 0;

        h3 {
            @include x-rem(font-size, 17px);
        }
    }

    // 1152
    @media (min-width: $bp-l) {
        padding: 0;

        h3 {
            @include x-rem(font-size, 18px);
        }
    }
}

.interior-callouts {

    // 959
    @media (max-width: $bp-m-max) {
        margin-bottom: 60px;
    }
}

.home-middle {

    h2 {
        margin-top: 2em;
    }

    // 768 - 1151
    @media (min-width: $bp-m) and (max-width: $bp-mw-max) {
        .content-box {
            padding: 0;
        }
    }

    // 959
    @media (max-width: $bp-m-max) {
        .callouts-grid {
            margin-bottom: 0;
        }
    }

}

.callouts-blue-line {

    &:after {
        content: "";
        display: block;
        border: 2px solid #00aeef;
    }

    // 767
    @media (max-width: $bp-sw-max) {
        &:after {
            border-width: 1px;
            margin-top: -8px;
        }
    }

    // 768
    @media (min-width: $bp-m) {
        &:after {
            margin-top: -10px;
        }
    }
}

.callout-images {
    display: flex;

    div {
        flex: 0 0 25%;
        margin: 35px 0 0 0;
    }
}

.sfPageEditorWrp .callouts-blue-line:after {
    margin-top: 0;
}

/* ================================================================
			Interior
	================================================================ */

.landing-top,
.interior-header {

    // 768
    @media (min-width: $bp-m) {
        margin: 0 auto;
    }

}

.landing-bottom {
    padding: 2em 0;
}

.landing-bottom .lib {
    margin-top: 2em;
}

.interior-body-margin {
    margin-top: 0.5em;
}


.lib {

    // 959
    @media (max-width: $bp-m-max) {
        text-align: center;
    }
}

/* ================================================================
			Site Footer
	================================================================ */

.site-footer {

    ul {
        >li:before {
            display: none;
        }
    }

    .footer-content {
        @include x-rem(font-size, 14px);

        a {
            color: $color-primary;
        }

        .footer-sub-nav {
            list-style: none;
            padding: 0;
            margin: 20px 0 20px;
            @media (min-width: $bp-mw) {
                margin: 20px 0 0;
            }

            li {
                margin: 0 7px;
                display: inline-block;
            }
        }
    }

    .site-footer__social-link {
        &:hover,&:focus {
            svg {
                fill: $color-secondary;
            }
        }
    }
    .site-footer__social-icon {
        fill: $color-dark-grey;
        width: 24px;
        height: 24px;
        transition: .2s ease-in fill;
    }

    .btn-wh {
        @include x-rem(font-size, 15px);
    }

    .footer-sub-nav {
        li {
            margin: 0 !important;

            &:after {
                content: "|";
                display: inline-block;
                margin: 0 1em;
            }

            &:last-child:after {
                display: none;
            }
        }

        a {
            color: $color-dark-grey;
            font-size: 12px;
            line-height: 2;
        }
    }


}


.site-footer__social-icons {
    margin: 35px 0 0;
    @media (min-width: $bp-mw) {
        margin: 45px 0 0;
    }
}
.site-footer__social-icon {
    margin: 0 5px;
}
.site-footer__logo-link {
    display: block;
    padding: 10px 0;
}


.footer-row {
    text-align: center;
    margin-top: 15px;

    svg {
        width: 150px;
    }

    
}

@media screen and (min-width: 960px) {
    .footer-row {
        margin-top: 0;
        display: flex;
        align-items: baseline;
        text-align: unset;
    }
}


/* ================================================================
			Content Box
	================================================================ */

.content-box {
    padding: 40px 0;
    background-color: $color-primary;

    &.alt {
        background-color: $color-secondary;
    }

    &.nbg {
        background-color: transparent;
    }

    &.greyBg {
        background-color: #f5f5f5
    }
}

/* ================================================================
			Error messages
	================================================================ */

.errorMsgBox {
    margin: 3em 0;

    h1 {
        color: $color-primary;
    }

    p {
        color: $color-font;
        @include x-rem(font-size, 14px);
    }

    .btn {
        margin-bottom: 2em;
    }

    // 767
    @media (max-width: $bp-sw-max) {
        h1 {
            @include x-rem(font-size, 26px);
        }
    }
}

 
/* ================================================================
	Color Layouts
	================================================================ */

.no-padding {
    padding: 0 !important;
}

.blue-layout,
.green-layout,
.blue-layout,
.grey-layout {
    margin: 0;
    padding: 2em 0;

    >h2 {
        margin-top: 0em;
    }
}

.grey-layout {
    background-color: $color-white-smoke;
}

.blue-layout {
    background-color: $color-blue;
    color: $color-invert;

    h2,
    h3 {
        color: $color-invert;
    }
}

.dark-blue-layout {
    background-color: $color-dark-blue;
    color: $color-invert;

    h2,
    h3 {
        color: $color-invert;
    }
}

.light-blue-layout {
    background-color: $color-light-blue;
    color: $color-invert;

    h2,
    h3 {
        color: $color-invert;
    }
}

.green-layout {
    background-color: $color-green;
    color: $color-invert;

    h2,
    h3 {
        color: $color-invert;
    }
}

.white-layout {
    background-color: $color-invert;
    color: $color-base;
    padding: 2em;
}

.pastel-blue-layout {
    background: $color-pastel-blue;
    color: $color-font;
    padding: 2em 0;

    h2,
    h3 {
        color: $color-font;
    }
}

.pastel-green-layout {
    background: $color-pastal-green;
    color: $color-font;
    padding: 2em 0;

    h2,
    h3 {
        color: $color-font;
    }
}

.pastel-yellow-layout {
    background: $color-pastel-yellow;
    color: $color-font;
    padding: 2em 0;

    h2,
    h3 {
        color: $color-font;
    }
}

.red-layout {
    background: $color-red-secondary;
    color: $color-white;
    padding: 2em 0;

    h2,
    h3 {
        color: $color-white;
    }
}

.dark-green-layout {
    background: $color-green-secondary;
    color: $color-white;
    padding: 2em 0;

    h2,
    h3 {
        color: $color-white;
    }
}

.black-layout {
    background-color: $color-black;
    color: $color-white;
    padding: 2em 0;
    
    h2,
    h3 {
        color: $color-white;
    }
}


/* ================================================================
			Cookie Message
	================================================================ */

.cookie-msg {
    position: fixed;
    bottom: 104px;
    left: 0;
    width: 100%;
    padding: 1em;
    background: $color-white-smoke;
    box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.25);
    text-align: center;
    z-index: 99;

    p {
        margin-bottom: 0;
    }
}

.cookie-text,
.cookie-action {
    display: inline-block;
}

.cookie-text {
    max-width: 830px;
    vertical-align: top;
}

.cookie-action {
    margin-top: 0.5em;
}

.accept-btn {
    position: relative;
    background: transparent;
    color: $color-dark-blue;

    &:after {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-top: 2px solid $color-dark-blue;
        border-right: 2px solid $color-dark-blue;
        position: absolute;
        right: 8px;
        top: 45%;
        transform: rotate(45deg) translateY(-45%);
        transition: all .2s ease-out;
    }

    &:hover {
        background: transparent;
        color: $color-dark-blue;
    }
}

@media(min-width: $bp-m) {
    .cookie-msg {
        bottom:124px;
    }
    
    .cookie-text {
        text-align: left;
    }

    .accept-btn {
        background: $color-dark-blue;
        color: $color-invert !important;

        &:after {
            display: none;
        }
    }
}

@media(min-width: $bp-mw) {
    .cookie-msg {
        bottom: 79px;
    }

    .cookie-text {
        text-align: left;
    }
}
		
/* ================================================================
    Loading Spinner
================================================================ */
.loader {
    border: 4px solid $color-blue-alt;
    border-top: 4px solid #E22922;
    border-right: 4px solid #028844;
    border-left: 4px solid #FFD500;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    animation: spin 2s linear infinite;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* ================================================================
    Fix to titles hidden in content blocks by the sticky header
================================================================ */

.sfContentBlock {

    h2 > a[id],
    h3 > a[id] {
        display: inline-block;
        font-size: inherit;
        color: inherit;
        text-decoration: none;
        padding-top: 155px;
        margin-top: -155px;
    }
}

// Spacer Layout
.spacer {
    padding: 15px;

    @media(min-width: $bp-m) {
        padding: 30px;
    }
}

// Advisor Featured Heading
.advisor-featured {
    p {
        max-width: 1168px;
        margin: 0 auto 2em auto;
    }
}

// Advisor Form
.advisor-form {
    &.mt {
        margin-top: -3rem;
    }

    label {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        margin: -1px;
        padding: 0;
        border: 0;
        width: 1px;
        height: 1px;
    }

    input, select {
        max-width: 516px;
        border-radius: 0;
        border: none;
        height: 54px;
        font-size: 20px;
        color: #737373;
    }

    button {
        background: $color-blue-alt !important;
        min-width: 0;
        font-size: 20px;
        border-radius: 0;

        &:after {
            display: none;
        }
    }
}

// Advisor Table 
.advisor-table {
    max-width: 1060px;
    margin: 4em auto !important;
    text-align: left;
    border-top: 1px solid #bcbcbc;

    tr {
        border-bottom: 1px solid #bcbcbc;
    }

    td, th {
        padding: 1em 0;
    }
}

// Metrics Section
.metrics-headline {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2, p {
        margin: 0;
    }
}

.metrics-card {
    background: $color-invert;
    padding: 2em;
    border-radius: 3px;
    min-height: 256px;

    .metrics-card__title {
        color: $color-black;
        font-size: 3rem;
        font-weight: $fontWeight-bold;
        margin-bottom: .5rem;
    }

    &.metrics-card--flex {
        min-height: 0;
        display: flex;
        height: 156px;

        img {
            max-width: 97px;
        }
    }

    &.metrics-card--tall {
        min-height: 342px;

        .metrics-card__tag {
            display: block;
            margin-bottom: 2rem;
        }
    }
}

.metrics-card__heading {
    img {
        margin-right: 10px;
    }
}

.metrics-card__tag {
    color: $color-dark-blue;
    font-weight: $fontWeight-bold;
    text-transform: uppercase;
}

.metrics-card__text {
    color: $color-base;
}
