/* ================================================================
    Search
================================================================ */



/* Search Form */

.search-form {
    position: relative;
    border: 2px solid $color-primary;
    border-width: 2px;
    transition: all 0.35s linear 0s;

    input[type="search"] {
        border: 1px solid #c2dbe2;
        color: $color-font;
        width: 470px;
        padding: 9px 10px 9px 45px;
        font-family: $font-primary;
        font-weight: $fontWeight-light; 
        @include x-rem(font-size,18px);

        ::-webkit-input-placeholder {
            color: #9b9b9b;
        }

        :-ms-input-placeholder {
            color: #9b9b9b;
        }

        ::-moz-placeholder {
            color: #9b9b9b;
        }

        :-moz-placeholder {
            color: #9b9b9b;
        }
    }


    .form-group {
        padding: 0;
    }

    button {
        background: transparent;
        font-size: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 40px;
        width: 40px;
        padding: 0;
        border-radius: 0;

        &:hover, &:focus {
            background: transparent;
        }

        &:after {
            display: none;
        }
    }
    // 767
    @media (max-width: $bp-sw-max) {
        width: 100%;

        input[type="search"] {
            width: 100%;
        }
    }
    // 768
    @media (min-width: $bp-m) {
        right: 2.2em;

        &[data-visible="true"] {
            margin-top: 0;
        }
    }
}

.btn-yxt-SearchBar-wrapper-toggle {
    border: 0;
    background: none;
    position: absolute;
    right: 15px;
    top: -40px;
    padding: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;

    &[aria-checked="true"] {

        svg {
            display: none;
        }

        &:before, &:after {
            content: "";
            background: #000;
            position: absolute;
            height: 18px;
            width: 3px;
            top: 6px;
            right: 13px;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    @media (min-width: $bp-l) {
        display: none;
        visibility: hidden;
    }
}

.yext_search_form {
    display: none;
    visibility: hidden;

    &.active {
        display: block;
        visibility: visible;
    }

    @media (min-width: $bp-l) {
        display: block;
        visibility: visible;
    }
}

.yext_search_form {
    margin-bottom: 12px; 
 
    @media (min-width: $bp-l) {
        max-width: 200px
    }

    @media (min-width: $bp-xl) {
        max-width: 280px
    }
}

.yext_search_form .yxt-SearchBar, .yext_search_form-errorpage .yxt-SearchBar {
    position: relative;
    height: 2.5rem;
    margin-bottom: 0.4rem;
    transition: all 0.35s linear 0s;
    border: 1px solid rgb(118, 118, 118);
    border-radius: 5px;

    .yxt-SearchBar-container {
        border: 0;
        height: 100%;
        border-radius: 0;
        background: none;
    }

    input[type="text"] {
        border: 0;
        color: $color-font;
        width: 100%;
        padding: 9px 10px;
        padding-right: 67px;
        font-family: $font-primary;
        font-weight: $fontWeight-light;
        border-radius: 5px 0 0 5px;
        height: 38px;
        background: transparent;
        @include x-rem(font-size,18px);

        ::-webkit-input-placeholder {
            color: #9b9b9b;
        }

        :-ms-input-placeholder {
            color: #9b9b9b;
        }

        ::-moz-placeholder {
            color: #9b9b9b;
        }

        :-moz-placeholder {
            color: #9b9b9b;
        }
    }

    .js-yext-submit.yxt-SearchBar-button {
        position: absolute;
        right: 0;
        border-radius: 5px;
        padding: 9px 10px 4px;

        .Icon-image {
            width: 1.1rem;
            height: 1.1rem;
        }
    }

    @media (min-width: $bp-l) {
        input[type="text"] {
            width: 200px
        }
    }

    @media (min-width: $bp-xl) {
        input[type="text"] {
            width: 280px
        }
    }
}

.yext_search_form .yxt-SearchBar-container {
    overflow: visible;
}

.js-yxt-SearchBar-clear {
    position: absolute;
    right: 37px;
    height: 37px;
}

.yxt-SearchBar-autocomplete,
.yxt-AutoComplete-wrapper,
.yxt-AutoComplete {
    border-radius: 5px;
}

.yxt-AutoComplete {
    border: 1px solid #d8d8d8;
}

.yxt-AutoComplete-wrapper .yxt-AutoComplete::before {
    background: none;
}

.search-results-header {

    h1 {
        color: $color-primary;
        margin-bottom: 0.5em;
        font-weight: $fontWeight-light;
        @include x-rem(font-size,32px);

        em {
            font-weight: $fontWeight-medium
        }
    }
}

.search-results {

    .sf-search-results {

        .sf-media {
            margin: 25px 0;
        }

        p {margin: 0;}

    }

    h2 {
        margin-bottom: .5em;
        font-weight: $fontWeight-light;
        @include x-rem(font-size,22px);        
    }

    .page-link {
        position: relative;        
        
        &:before{
            content: "";
            display: inline-block;
            border: 4px solid #000;
            border-right: 0;
            border-top-color: transparent;
            border-bottom-color: transparent;
            margin: 0 5px 1px 0;
            transition: all .25s linear;
        }

        &:after{
            content: "";
            display: block;
            height: 2px;
            background: $color-secondary;
            position: absolute;
            top: 100%;
            right: 0;
            width: 0;
            transition: width .25s linear;
        }

        &:hover,&:focus{
            text-decoration: none;

            &:before{ margin-right: 12px; }
            &:after { width: 100%; }

        }
    }

}

//Fix for autocomplete list picking up default list styling when in content block
li.yxt-AutoComplete-option:before {
    content: none !important;
}

/* Hiding Autocomplete (for now) */
.ui-autocomplete.ui-menu.ui-widget.ui-widget-content.ui-corner-all.sf-autocomplete {
    display: none !important;
}