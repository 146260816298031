/* ================================================================
   Typography Styles
   ================================================================ */


h1, .h1, h2, .h2, h3, .h3 {
	margin: 0.5em 0;
	font-family:$font-heading;
}

h1, .h1 { @include x-rem(font-size, 32px);font-weight: $fontWeight-bold; color: $color-font; }
h2, .h2 { @include x-rem(font-size, 24px);font-weight: $fontWeight-light; color: $color-font; font-family: $font-primary;}
h3, .h3 { @include x-rem(font-size, 20px);font-weight: $fontWeight-light; color: $color-font; font-family: $font-primary;}
h4, .h4 { @include x-rem(font-size, 18px); font-weight: $fontWeight-bold; font-family: $font-primary; margin: 0.3em 0; }

.advisor {
	h1,.h1,
	h2,.h2,
	h3,.h3,
	h4,.h4 {
		color: $color-black;
		font-weight: $fontWeight-bold;
	}
}

@media(min-width: $bp-mw) {

h1, .h1 { @include x-rem(font-size, 40px);}
h2, .h2 { @include x-rem(font-size, 32px);}
h3, .h3 { @include x-rem(font-size, 24px);}
h4, .h4 { @include x-rem(font-size, 20px);}
}

.feature-heading {
	display: block;
	@include x-rem(font-size, 30px);
	font-weight: $fontWeight-light;
}

.feature-heading--lyon {
	display: block;
	@include x-rem(font-size, 30px);
	font-weight: $fontWeight-bold;
	font-family: $font-secondary;
}

.heading-bold {
	font-weight: $fontWeight-semibold;
	color: $color-black;
}

a {
	color:$color-primary;
	display: inline-block;
	text-decoration:none;

	&:hover, &:focus { text-decoration:underline; }
}
// a[target="_blank"] { cursor:alias }

p { margin:0 0 $typography-margin-bottom; }

// Very simple blockquote styling
blockquote {
	font-style: italic;
	margin: 1.5em auto;
	color: $color-secondary;
	text-align: center;
	@include x-rem(font-size,18px);

	&:before,
	&:after {
		content:"";
		display: block;
		width: 44px;
		height: 4px;
		margin: 15px auto;
		background: $color-secondary;
	}
}

// pull quote
.blueBox{
	background: rgba(#84c9df ,0.6);
	color: $color-primary;
	padding: 20px 35px;
	border-radius: 3px;
}

.smallest { font-size:0.8em; }
.smaller { font-size:0.9em; }
.larger { font-size:1.1em; }
.largest { font-size:1.2em; }

.bold { font-weight:bold; }
.italic { font-style:italic; }
.strike { text-decoration:line-through; }

.nowrap { white-space:nowrap; }
.important { color:red; font-weight:bold; text-transform:uppercase; }

.section-heading {
	display: block;
	margin: 1.5em 0 0.5em 0;
	color: $color-base;
	&:after {
		content: "";
		display: block;
		margin: 0.3em 0;
		width: 40px;
		height: 5px;
		background-color: $color-dark-blue;
	}

	&.align-center {
		display: block;

		&:after {
			margin: 0.5em auto;
		}
	}
}

.divider-line {
    border-top: 1px solid $color-taupe-grey;
    &.white {
        border-top: 1px solid $color-white-smoke;
    }
    &.blue {
        border-top: 1px solid $color-primary;
    }
}

.image-border img {
	border: 1px solid #333333;
}
