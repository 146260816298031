.lazyload-video__list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.lazyload-video__item {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }

    &:before {
        content: none;
    }
}