/* ================================================================
 Find an Agent Form
   ================================================================ */

.find-an-agent {
  margin-bottom: 2em;
}
   
.agent-form {
  background: $color-white-smoke;
  padding: 2em;
  margin-bottom: 2em;

  form {
    margin: 0;
  }

  #btnSubmit {
    margin-top: 0.5em;
  }
}

.agency-name {
  @include x-rem("font-size", 20px);
  color: $color-base;
  font-weight: $fontWeight-bold;
}

.agency-address, .agency-name {
  font-style: normal;
  text-transform: uppercase;
}

.agent-list {
  margin-bottom: 2em;
}

.agent-form-title {
  margin-top: 0;
}

.agent-text {
  @include x-rem("font-size", 18px);
}

.agent-error {
  margin-top: 0.5em;
	font-family: $font-primary;
	flex: 1 100%;
	text-align: left;
	color: $color-invert;
	padding: 0.5em;
	background: rgba(226,41,34, 0.7);
}