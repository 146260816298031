/* ******************************************************
   NAVIGATION
        - CLICK MENU STYLES
   ****************************************************** */


   .cm-menu {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;

    a, a:visited {
        color: $color-invert;
        text-decoration: none;
    }

    *, *:after, *:before {
        box-sizing: border-box;
    }

    &:focus {
        outline: 3px solid blue; 
    }

    ul {
        display: none;
        margin: 0;
        padding: 0;
        max-height: 0;
        list-style: none;
        background: $nav-mainLinkBackground;
        opacity: 0;
        overflow: hidden;
        transition: max-height $nav-transition-speed ease-in-out, opacity $nav-transition-speed ease-in-out;
    }

    li {
        position: relative;
        margin-bottom: 0;

        &:before {
            display: none;
        }

        &:first-child {
            border-top: 0;
        }

        a {
            display: block;
            position: relative;
            padding: 1em;
        }
    }

    .has-sub:after,
    .expander-wrap .has-sub > span:after {
        content: " ";
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 10px;
        margin: 2px 0 0 5px;
        vertical-align: middle;
        border: 5px solid transparent;
        /* Extra is for anti-aliasing issues in firefox and others */
        border: 5px solid rgba(0,0,0,0);
        border-top-color: $color-secondary;
        transition: all $nav-transition-speed ease-in-out;
        transform: scale(0.98) translateY(-50%);
    }

    .opened > .has-sub:after,
    .opened > .expander-wrap .has-sub > span:after {
        border-top-color: $color-secondary;
        transform: scale(0.98) translateY(-75%) rotate(-180deg);
    }

    .opened > ul {
        max-height: 80em;
        opacity: 1;
        display: block;
    }
}

.toggle-menu + .cm-menu {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height $nav-transition-speed ease-in-out, opacity $nav-transition-speed ease-in-out;
}

.cm-js-active {
    .cm-menu {
       // max-height: 80em;
        max-height: calc(100vh - 120px);
        overflow: auto;
        opacity: 1;
    }
}


/* ================= SEPARATE EXPANDERS OPTION IS ENABLED ================= */

.cm-menu .expander-wrap {
    display: table;
    width: 100%;

    a {
        display: table-cell;
    }

    .has-sub {
        position: relative;
        width: 30px;

        &:after {
            display: none;
        }

        > span {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: #aaa;

            &:after {
                content: " ";
                display: inline-block;
                position: absolute;
                top: 50%;
                right: 50%;
                margin: 2px 0 0 5px;
                vertical-align: middle;
                border: 5px solid transparent;
                /* Extra is for anti-aliasing issues in firefox and others */
                border: 5px solid rgba(0,0,0,0);
                border-top-color: #fff;
                transition: all $nav-transition-speed ease-in-out;
                transform: scale(0.98) translate(50%, -50%);
            }
        }
    }
}

.cm-menu .opened > .expander-wrap .has-sub > span:after {
    border-top-color: $nav-subLinkBackground;
    transform: scale(0.98) translate(50%, -75%) rotate(-180deg);
}

/* ================= MENU OPEN FUNCTIONALITY ADDED ON SETUP ================= */

@media (min-width:$bp-l) {
    .toggle-menu + .cm-menu {
        max-height: 80em;
        opacity: 1;
        overflow: visible;
    }

    .cm-menu {
        ul {
            top: 100%;
            overflow: hidden;
        }

        .opened > ul {
            max-height: 80em;
            opacity: 1;
        }

        .animated > ul {
            overflow: visible;
            z-index: 10;
        }

        .animating > ul {
            z-index: -1;
        }
    }
}


/*================================================================
                 CLICK MENU MENU TYPES
================================================================*/

/*  NAV BAR STYLE TYPE
    ================================================================*/
.nav-bar {

    .cm-menu li {

        a {
            color: $color-black;
            font-weight: $fontWeight-bold;
            position: relative;
            @include x-rem(font-size,16px);
        }

        a:hover, a:focus {
            color: $color-secondary;
        }

        li a {
            background: $color-platinum;
            border-bottom: 0;
            padding: 10px 25px;
            font-weight: 300;
            color: #666666;

            &:hover, &:focus {
                color: $color-secondary;
                background: $color-pastel-blue;
                border-left: 5px solid $color-blue;
            }
        }


        .link-landing a {
            position: relative;
            color: $color-secondary;
            font-size: 1.125rem;
            font-weight: 700;

            &:after {
                content: "";
                position: absolute;
                top: 50%;
                right: 10px;
                display: inline-block;
                width: 10px;
                height: 10px;
                border-top: 2px solid $color-secondary;
                border-right: 2px solid $color-secondary;
                margin-left: 1em;
                transform: rotate(45deg) translateY(-50%);
            }

            &.active {
                &:after {
                    transform: rotate(-45deg) translateY(-50%);
                }
            }
        }
    }
    // 768
    @media (min-width:$bp-l) {


        .cm-menu {
            position: relative;
            display: flex;
            flex-flow: row wrap;
            transition: top 0.25s linear;
            margin-bottom: 0.5em;
            max-width:1100px;
        }

        li {
            border-top: 0;
        }

        > ul {
            display: table;
            width: 100%;
            table-layout: fixed;

            > li {
                display: block;
                padding: 0;

                > a {
                    display: table;
                    width: 100%;
                    padding: 1em 0.7em;

                    > span {
                        display: table-cell;
                        padding: 0 10px;
                        height: 15px;
                        vertical-align: middle;
                        text-align: center;
                    }
                }

                > ul {
                    position: absolute;
                }
            }
        }

        .has-sub span:after {
            position: static;
            margin-top: 10px;
        }

        ul {
            ul .has-sub:after {
                right: 5px;
            }

            li li {
                margin: 5px;
            }

            li li li {
                margin-left: 0;
                margin-right: 0;
            }

            ul a {
                padding: 3px 15px;
                background: #999;
            }

            ul a:hover, .nav-bar ul ul a:focus,
            ul .opened > a {
                color: #000;
                background: #ddd;
            }
        }
    }

    @media(min-width: $bp-l) {
        > ul {
            padding-left: 2em;

            > li > a {
                padding: 1em 2em;
            }
        }
    }
}


.sticky-header {
    // 1140
    @media (min-width: $bp-l) {
        .cm-menu {
            top: 0;

            li {
                a:after {
                    bottom: 5px;
                    top: auto;
                }

                a:hover:after, a:focus:after, &.active a:after {
                    bottom: 2px;
                }
            }
        }
    }
}

/*  ACCORDION NAVIGATION
    ================================================================*/

/* ------------- CURRENTLY KEEPS SMALL SCREEN STYLES -------------- */




/*  DROP DOWN NAVIGATION
    ================================================================*/

@media (min-width:$bp-mw) {
    [data-type="dropdown"] {
        ul {
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: -1;
            width: 100%;
            min-width: 180px;
            background: $nav-subLinkBackground;
            transition: max-height $nav-transition-speed ease-in-out, opacity $nav-transition-speed ease-in-out, left $nav-transition-speed ease-in-out, right $nav-transition-speed ease-in-out;

            ul {
                top: -5px;
            }
        }

        .opened > ul {
            left: 100%;
            max-height: 80em;
            opacity: 1;
        }
    }

    li[data-type="dropdown"]:nth-last-child(-n+2) {
        ul {
            left: auto;
            right: 0;
        }

        .opened > ul {
            left: auto;
            right: 100%;
        }
    }

    .nav-bar ul li[data-type="dropdown"] li li {
        margin-left: 5px;
        margin-right: 5px;
    }

    [data-type="dropdown"] a + .has-sub {
        width: 0;
        min-height: 0;
        height: 100%;

        > span {
            min-height: 0;
            height: 100%;
        }
    }
}



/*================================================================
                 NAVIGATION TOGGLE STYLES
================================================================*/

.toggle-menu {
    display: block;
    position: relative;
    width: 58px;
    height: 58px;
    border: 0;
    cursor: pointer;
    background: transparent;


    &.active {

        &:after {
            display: none;
        }

        &:before {
            top: 30px;
            transform: rotate(-315deg);
        }

        > span:before {
            top: 30px;
            transform: rotate(315deg);
        }
    }

    &:before, &:after, > span:before {
        content: " ";
        position: absolute;
        left: 15%;
        width: 70%;
        height: 2px;
        border-radius: 2px;
        background: #898989;
        transition: all 0.2s linear;
    }

    &:before {
        top: 22px;
    }

    &:after {
        top: 29px;
    }

    > span:before {
        top: 36px;
    }
    // 1140
    @media (min-width:$bp-l) {
        display: none;
    }
}

/*================================================================
Secondary - Top Navigation
================================================================*/

.top-menu {
    .menu {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            display: inline-block;
            margin-bottom: 0;
            margin-left: -1px;
            padding: 0;
            border-left: 1px solid $color-warm-grey;    
            border-right: 1px solid $color-warm-grey;    

            &:before {
                display: none;
            }
        
            a {
                display: inline-block;
                color: $color-font;
                padding: 10px 15px;
                font-size: 14px;
                font-weight: 500;

                &:hover, &:focus {
                    text-decoration: none;
                    color: $color-secondary;
                    background-color: $color-invert;
                }
            }

            button {
                color: $color-font;
                padding: 10px 15px;
                font-size: 14px;
                border: none;
                line-height: 1.2rem;
                background-color: transparent;
                font-weight: 500;
                height: 100%;

                &:hover, &:focus {
                    text-decoration: none;
                    color: $color-secondary;
                    background-color: $color-invert;
                }
            }

            &.active {
                color: $color-secondary;
                background-color: $color-invert;
                border: none;       
                z-index: 1;
                font-weight: 700;

                a, button {
                    font-weight: 700;
                }
            }
        }
    }
}

@media(min-width: $bp-l) {
    .top-menu {
        .menu {
            justify-content: left;

            li {
                &:first-child {
                    display: inline-block;
                }
            }
        }
    }
}


/*  Page Menu
    ================================================================*/

    .page-menu {
        .cm-menu {
            display: none;
                &.open {
                    display: flex;
                    flex-flow: row wrap;
                }
            li {
                border: 1px solid $color-platinum;
                border-bottom: 0;
                flex: 1 100%;            

                &:last-child {
                    border-bottom: 1px solid $color-platinum;
                }

                a {
                    color: $color-base;
                    padding: 1.5em 1em;
                    font-weight: $fontWeight-bold;
                    transition: 0.3s color ease-in;

                    &:hover, &:focus {
                        color: $color-secondary;
                    }
                }
            }
        }
        .page-menu-selector {   
            position: relative;         
            padding: 1em;
            border-top: 1px solid lightgray;
            border-bottom: 1px solid lightgray;
            text-align: center;

            &:after {
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                border-top: 2px solid #4a4a4a;
                border-right: 2px solid #4a4a4a;
                position: absolute;
                right: 20px;
                top: 42%;
                -webkit-transform: rotate(135deg);
                -ms-transform: rotate(135deg);
                transform: rotate(135deg);
            }
    
        }
    }

@media(min-width: $bp-mw) {
    .page-menu {
        margin-bottom: 2em;
        border-bottom: 1px solid $color-platinum;

        .cm-menu {
            display: flex;
            flex-flow: row wrap;
            max-width: 80em;
            margin: 0 auto;
            text-align: center;

            li {
                display: inline-block;
                flex: 1 20%;
                border: none;

                &.active a {
                    border-bottom: 2px solid $color-blue;
                    color: $color-secondary;
                }

                &:last-child {
                    border-bottom: 0;
                }
            }
        }

        .page-menu-selector {
            display: none;
        }
    }
}

    
/*  Sub Nav
    ================================================================*/

@media screen and (max-width: 959px) {
    .sub-nav {
        max-height: 120px !important;
        background: #f4f4f4 !important;
        color: #363636;
        display: flex !important;
        opacity: 1 !important;

        li {
            width: 50%;
            text-align: center;
            border: 1px solid #ccc !important;

            &:hover, &:focus {
                background: #ccc;
            }

            a {
                padding: 25px 10px;
            }
        }
    }
}