/* ================================================================
   Boilerplate - Base Styles
   ================================================================ */

html, button, input, select, textarea { color:$color-font; }
body { font-family:$font-primary; line-height:1.5; overflow-y:scroll; background:$body-bg }

::-moz-selection { background:$color-primary; color:$color-invert; text-shadow:none; }
::selection { background:$color-primary; color:$color-invert; text-shadow:none; }

*, *:before, *:after { box-sizing:border-box; }

hr { display:block; margin:1em 0; padding:0; height:1px; border:0; border-top:1px solid #ccc;  }
img { vertical-align:middle; width:auto; height:auto; max-width:100%; }
.image-fluid, .image-fluid img { max-width:none; width:100%; height:auto; }
.image-fixed, .image-fixed img { max-width:none; } 

fieldset { border:0; margin:0; padding:0; }
textarea { resize:vertical; }


.hidden { display:none !important; visibility: hidden; }
.visuallyhidden, .visually-hidden, .visuallyHidden { position:absolute; overflow:hidden; clip:rect(0 0 0 0); margin:-1px; padding:0; border:0; width:1px; height:1px; }
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus, .visuallyhidden.focusable:focus-within { position:static; overflow:visible; clip:auto; margin:0; width:auto; height:auto; }
.invisible { visibility:hidden; }

.clearfix {
	&:before, &:after { content:" "; display:table; }
	&:after { clear:both; }
}

.align-center { text-align:center; }
.align-right { text-align:right; }
.align-left { text-align:left; }

.no-padding {
	padding: 0;
}

.extra-padding {
	padding: 3em;
}

.mb {
	margin-bottom: 40px;
}

.container.padding-left-right, .padding-left-right {
	padding: 0 1.5em;
}

.container.padding-top-bottom, .padding-top-bottom {
	padding: 1.5em 0;
}

.breakforprint { page-break-after:always; }

.float-right { float:right }
.float-left { float:left }

figure { margin:1em 0; }

.button,.btn {
	@include btn("Blue");
}

.button-wh,.btn-wh {
	@include btn("White");
}

.button-box, .btn-box {
	@include btn("Box");

	&.btn-green {
		background: $color-green;

		&:after {
			border-color: $color-invert;
		}

		&:hover, &:focus {
			background: $color-dark-green;
		}
	}

	&.btn-blue {
		background: $color-primary;

		&:after {
			border-color: $color-invert;
		}

		&:hover, &:focus {
			background: $color-darkest-blue;
		}
	}

	&.btn-green, &.btn-blue {
		display: inline-block;
		color: $color-invert;
		border: none;
	}

	&.btn-inline {
		display: inline-block;
	}
}

.caretBtn{
	color: $color-primary;
	@include x-rem(font-size,16px);
	font-weight: $fontWeight-bold;
	cursor: pointer;

	&:after{
		content:"";
		display: inline-block;
		width: 10px;
		height: 10px;
		border-top: 2px solid $color-primary;
		border-right: 2px solid $color-primary;
		transform: rotate(45deg);
		margin-left: 0.5em;
		transition: 0.2s all linear;
	}

	&:hover, &:focus {
		&:after {
			margin-left: 1em;
		}
	}
}
.caretBtn--white {
    color: #fff;
    &:after {
        border-color: #fff;
    }
}

.blog-details {
	ul {
		list-style:none;
		li{
			position:relative;
			padding-left:1em; 
			&:before {
				display:block; position:absolute; left:0; top:16px;
				content: "";
				width:5px; height:5px;
				border-radius:10px;
				background-color: $color-dark-grey;
			}
		}
	}
}

/* ================================================================
   Error page - 404
   ================================================================ */
.errorpage {
	background-image: url(/ResourcePackages/Talon/assets/dist/images/Moon-background.png);
	background-position: bottom right;
	background-repeat: no-repeat;
	background-size: contain;
	height: 100vh;

	&-content-block{
		margin:0 !important;
		padding:0 !important;
	}

	.yext_search_form {
		display: block;
		visibility: visible;
	}

	ul.yxt-AutoComplete-results {
		li:before {
			content: none;
		}
	}

	&__header-image {
		background-image: url(/ResourcePackages/Talon/assets/dist/images/Star-background.png);
		width: 100%;
		background-size: cover;
		height: 214px;
	}

	&__content-wrap {
		margin: 3em 0;
	}

	&__content {
		&-headline {
			font-size: 5rem;
			margin: 0;
			line-height: 1;

			&-message {
				font-size: 3rem;
				margin: 0;
			}
		}

		&-copy {
			font-family: 'Roboto', sans-serif;
			font-weight: 300;
			font-size: 1.125rem;
			line-height: 1.1;
			margin-bottom: 1em;
		}

		&-button {
			padding: 0.5em 1em;
			color: $color-white;
			background-color: $color-primary;
			border-radius: 5px;
			display: inline-block;
			margin-top: .5em;
			transition: background-color ease-in-out 200ms;

			&:hover, &:focus {
				background-color: $color-light-blue;
				color: $color-black;
				text-decoration: none;
			}
		}
	}

	&__image {
		img {
			max-height: 250px;
			position: absolute;
			right: 100px;
		}
	}

	@media(min-width:$bp-m) {
		background-size: 50%;

		&__content {
			&-headline {
				font-size: 6rem;
			}

			&-copy {
				font-size: 1.325rem;
			}
		}

		&__image {
			img {
				max-height: 350px;
				right: 50px;
			}
		}
	}
}


/* ================================================================
   Talon Util Boilerplate Additions
   ================================================================ */


.js-click-user *:focus, .js-click-user *:active { outline:0; }

.site-main {
	.sfContentBlock {		
		padding: 1em 0;
		margin: 1em 0;
	}
}

.scroll-cover {
    position: fixed; top: 0; left: 0; bottom: 0; right: 0;
    pointer-events: auto !important;
    z-index: 10000;
}

.svg-legend {
    position: absolute; top: -9999em; left: -9999em;
    width: 0; height: 0;
    overflow:hidden;
}

svg.icon {
	display:inline-block; vertical-align: middle;
	width:24px; height:24px;
	transition:all 0.3s ease-in-out;
}

.sf-Code {
	pre {
		color: #666;
	}
}

.skip-link {
	@extend .visually-hidden;
	text-align:center;

	&:focus {
		position:static;
		display:block;
		color:$color-invert;
		background:$color-primary;
		padding:20px 0;
		height:auto; width:100%;
	}
}

.resource-tags {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin-bottom: 2rem;

	li {
		list-style: none;
		padding: 0 !important;
		border: 2px solid $color-warm-grey;
		margin: 0 6px 12px 6px;

		&:before {
			display: none; 
		}

		a {
			color: $color-warm-grey;
			padding: .5rem 2rem;
			text-decoration: none;
			transition: .2s ease-out;

			&:hover,
			&:focus {
				color: $color-white;
				background: $color-secondary;
			}
		}

		&.active {
			a {
				color: $color-white;
				background: $color-secondary;
			}
		}
	}
}
.sf-editor {
	ul {
		li {
			&:before {
				display: none;
			}
		}
	}
}

.sf-field {
	ul {
		li {
			&:before {
				display: none;
			}
		}
	}
}

.k-editor-toolbar {
	li {
		&:before {
			display: none;
		}
	}
}

.sf-section-nav {
	ul {
		li {
			&:before {
				display: none;
			}
		}
	}
}

.view-full {
	a {
		text-decoration: underline;
	}
}

.anchor-target {
	&:before {
		content: '';
		display: block;
		height: 142px;
		margin: -142px 0 0;
	}
}

.sfContentBlock,
.content {
	p, div, li, span {
		a {
			font-weight: bold;
		}
	}
} 
