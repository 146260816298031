.sf-media {

	.sf-img-thmb{
		a {
			display: block;
			width: 120px; height: 90px;
			overflow: hidden;
		}
	}

	.sf-video-thmb {
		position: relative;
		width: 120px; height: 90px;
		background-color: #000;
 
		a {
			display: block;
			position: absolute; top: 50%;
			margin-top:-35px;
			height: 70px;
			overflow: hidden;

			.sf-icon-play {
				position: absolute; top: 50%; left: 50%;
				width: 30px; height: 30px; 
				margin-left: -15px; margin-top: -15px;
				border-radius: 50%;
				background-color: #000;
				opacity: 0.8;

				&:hover { background-color: lighten(#000, 20%); }

				&:before {
					content: ''; display: block;
					width: 0; height: 0;
					border-top: 6px solid transparent; border-bottom: 6px solid transparent; border-left: 8px solid #fff;
					position: absolute; left: 12px; top: 9px;
				}
			}
		}
	}

	.sf-media-body {
		h3 { margin-top: 0; }
	}
}

.iframe-container {
	width: 100%;
padding-top: 56.25% !important;
position: relative;
}

.iframe-aspect-ratio {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	
}