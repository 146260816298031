/* ================================================================
=   Contact Form To Pdf
=  ================================================================ */

.ctpdf {
    background-color: #fff;
    margin: 2rem auto;	
	max-width: 51.25rem;
    border: 1px solid transparent;
    border-radius: 4px;
    border-color: #ddd;        
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    &__form {
    	margin-bottom: 0;
    	padding: 1rem;
    }

    &__form-row {}
    &__form-row--submit {
    	margin-top: 0.5rem;
    	padding-top: 1rem;
    	border-top: 1px solid #ddd;
    	.field-p {
    		text-align: right;
    	}
    }
    .form-group {
    	label {
    		color: $color-black;
    	}
    }

    &__head {
    	margin-bottom: 1rem;
    }

    &__head-title {}
    &__help-text {
    	font-size: 0.875rem;
    }	

}

.ctpdf-iframe {
    width: 100%;
    max-height: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 51.250rem;
    overflow: hidden;
    transition: max-height 0.4s ease-out;

    &.active {
    	max-height: 80em;
    }

}
.ctpdf-iframe__inner {
    width: 100%;        
    position: relative;     
    padding-top: 160%;
}
.ctpdf-iframe__iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;       
}

.pdf_template_container {

}
.pdf_template_row {}
.pdf_template_top {}

.pdf_template_bottom {}
.pdf_template_bottom-content {
	background-color: pink;
	display: flex;
	.col-copy {}
	.col-text {}
	.col-logo {}
}
