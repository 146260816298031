/* Breadcrumbs
   ================================================================ */

.breadcrumb {
	list-style:none;
    margin: 1em 0 1em 0; 
    padding: 0;


    li {
        position:relative;
        opacity: 0;
        transform: translateX(15px);
        transition: all 0.35s ease-in 0s;
        display:inline-block;
        padding-left: 0 !important;

        &:before {
            display: none;
        }

        &:after {
            content:'>'; display:inline-block;
        }

        &:last-child {
            &:after { display:none; }
        }


  
        &:nth-child(1){transition-delay: 0.4s;}
        &:nth-child(2){transition-delay: 0.6s;}

        & + li {padding-left: 25px;}

        &, a {
            color: $color-secondary;
            text-transform: capitalize;
        }

        &.active {
            color: $color-font;
        }

    }

    // loaded class so js has enough time to edit the text while it's hidden
    &.loaded li{
        opacity: 1;
        transform: translateX(0);
    }

    // 767
    @media (max-width: $bp-sw-max){
        padding: 0 !important;

        li{
            @include x-rem(font-size,14px);

        }

    }

    // 768
    @media (min-width: $bp-m){

        li {
            @include x-rem(font-size,16px);


        }
    }
}