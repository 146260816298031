﻿/* Quote Information widget */

.get-quote__container {
  font-family: "Roboto";
  color: $color-font;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  @media (max-width: $bp-l) {
    background-image: none !important;
    background-color: $color-light-blue !important;
  }

  input:focus {
    outline: none;
  }

  .get-quote__title {
    font-family: $font-heading;
    font-weight: $fontWeight-bold;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: $color-font;
    
  }

  .form-group {
    text-align: left;
    padding: 0 0 15px 0;

    label {
      font-size: 0.9rem;
      font-weight: $fontWeight-bold;
    }

    select[name="PolicyAmount"] option:disabled {
      font-weight: $fontWeight-bold;
    }
  }

  #recaptchaV2Widget {
      position: relative;
      left: -12px;
  }

  input[type="text"],
  input[type="password"],
  input[type="tel"],
  input[type="email"],
  input[type="number"],
  input[type="radio"],
  .select-field {
    height: 3rem;
    display: block;
    padding: 0.8rem 0.9rem;
    font-size: 0.875rem;
    background-color: $color-white;
    border-radius: 10px;
    border: 1px solid transparent;
    width: 100%;
    margin-top: .5em;
    &:focus {
      outline: none;
      box-shadow: inset 0px 0 0px 2px $color-blue-alt;
    }
  }

  .quote__button {
    background: $color-secondary;
    color: $color-white;
    display: block;
    border-radius: 10px;
    width: 100%;
    border: none;
    padding: 15px;
    margin-top: 10px;
    width: 100%;

    &:hover,
    &:focus {
      background: $color-primary;
    }
  }

  fieldset {
    text-align: left;

    legend {
      font-size: 0.9rem;
      font-weight: $fontWeight-bold;
    }

    .small-label {
      font-size: 0.8rem;
      font-weight: $fontWeight-normal;
    }

    .form-group--flex {
      justify-content: space-between;
    }

    .form-group {
      width: 49%;
    }

    .radio-group {
      position: relative;
      display: block;
      border-radius: 10px;
      input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;

        &:focus + label {
          box-shadow: inset 0px 0 0px 2px $color-blue-alt;
        }

        &:checked + label {
          background-color: $color-white;
        }
      }

      label {
        display: inline-block;
        background-color: $color-white;
        width: 100%;
        height: 48px;
        text-align: center;
        padding: 16px 0;
        font-size: 0.8rem;
        font-weight: $fontWeight-bold;
        border-radius: 10px;
        border: 1px solid transparent;
        margin-top: .5em;

        &:hover {
          cursor: pointer;
          background-color: $color-light-blue;
        }

        &.disabled {
          background: $color-platinum;
          color: $color-warm-grey;
        }
      }
    }
  }

  .quote__instructions {
    position: absolute!important;
    z-index: 99;
    left: 50%;
    top: 20px;
    transform: translatex(-50%);
    background-color: $color-white;
    width: calc(100% - 80px)!important;
    box-shadow: inset 0px 0 0px 2px $color-blue-alt;
    border-radius: 10px;
    padding: 10px;
    outline: none;

    >p {
      margin: 0;
    }
  }

  .get-quote__content {
    text-align: center;
    background: $color-secondary;
    box-shadow: 0px 1.33px 2.67px 0px rgba(0, 0, 0, 0.16),
      0px 2.67px 5.33px 0 rgba(0, 0, 0, 0.12),
      0px 1.33px 10.67px 0px rgba(0, 0, 0, 0.1);
    margin: 4em 2em;
    border-radius: 10px;
  }

  .get-quote__blue {
    position: relative;
    background: $color-secondary;
    padding: 15px;
    border-radius: 10px 10px 0 0;

    button {
      background: transparent;
      border: none;
      color: $color-white;
      position: relative;
      font-size: 0.75rem;
      padding: 0;
      margin: 0 10px;
      &.active {
        font-weight: $fontWeight-bold;
        &:after {
          content: "";
          height: 2px;
          background-color: transparent;
          position: absolute;
          bottom: -5px;
        }
      }
    }

    .tab-button {
      font-family: $font-light;
      &.active {
        font-weight: $fontWeight-bold;
        &:after {
          content: "";
          height: 2px;
          width: 100%;
          background-color: white;
          position: absolute;
          left: 0;
          bottom: -5px;
        }
      }
    }
  }

  .get-quote__tab-containers {
    position: relative;
    padding: 20px 40px;
    background: $color-white-smoke;
    border-radius: 10px;
    min-height: 550px;

    form {
      margin: 0;
    }
  }

  .form-group--flex {
    display: flex;
  }

  .get-quote__result {
    .get-quote__title {
      font-family: $font-light;
      font-size: 0.9rem;
      margin: 0;
      color: $color-white;
      
      &--b {
        margin: 1em;
        color: $color-font;
        font-family: $font-secondary;
        font-size: 1.2rem;
      }
    }

    .results__amount {
      font-weight: 700;
      font-size: 2rem;
      color: $color-secondary;
      margin-bottom: .5em;

      &--small {
        font-weight:$fontWeight-medium;
        color: $color-font;
        font-size: 1rem;
      }

      &--white {
        color: $color-white;
      }
    }

    .results__instructions {
      color: $color-taupe-grey;
      font-size: 0.7rem;
      margin: 0 auto;
      font-weight: 700;
    }

    .range-container {
      text-align: left;

      label {
        font-size: 0.9rem;
      }


    // SLIDER A
    //   /*generated with Input range slider CSS style generator (version 20211225)
    //   https://toughengineer.github.io/demo/slider-styler*/
      input[type="range"].styled-slider {
        height: 3em;
        -webkit-appearance: none;
        background: transparent;
        width: 100%;
        border-radius: 10px;
        &:focus {
          box-shadow: none;
        }
        &:focus-visible {
          box-shadow: inset 0px 0 0px 2px #000;
          outline: none;
        }
      }

      /*progress support*/
      input[type="range"].styled-slider.slider-progress {
        --range: calc(var(--max) - var(--min));
        --ratio: calc((var(--value) - var(--min)) / var(--range));
        --sx: calc(0.5 * 1.3em + var(--ratio) * (100% - 1.3em));
      }

      /*webkit*/
      input[type="range"].styled-slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 1em;
        height: 1em;
        border-radius: 1em;
        background: $color-secondary;
        border: none;
        box-shadow: none;
        margin-top: calc(0.5em * 0.5 - 1em * 0.5);
      }

      input[type="range"].styled-slider::-webkit-slider-runnable-track {
        height: 0.5em;
        border: none;
        border-radius: 0.5em;
        background: $color-pastel-blue;
        box-shadow: none;
      }

      input[type="range"].styled-slider.slider-progress::-webkit-slider-runnable-track {
        background: linear-gradient($color-secondary, $color-secondary) 0 /
            var(--sx) 100% no-repeat,
          $color-pastel-blue;
      }

      /*mozilla*/
      input[type="range"].styled-slider::-moz-range-thumb {
        width: 1em;
        height: 1em;
        border-radius: 1em;
        background: #007cf8;
        border: none;
        box-shadow: none;
      }

      input[type="range"].styled-slider::-moz-range-track {
        height: 0.5em;
        border: none;
        border-radius: 0.5em;
        background: #c9e8fb;
        box-shadow: none;
      }

      input[type="range"].styled-slider.slider-progress::-moz-range-track {
        background: linear-gradient(#007cf8, #007cf8) 0 / var(--sx) 100%
            no-repeat,
          #c9e8fb;
      }

      /*ms*/
      input[type="range"].styled-slider::-ms-fill-upper {
        background: transparent;
        border-color: transparent;
      }

      input[type="range"].styled-slider::-ms-fill-lower {
        background: transparent;
        border-color: transparent;
      }

      input[type="range"].styled-slider::-ms-thumb {
        width: 1.3em;
        height: 1.3em;
        border-radius: 1em;
        background: #007cf8;
        border: none;
        box-shadow: none;
        margin-top: 0;
        box-sizing: border-box;
      }

      input[type="range"].styled-slider::-ms-track {
        height: 0.5em;
        border-radius: 0.5em;
        background: #c9e8fb;
        border: none;
        box-shadow: none;
        box-sizing: border-box;
      }

      input[type="range"].styled-slider.slider-progress::-ms-fill-lower {
        height: 0.5em;
        border-radius: 0.5em 0 0 0.5em;
        margin: -undefined 0 -undefined -undefined;
        background: #007cf8;
        border: none;
        border-right-width: 0;
      }

      .bubble {
        background-color: $color-white;
        display: block;
        text-align: center;
        padding: 7px;
        font-weight: $fontWeight-bold;
        border-radius: 10px;
        font-size: 1rem;
      }
    }

    &--blue {
      margin-bottom: 20px;
      h2,
      h3,
      p {
        color: $color-white;
      }

      p {
        font-size: 0.9rem;
        margin: 0;
      }

      .results__amount {
        color: $color-white;
      }
    }

    .adjust-box {
      background: $color-yellow;
      color: $color-font;
      font-size: 0.9rem;
      padding: 10px 35px 10px 15px;
      border-radius: 10px;
      font-weight: 700;
      position: absolute;
      bottom: -18px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      margin: 0;

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-top: 12px solid $color-font; //Coloured border
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translatey(-50%);
      }

      &.active {
        &::after {
          transform: translatey(-50%) rotate(180deg);
        }
      }
    }
  }

  .get-quote__result--adjust {
    margin-bottom: 2em;

    .results__instructions {
      color: $color-white;
      font-size: 0.9rem;
    }

    .range-container {
      text-align: left;
      margin-bottom: 1em;

      label {
        font-size: 0.9rem;
        color: $color-white;
      }

      // SLIDER B
      /*generated with Input range slider CSS style generator (version 20211225)
      https://toughengineer.github.io/demo/slider-styler*/
      input[type="range"].styled-slider {
        height: 3em;
        width: 100%;
        background: transparent;
        -webkit-appearance: none;
        border-radius: 10px;
        &:focus {
          box-shadow: none;
        }
        &:focus-visible {
          box-shadow: inset 0px 0 0px 2px #000;
          outline: none;
        }
      }

      /*progress support*/
      input[type="range"].styled-slider.slider-progress {
        --range: calc(var(--max) - var(--min));
        --ratio: calc((var(--value) - var(--min)) / var(--range));
        --sx: calc(0.5 * 1em + var(--ratio) * (100% - 1em));
      }

      // input[type="range"].styled-slider:focus-visible {
      //   outline: 2px solid black;
      // }

      /*webkit*/
      input[type="range"].styled-slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 1em;
        height: 1em;
        border-radius: 1em;
        background: #ffffff;
        border: none;
        box-shadow: none;
        margin-top: calc(0.5em * 0.5 - 1em * 0.5);
      }

      input[type="range"].styled-slider::-webkit-slider-runnable-track {
        height: 0.5em;
        border: none;
        border-radius: 0.5em;
        background: #c9e8fb;
        box-shadow: none;
      }

      input[type="range"].styled-slider.slider-progress::-webkit-slider-runnable-track {
        background: linear-gradient(#71cbf4, #71cbf4) 0 / var(--sx) 100%
            no-repeat,
          #c9e8fb;
      }

      /*mozilla*/
      input[type="range"].styled-slider::-moz-range-thumb {
        width: 1em;
        height: 1em;
        border-radius: 1em;
        background: #ffffff;
        border: none;
        box-shadow: none;
      }

      input[type="range"].styled-slider::-moz-range-track {
        height: 0.5em;
        border: none;
        border-radius: 0.5em;
        background: #c9e8fb;
        box-shadow: none;
      }

      input[type="range"].styled-slider.slider-progress::-moz-range-track {
        background: linear-gradient(#71cbf4, #71cbf4) 0 / var(--sx) 100%
            no-repeat,
          #c9e8fb;
      }

      /*ms*/
      input[type="range"].styled-slider::-ms-fill-upper {
        background: transparent;
        border-color: transparent;
      }

      input[type="range"].styled-slider::-ms-fill-lower {
        background: transparent;
        border-color: transparent;
      }

      input[type="range"].styled-slider::-ms-thumb {
        width: 1em;
        height: 1em;
        border-radius: 1em;
        background: #ffffff;
        border: none;
        box-shadow: none;
        margin-top: 0;
        box-sizing: border-box;
      }

      input[type="range"].styled-slider::-ms-track {
        height: 0.5em;
        border-radius: 0.5em;
        background: #c9e8fb;
        border: none;
        box-shadow: none;
        box-sizing: border-box;
      }

      input[type="range"].styled-slider.slider-progress::-ms-fill-lower {
        height: 0.5em;
        border-radius: 0.5em 0 0 0.5em;
        margin: -undefined 0 -undefined -undefined;
        background: #71cbf4;
        border: none;
        border-right-width: 0;
      }

      .bubble {
        background-color: $color-white;
        color: $color-secondary;
        display: block;
        text-align: center;
        padding: 7px;
        font-weight: $fontWeight-bold;
        border-radius: 10px;
        font-size: 1rem;
      }
    }
  }

  .quote-info__actions {

    .results__btn {
      background-color: $color-light-blue;
      color: $color-white;
      display: block;
      border-radius: 8px;
      padding: 15px;
      border: none;
      width: 100%;
      font-size: 0.875rem;
      font-weight: 700;
      cursor: pointer;

      &--dark {
        background-color: $color-secondary;
      }

      &:hover,
      &:focus {
        background-image: linear-gradient(rgba(0, 0, 0, 0.4) 0 0);
      }
    }

    p {
      margin: 5px 0;
      font-size: 0.75rem;
    }

    .quote-btn__ {
      &grey {
        background-color: $color-font;
      }
      &white {
        background-color: $color-white;
        color: $color-font;
        border: 1px solid $color-font;
      }
      &green {
        background-color: #005629;
      }
      &ltgreen {
        background-color: $color-green;
      }
      &blue {
        background-color: $color-dark-blue;
      }
      &ltblue {
        background-color: $color-blue;
      }
    }
  }

  .results__message {
    color: $color-white;
    font-size: 0.9rem;

    .get-quote__title {
      color: $color-white;
      line-height: 1.5rem;

    }
  }

  .results__checkout-nav {
    h3 {
      font-size: 1.2rem;
      font-family: $font-secondary;
      color: $color-font;
      font-weight: 700;
      margin: 1em 0;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        padding-left: 0 !important;
        margin-bottom: 1em;
        font-size: 0.9rem;

        &:before {
          content: none;
        }
      }
    }
  }

  
  @media (min-width: $bp-m) {
    .get-quote__content {
      margin: 6em 2em;
    }
  }

  @media (min-width: $bp-l) {
    .get-quote__content {
      margin-left: 60%;
    }
  }

  @media (min-width: $bp-l-max) {
    .get-quote__content {
      margin-left: 69%;
    }
  }
}



.validation {
  font-size: 0.9rem;
  font-family: $font-primary;
  flex: 1 100%;
  text-align: left;
  padding: 0 0 1em 0;
  color: rgb(226, 41, 34);
}

#recaptchaErrorMessage p {
    font-weight: 700;
}

#recaptchaErrorMessage {
    text-align: center;
}

// insurance calculator

.loan-calculator {
  color: #4f4f4f;
}

.loan-calculator-header {
  padding: 20px !important;
  margin-bottom: 0 !important;
}

.loan-calculator-table {
  display: block;
  background: white;
  padding: 20px;
}

.loan-calculator-table__cell {
  width: 100%;
}

/* head */

.loan-calculator-table__cell--head {
  margin-top: 20px;
}

.loan-calculator-table__cell-text {
  position: relative;
}

.loan-calculator-table__cell-text--flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.loan-calculator-table__cell-label-helper {
  font-size: 0.8rem;
}

.loan-calculator-table__cell-number {
  margin-right: 10px;
}

.loan-calculator-table__cell label {
  padding-right: 20px;
}

/* tooltip */
.loan-calculator-table__cell-tooltip-icon {
  height: 1.1rem;
  width: 1.1rem;
  fill: #a3a3a3;

  &:hover,
  &:focus {
    fill: $color-secondary;
  }
}

.loan-calculator-table__cell-tooltip {
  position: absolute;
  right: 0;
  background: transparent;
  border: none;

  &--education {
    position: relative;
    top: 0;
  }
}

.loan-calculator-table__cell-tooltip-text {
  visibility: hidden;
  position: absolute;
  min-width: 60%;
  background: #f5f5f5;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  z-index: 1;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 3px 3px 5px 3px rgba(0, 0, 0, 0.2);
  font-size: 0.8rem;

  &.active {
    visibility: visible;
  }
}

/* foot */

.loan-calculator-table__cell--foot {
  margin-bottom: 2em;
  position: relative;
}

.loan-calculator-table__cell-dollar {
  &:after {
    content: "$";
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    z-index: 1;
  }
}

.loan-calculator-table__cell--foot input[type="text"] {
  border: 2px solid $color-secondary;
  border-radius: 4px;
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  font-size: 1rem;
  position: relative;
}

.loan-calculator-table__cell-input--disabled {
  background: rgb(226, 226, 226) !important;
  border: 2px solid $color-primary !important;
}

.loan-calculator-table__cell-select {
  width: 100%;
  border: 2px solid $color-secondary;
  border-radius: 4px;
  background: $color-white;
  color: $color-secondary;
  padding: 12px 20px;
  margin: 8px 0;
  font-size: 1rem;
}

.loan-calculator-table__cell-children-counter {
  text-align: center;
  margin: 10px 0;
}

/* children counter */

.loan-calculator-table__add-child-button {
  text-align: right;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.children-counter__add-child {
  color: $color-blue-alt;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover,
  &focus {
    color: black;

    .children-counter__add-child-icon {
      fill: black;
    }
  }
}

.children-counter__add-child-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 10px;
  fill: $color-blue-alt;
}

/* children propagation */

.loan-calculator-table__cell-children-propagation {
  position: relative;
  background: rgb(238, 238, 238);
  padding: 10px;
  padding-bottom: 50px;
  margin-left: -20px;
  margin-right: -20px;
  fill: $color-blue-alt;

  &:nth-child(even) {
    background: #f5f5f5;
  }
}

.children-propagation__title {
  display: none;
}

.children-propagation__title-mobile {
  font-weight: 700;
  margin-left: 20px;
  margin-top: 10px;
}

.children-propagation__question {
  padding: 5px 0;
}

.child-propagation__question-container {
  display: flex;
  align-items: baseline;
}

.children-propagation__question-label {
  margin-right: 10px;
  margin-left: 20px;
  width: calc(50% - 25px);
}

.children-propagation__question-input-container {
  position: relative;
  width: 50%;
  width: calc(50% - 25px);
}

.children-propagation__question-input[type="text"] {
  border: 2px solid $color-secondary;
  border-radius: 4px;
  padding: 12px 20px;
  margin: 8px 0;
  width: 100%;
  box-sizing: border-box;
  font-size: 1rem;
  position: relative;
}

.children-propagation__add {
  background: transparent;
  border: none;
  position: absolute;
  bottom: 10;
  right: 60px;
  cursor: pointer;

  &:hover,
  &:focus {
    .children-propagation__add-icon {
      fill: black;
    }
  }

  & svg {
    pointer-events: none;
  }
}

.children-propagation__delete {
  background: transparent;
  border: none;
  position: absolute;
  bottom: 10;
  right: 10px;
  cursor: pointer;

  &:hover,
  &:focus {
    .children-propagation__delete-icon {
      fill: black;
    }
  }
}

.children-propagation__delete-icon {
  height: 2rem;
  width: 2rem;
  fill: $color-secondary;
  pointer-events: none;
}

.loan-calculator-table__error {
  position: absolute;
  color: #ee0000;
  right: 20px;
  pointer-events: none;
  top: 50%;
  transform: translateY(-50%);
}

.loan-calculator-table__footer {
  text-align: center;
  width: 100%;
}

.loan-calculator-button {
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  background: transparent;
  padding: 15px 20px;
  font-weight: 700;
  cursor: pointer;
}

.loan-calculator-table__submit {
  margin-bottom: 20px;
  background: $color-green;
  color: white;

  &:hover,
  &:focus {
    background: $color-dark-green;
  }
}

.loan-calculator-table__submit--hidden {
  display: none;
}

.loan-calculator-result {
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 20px;
}

.loan-calculator-table__cta {
  background: $color-secondary;
  color: white;
  margin-top: 20px;

  &:hover,
  &:focus {
    background: $color-primary;
  }
}

.loan-calculator-table__cta--hidden {
  display: none;
}

.loan-calculator-table__error--hidden {
  display: none;
}

@media screen and (min-width: 767px) {
  .loan-calculator-header {
    max-width: 70em !important;
    padding: 0 calc(0.938em + 20px) !important;
    margin: 0 auto !important;
  }

  .loan-calculator {
    max-width: 70em;
    padding: 0 0.938em;
    margin: 0 auto;
  }

  .loan-calculator-table {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 3em 0;
    padding: 20px;
    margin: 0 auto;
  }

  .loan-calculator-table__cell label {
    padding-right: 0;
  }

  .loan-calculator-table__cell--head {
    width: calc(60% - 25px);
    display: flex;
    align-items: center;
    margin: 25px 0;
  }

  .loan-calculator-table__cell--children {
    width: 100%;
  }

  .loan-calculator-table__cell-tooltip {
    right: -33px;
    top: -3px;

    &--education {
      left: 0;
      top: 0;
      transform: none;
    }
  }

  .loan-calculator-table__cell-text-left {
    position: relative;
  }

  .loan-calculator-table__cell-tooltip-text {
    min-width: 250px;
    bottom: auto;
    top: 50%;
    left: calc(100% + 60px);
    transform: translateY(-50%);

    &--education {
      left: calc(100% + 30px);
      right: auto;
    }
  }

  .loan-calculator-table__cell--foot {
    margin-bottom: 0;
    width: calc(40% - 25px);
    margin-left: 50px;
    display: flex;
    align-items: center;
  }

  /* child counter */
  .loan-calculator-table__cell--head-children {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 30px;
  }

  .loan-calculator-table__cell--foot-children {
    width: 100%;
  }

  .children-propagation__question-input[type="text"] {
    width: 100%;
  }

  .children-propagation__question {
    width: 75%;
    margin: 0 auto;
  }

  .children-propagation__title-mobile {
    display: none;
  }

  .children-propagation__title {
    font-weight: 700;
    transform: translateX(51%);
    display: block;
  }

  .loan-calculator-table__submit {
    margin-top: 20px;
  }
}
