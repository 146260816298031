/* ================================================================
   Documents
   ================================================================ */

@font-face {
	font-family: "sf-icon-font";
	src: url("../fonts/sf-icon-font.eot?31b9ae05d6feaf588a80ace92ddbffe1");
	src: url("../fonts/sf-icon-font.eot?#iefix") format("embedded-opentype"), url("../fonts/sf-icon-font.woff?31b9ae05d6feaf588a80ace92ddbffe1") format("woff"), url("../fonts/sf-icon-font.ttf?31b9ae05d6feaf588a80ace92ddbffe1") format("truetype"), url("../fonts/sf-icon-font.svg?31b9ae05d6feaf588a80ace92ddbffe1#sf-icon-font") format("svg");
	font-weight: normal;
	font-style: normal;
}

[class="icon- -single"],
[class*=" icon-"]:before,
[class*=" icon-"]:after,
[class^="icon-"]:before,
[class^="icon-"]:after {
	font-family: "sf-icon-font";
	vertical-align: middle;
	font-weight: normal;
	font-style: normal;
	font-size: 26px;
	speak: none;
	text-decoration: inherit;
	text-transform: none;
	text-rendering: geometricPrecision;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-file:before {
	content: "\f001";
}

.icon-txt {
	position: relative;
	display: inline-block;
	line-height: normal;

	&.icon-file {
		color: #555555;
	}

	[class^="icon-txt-"] {
		position: absolute;
		top: 47%;
		left:0;
		padding: 0.2em 0.4em;
		font-size: 10px;
		line-height: normal;
		height: auto;
		font-style: normal;
		font-weight: bold;
		text-transform: uppercase;
		background-color: #333333;
		color: #fff;
	}

	.icon-txt-xlsx {
	  	background-color: #38ab63;
	}

	.icon-txt-doc,
	.icon-txt-docx {
		background-color: #1a8cff;
	}

	.icon-txt-ppt,
	.icon-txt-pptx {
	  	background-color: #ed8527;
	}

	.icon-txt-pdf, .icon-txt-swf  {
	  	background-color: #ff4848;
	}

	.icon-txt-zip {
	  	background-color: #596c9b;
	}
}

.documents {
	&.listing {
		.aside {
			margin:0 0 10px;
			text-align:left;
		}
	}

	&.detail {


	}

	svg { max-width:100%; max-height:50%; }

	.aside { width:60px; text-align:center; }
}

.document-link {
	a {
		margin-left: 10px;
		font-size: 18px;
		color: $color-base;
	}

	svg {
		width: 2em;
		height: 2em;
	}
}

.documents-slider__item.card {
	margin: 0;

	.title {
		color: $color-primary;
	}
}

.documents-slider__list {
	.slick-prev,
	.slick-next {
		&:hover,
		&:focus{
			border-color: $color-red;
		}
	}

	.slick-prev {
		left: 0;

		@media (min-width: $bp-xl) {
			left: -25px;
		}
	}

	.slick-next {
		right: 0;
		@media (min-width: $bp-xl) {
			right: -25px;
		}
	}

}

.documents-slider__list .slick-track {
	display: flex;
	flex-flow: row;
	padding: 2em 0;

	.slick-slide {
		float: none;
		height: auto;
		padding: 0 1em;

		> div, .documents-slider__item {
			height: 100%;
		}
	}
	
}