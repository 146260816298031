/* ================================================================
   Button Styles
   ================================================================ */

.button, .btn {
	display:inline-block;
	padding:0.47em 1.2em;
	border:0;
	font-family:$font-primary; font-weight:$fontWeight-bold; color:$color-invert;
	text-decoration:none; text-align:center;
	background:$color-primary;
	transition:all 0.2s ease-out;

	&:hover, &:focus {
		text-decoration:none;
		background:$color-darkest-blue;
	}

	&.btn-green {
		background: $color-green;
		color: $color-invert;
		border: none;

		
		&:hover, &:focus {
			background: $color-dark-green;
		}
	}

	&.btn-light-blue {
		background: $color-blue-alt;
		color: $color-invert;
		border-radius: 0;
		padding: .8em 1.2em;
	}
}

.yellow-badge {
  background: $color-yellow;
  border-radius: 3px;
  font-size: 0.875rem;
  font-weight: $fontWeight-bold;
  color: $color-black;
  margin: 0 0.5em;
  padding: 0.3em 0.5em;
}

.back {
	@extend .button-small !optional;
	text-transform: capitalize;

	&:before {
		@extend .iconfont-chevron-left !optional;
	}
}

.more {
	@extend .button-small !optional;
	text-transform:capitalize;

	&:after {
		@extend .iconfont-chevron-right !optional;
	}
}

.btn-dropdown {
	display: inline-block;
	position: relative;
	border: none;
	padding: 0;
	text-align: center;
	background: none;
	cursor: pointer;

	&.active {
		background: $color-invert;
	}

	svg {
		width: 1.5em;
		height: 2.3em;
		margin-right: .25em;
		margin-left: .5em;
	}

	span {
		font-size: .875rem;
		margin-right: 1.75rem;
		margin-top: 2px;
	}

	&:after {
		content: "";
		display: block;
    width: 8px;
    height: 8px;
    border-top: 2px solid $color-base;
    border-right: 2px solid $color-base;
    position: absolute;
    right: 10px;
    top: 30%;
    transform: rotate(135deg) translateY(-30%);
	}
}

.loading-btn {
	position: relative;

	&.active-loading{
		&:before {
			content: '';
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			margin: auto;
			border: 3px solid #fff;
			border-right-color:transparent;
			border-left-color: transparent;
			border-radius: 50%;
			width: 2rem;
			height: 2rem;
			-webkit-animation:spin 2s linear infinite;
			-moz-animation:spin 2s linear infinite;
			animation:spin 2s linear infinite;
		}
		&:after {
			content: '';
			background-color: rgba(255, 255, 255, 0.3);
			height: 100%;
			width: 100%;
			position: absolute;
			left: 0;
			top: 0;
		
		}
	}
}