/* ==========================================================================
PHOTO GALLERY

- Image Gallery
- Overlay Gallery
- Gallery with Thumbnail 
- List Image
========================================================================== */

.photo-gallery, .photo-gallery-thumbs {
  	margin:0 auto 1.5em; 
  	padding:0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

  	.photo-gallery-item {
		position: relative;
		min-height: 220px;

		img {
	  		display: block;
	  		margin: 0 auto;
	  		width: 100%;
			height: 100%;
			transition: all ease 1s;
		}

		> a {
		  	position: relative;
	  		display: block;
			height: 100%;
			overflow: hidden;
		}

		@media(min-width: $bp-m) {
			width: 50%;
		}

		@media(min-width: $bp-mw) {
			width: 33.33333%;
		}

		&:hover {
			img {
				transform: scale(1.125);
			}
		}

  	}

  	.slick-next, .slick-prev {
	    height: 25px;
    	width: 15px;
	    z-index: 50;
	    top: 40%;
	    margin: 0;

	    &:before { 
	        content: '';
	        height: 100%;
	   		width: 100%;
	        background-size: 100%;
	        display: block;
	        opacity: 1;
	        background-repeat:no-repeat;
	        background-position:center center;
	    }

	    &:focus, &:hover {
	    }

	    @media (min-width:$bp-l) {  
  			height: 40px;
    		width: 25px;
    		top:40%;
		}
  	}

	.slick-prev {
	    left:-15px;

	    &:before {
	      	background-image:url(/ResourcePackages/Talon/assets/dist/images/arrow.png);
	    	transform: rotate(180deg);
	    }

	    @media (min-width:$bp-l) {  
	    	left: -25px;
	    }	
	}

	.slick-next {
	    right: -15px;

	    &:before { 
	      	background-image:url(/ResourcePackages/Talon/assets/dist/images/arrow.png);
	    }
	    @media (min-width:$bp-l) {  
	    	right:  -25px;
	    }	
	}
}

.photo-gallery-thumbs { 
	.photo-gallery-item {
		padding:0;
	}
}
.photo-thumbs {
	margin:0 auto 1.5em; 
  	padding:0;

  	.photo-gallery-item {
		height: 100px;
	    background-size: cover;
	    background-position: center center;
	    background-repeat: no-repeat;
		margin:0 10px;

		@media (min-width:$bp-m) {  
	    	height: 150px;
	    }	
	    @media (min-width:$bp-m) {  
	    	height: 150px;
	    }	

		span {
			@include x-rem(font-size, 0px);
		} 
		
  	}

  	.slick-next, .slick-prev {
	    height: 25px;
    	width: 15px;
	    z-index: 50;
	    top: 40%;
	    margin: 0;

	    &:before { 
	        content: '';
	        height: 100%;
	   		width: 100%;
	        background-size: 100%;
	        display: block;
	        opacity: 1;
	        background-repeat:no-repeat;
	        background-position:center center;
	    }
 
	    &:focus, &:hover {
	    }

	    @media (min-width:$bp-l) {  
  			height: 40px;
    		width: 25px;
    		top:40%;
		}
  	}
	.slick-prev {
	    left:-15px;

	    &:before {
	      	background-image:url(/ResourcePackages/Talon/assets/dist/images/arrow.png);
	    	transform: rotate(180deg);
	    }

	    @media (min-width:$bp-l) {  
	    	left: -25px;
	    }	
	}

	.slick-next {
	    right: -15px;

	    &:before { 
	      	background-image:url(/ResourcePackages/Talon/assets/dist/images/arrow.png);
	    }
	    @media (min-width:$bp-l) {  
	    	right:  -25px;
	    }	
	}
}

.close-box {
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	background: rgba(0,0,0,0.7);
	width: 60px;
	height: 60px;

	&:before {
		content: "";
		display: block;
		width: 2px;
		height: 30px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(45deg);
		background: #fff;
	}

	&:after {
		content: "";
		display: block;
		width: 2px;
		height: 30px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(-45deg);
		background: #fff;
	}
}