/* ******************************************************
   NAVIGATION
        - ClickMenu
        * MEGA MENU NAVIGATION ADJUSTMENTS
   ****************************************************** */


// 1139
@media (max-width: $bp-mw-max) {

	.mega-menu .cm-menu {
		flex-direction: column;
		position: absolute;
		z-index: 10;
		left: 0;
		right: 0;
		top: 59px;
		background: #fff;
		margin: 0 -.938em;
		border-bottom: 1px solid #dcdcdc;
		border-top: 1px solid #dcdcdc;
	}
}

@media (min-width:$bp-l) {
	[data-type="mega"] {
		&:last-child > ul {
			left: auto;
			right: 0;
		}

		> ul {
			position: absolute;
			top: 100%;
			left: 0;
			width: 300px;
			max-height: 0;
			background: $nav-subLinkBackground;
			overflow: hidden;

			ul {
				position: static;
				max-height: 80em;
				width: 100%;
				overflow: hidden;
				background: transparent;
				opacity: 1;
			}

			.opened ul {
				margin-left: 0;
			}

			li {
				max-width: 60em;
				margin: 0 auto !important;

				a {
					display: block;
				}
			}


			> .link-landing {
				~ li:nth-child(4n+1) {
					clear: none;
				}

				~ li:nth-child(4n+2) {
					clear: left;
				}
			}
		}
		/* ================= OPENED FUNCTIONALITY ADDED ON SETUP ================= */
		a + .has-sub {
			width: 0;
			min-height: 0;
			height: 100%;

			> span {
				min-height: 0;
				height: 100%;
			}
		}

		ul .has-sub:after {
			display: none;
		}

		&.opened > ul {
			padding: 20px;
			box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .2);
		}
		/* ================= SEPARATE EXPANDERS OPTION IS ENABLED ================= */
		ul a + .has-sub {
			display: none;
		}

		.has-sub:after,
		.expander-wrap .has-sub > span:after {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-top: 2px solid $color-base;
      border-right: 2px solid $color-base;
      border-left: 0;
      border-bottom: 0;
      position: relative;
      right: auto;
      margin: -5px 0 0 10px;
      transform: rotate(135deg);
		}

		&.opened > .has-sub:after,
		&.opened > .expander-wrap .has-sub > span:after {
			display: block;
			border-top-color: $color-secondary;
			transform: scale(2) rotate(-180deg) !important;
		}
	}
}

.nav-bar.mega-menu {
	width: 100%;

	.container {
		padding: 0;
	}
}

.close-menu {
	display: none;
	position: absolute;
	top: 8%;
	right: 25%;
	border: none;
	background: transparent;
	font-family: $font-primary;
	@include x-rem("font-size", 24px);
	font-weight: $fontWeight-light;
	cursor: pointer;

	&:after {
		content: "\2715";
		position: relative;
		display: inline-block;
		margin-left: 0.5em;
		color: $color-base;
	}
}

.mega-menu {
}

.mega-menu__info {
	background: $color-white;

	&.focusable:focus {
		width: 75%;
	}
}

.mega-menu__menu {
	> li {
		> a.mega-menu__link {
			font-family: $font-light;
		}
	}
}

.mega-menu__item {
}

.mega-menu__item--root {
	> .mega-menu__link {
		font-weight: 300;
		color: $color-secondary;
	}
}

.mega-menu__link {
}

.navigation-image {
	display: none;
	position: absolute;
	right: 30%;
	bottom: -5px;
}

@media(min-width: $bp-l) {
	.close-menu {
		display: block;
	}

	.navigation-image {
		display: block;
	}

	.nav-bar.mega-menu {
		position: unset;

		.cm-menu {
			position: relative;
			z-index: 2;

			ul li a {
				background: transparent;
				padding: 6px 0;
				border-bottom: 1px solid $color-primary;
				color: $color-dark-grey;

				&:focus,
				&:hover {
					border-left: 0;
					color: $color-secondary;
				}
			}

			ul li.link-landing {
				a {
					color: $color-secondary;
				}
			}
		}
	}

	.mega-menu__menu {
		> li {
			display: inline-block;
			margin: 0 16px;

			&:hover,
			&:focus,
			&.active {
				> a.mega-menu__link {
					color: $color-secondary;
					border-bottom: 4px solid $color-secondary;
				}
			}

			> a.mega-menu__link {
				border-bottom: 4px solid transparent;
				padding: 1em 0 0;
			
				&.has-sub {
					pointer-events: none;
				}

				&:hover,
				&:focus {
					border-bottom: 4px solid $color-secondary;
				}
			}
		}
	}

	.mega-menu__item--root {
		&.opened {
			> .mega-menu__link {
				&:after {
					display: none;
					border-bottom: 4px solid $color-secondary;
				}
			}
		}
	}

	.mega-menu__item--root {
		&:hover {
			> .mega-menu__sub-menu {
				display: block;
				max-height: none;
				opacity: 1;
				padding: 20px;
				box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .2);
			}
		}

		> .mega-menu__link {
			&:focus {
				& ~ .mega-menu__sub-menu {
					display: block;
					max-height: none;
					opacity: 1;
					padding: 20px;
					box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .2);
				}
			}
		}
	}
}

@media (min-width: $bp-xl) {
	[data-type="mega"] {
		&:last-child > ul {
			left: 0;
			right: auto;
		}
	}
}
