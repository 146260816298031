/* ================================================================
  LGRA Landing Styles
   ================================================================ */

.lgra-landing {

    $color-green: #028844;
    $color-blue-dark: #025774;
    $color-blue: #0094df;

    .site-header {
        min-height: 80px;
        @media (min-width: 48em) {
            border-bottom: 6px solid $color-blue;
        }
        .logo {
            margin: .5rem auto;
            position: static;
            display: block;
        }
        .account-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
            width: 40px;
            height: 40px;
            fill: $color-light-blue;
        }
        .account-text, .account-link {
            display: none;
        }
        .header-bottom {
            width: 100%;
            max-width: 80em;
            margin: 0 auto;
            position: relative;
            padding: 0 $grid-gutter-em;
            .row {
                width: 100%;
            }
        }
    }



    .home-rotator h2 {
        line-height: 36px;
    }

    h2 {
        color: $color-green;
    }

    h3 {
        color: $color-green;
    }

    .btn,
    .button {
        color: $color-blue;
        border-color: $color-blue;

        &.btn-green {
            color: $color-invert;
            border-color: $color-green;
            background: $color-green;
        }
    }

    .accept-btn:after {
        display: none;
    }

    .site-main {
        margin-top: 107px;
    }
    .sign-in-block {}

    .sign-in-block a,
    .sign-in-block svg {
        color: $color-blue;
        fill: $color-blue;
    }

    .home-rotator .txt-container {
        padding: 40px 60px 35px 22px !important;
    }

    @media (min-width: 48em) {
        .site-header {
            .logo {
                transform: none;
            }
        }
        .site-main {
            margin-top: 122px;
        }
    }

    @media(min-width: 60em) {

        .site-header {
            .header-bottom {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
            }
            .account-login {
                display: flex;
                align-items: center;
            }
            .account-text, .account-link {
                display: block;
                margin: 0;
            }
            .account-icon {
                position: static;
                top: auto;
                transform: none;
                right: auto;
                margin: 0 .5em 0 0;
            }
            .logo {
                margin: 1.5em 0;
            }
        }

        .sign-in-block {
            display: inline-block;
            margin-top: 3em;
        }

        .home-rotator .txt-container {
            width: 500px !important;
            min-height: 210px;
        }
    }

    @media(min-width: 90em) {
        .site-header {
        }
        .sign-in-block {
            padding-left: 3em;
        }
    }

    .sign-in-block svg {
        float: left;
        margin-right: 0.5em;
        height: 40px;
        width: 40px;
    }

    .site-header.sticky .sign-in-block {
        margin-top: 1em;
    }

    .site-footer {
        margin-top: 2em;
    }

    .number-callouts {
        text-align: center;
    }

    .number-callouts img {
        width: 150px;
        height: 150px;
    }

    .callout-item {
        width: 100px;
        font-size: 42px;
        font-weight: 700;
        height: 100px;
        border: 5px solid $color-blue;
        border-radius: 50%;
        display: inline-block;
        background: white;
        margin: 0 0 0.3em;
        line-height: 90px;
        color: $color-blue;

    }

    @media(min-width: 60em) {
        .number-callouts {
            border-top: 5px solid $color-blue;
            margin-top: 5em;
        }

        .number-callouts .sf_colsIn {
            margin-top: -3.5em;
        }

        .callout-item {
            width: 152px;
            height: 152px;

            line-height: 145px;
        }


    }

    .sfPageEditor .number-callouts .sf_colsIn {
        margin-top: 0;
    }

    .caption {
        margin-top: 2em;
    }
}

.grey-container {
    background: #f2f2f2;
    padding: 2em 0;
    margin: 2em 0;
}