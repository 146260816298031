.slider-nav__menu {
    background: $color-white-smoke;
    margin: 0 0 2em 0;
    padding: 2em 0;
    list-style: none;
    text-align: center;

    .slick-next,
    .slick-prev {
        height: 15px;
        width: 15px;
    }

    .slick-next {
        right: 5%;
    }

    .slick-prev {
        left: 5%;
    }
}

.slider-nav__item {
    display: inline-block;
    margin: 0 5px;
    padding-left: 0;

    &:before {
        display: none;
    }

    &.active {
        .slider-nav__link {
            color: $color-dark-grey;
        }
    }
}

.slider-nav__link {
    font-weight: 800;
    color: $color-taupe-grey;
    text-decoration: none;
    text-transform: uppercase;
}