/* ******************************************************
   NAVIGATION
        - Footer Navigation
   ****************************************************** */
   .footer-nav {
       border-top: 3px solid $color-secondary;
    .cm-menu {
        padding-top: 2em;
        
        ul {
            top: 0;
            overflow: visible;
            padding: 1em;
        }

        .menu-heading {
            color: $color-dark-grey;
            background: $color-white-smoke;
            border-bottom: 1px solid $color-warm-grey;
            font-size: 20px;
            font-weight: 300;
        }

        .has-sub:after {
            display: inline-block;
            content: "+";
            right: 20px;
            border: none;
            color: $color-secondary;
            transform: translateY(-50%);
        }

        .opened .has-sub:after {
            content: "-";
         
        }

        li {
            text-align: left;
            padding: 0;
            a {
                display: block;
                color: $color-dark-grey;
                text-align: center;
            }
            ul li a {
                font-size: 16px;
                text-transform: none;
                padding: 0.5em 0;
                &:hover,&:focus {
                    text-decoration: underline;
                }
            }
        }
    }
}

@media (min-width:$bp-mw){
    .footer-nav {
        display: block;
        width: 100%;
        .cm-menu { 
            display:flex; 
            list-style:none;
            width:100%; 

            .menu-heading {
                background: transparent;
                border: none;
                font-size: 18px;
                font-weight: 700;
            }

            
            > li { 
                width: 13%;
                margin: 0 1em;
                a {
                    padding: 0;
                    text-align: left;
                }

                ul {
                    display: block;
                    position: relative;
                    list-style:none; 
                    margin: 1em 0 0 0; padding: 0;
                    max-height: 100%;
                    opacity: 1;

                    li a {
                        text-align: left;
                        font-size: 14px;
                    }
                }

                a.has-sub:after {
                    display: none;
                }
            }
        }
    }
}

/** New Footer Styles **/
.footer-nav-menu {
    border-top: 3px solid $color-secondary;
}

.footer-nav__title {
    display: none;
}

.footer-nav__title--toggle {
    display: block;
    width: 100%;
    text-align: center;
    border: none;
    border-bottom: 1px solid $color-warm-grey;
    color: $color-dark-grey;
    background: $color-white-smoke;
    padding: 1em;
    font-size: 20px;
    position: relative;

    &:after {
        content: '+';
        display: block;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: $color-secondary;
    }

    &.active {
        &:after {
            content: '-';
            transform: translateY(-50%) rotate(0);
        }
    }
}

.footer-nav__menu {
    display: none;
    margin: 0;
    padding: 1em;
    text-align: center;

    &.active {
        display: block;
    }
}

.footer-nav__item {
    margin: 0;
    display: block;
}

.footer-nav__link {
    display: block;
    padding: .5em 0;
    font-size: 1rem;
    color: $color-dark-grey;
    font-weight: 500 !important;
}

@media(min-width: $bp-mw) {
    .footer-nav-menu {
        padding-top: 2em;
    }

    .footer-nav__container {
        display: flex;
    }

    .footer-nav__col {
        width: 13%;
        margin: 0 1em;
    }

    .footer-nav__title {
        display: block;
        font-size: 18px;
        font-weight: 700;
    }

    .footer-nav__title--toggle {
        display: none;
    }

    .footer-nav__menu {
        display: block;
        padding: 1em 0;
    }

    .footer-nav__link {
        font-size: 14px;
        text-align: left;
    }
}