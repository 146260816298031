/* ==========================================================================
  COLOR BOX - Overlay Plugin
========================================================================== */

/* 
  General Styles
*/


/* 
  Overlay
*/
#cboxOverlay{
  top:0; 
  left:0; 
  z-index:9999; 
  overflow:initial; 
  -webkit-transform: translate3d(0,0,0);
  position:fixed; 
  width:100%; 
  height:100%;
  background:rgba(0, 0, 0, 0.9); 
}

/* 
  Box Container
*/
#colorbox {
  position:absolute; 
  top:0; 
  left:0; 
  z-index:9999; 
  overflow:initial; 
  -webkit-transform: translate3d(0,0,0);
  box-sizing:content-box; 
  -moz-box-sizing:content-box; 
  -webkit-box-sizing:content-box;
  outline:0;
  max-width: 100%;


  #cboxWrapper{
    position:absolute; 
    top:0; 
    left:0; 
    z-index:9999; 
    overflow:initial; 
    -webkit-transform: translate3d(0,0,0);
    max-width:100%;
  }

  #cboxMiddleLeft, #cboxBottomLeft{
    clear:left;
  }

  #cboxContent{
    position:relative;
    box-sizing:content-box; 
    -moz-box-sizing:content-box; 
    -webkit-box-sizing:content-box;

    max-width:100%;
    background:$color-invert;

    #cboxLoadedContent{
      overflow:auto; 
      -webkit-overflow-scrolling: touch;
      box-sizing:content-box; 
      -moz-box-sizing:content-box; 
      -webkit-box-sizing:content-box;
      max-width: 100%;
      padding-bottom:38px;

      .cboxPhoto{
        float:left; 
        margin:auto; 
        border:0; 
        display:block; 
        max-width:100%; 
        -ms-interpolation-mode:bicubic;
      }
    }
    
    #cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {
      border:0; 
      padding:0; 
      margin:0; 
      overflow:visible; 
      width:auto; 
      background:none;
      cursor:pointer;

      &:active {
        outline:0;
      }
    }

    #cboxPrevious{
      top: 50%;
      margin-top: -32px;
      background-image: url(/ResourcePackages/Talon/assets/dist/images/arrow.png);
      width: 50px;
      height: 56px;
      position: absolute;
      background-color:rgba(85, 85, 85, 0.8);
      @include x-rem(font-size, 0px);
      background-size: 7px;
      background-repeat: no-repeat;
      background-position: center center;
      left: 0; 
      transition: all .5s ease-in; 
      
      &:hover{
     
      }

      &:before {
        content: '';
          display: block;
          width: 20px;
          height: 20px;
          border-bottom: 2px solid #fff;
          border-left: 2px solid #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
      }
    }

    #cboxNext{
      right:0; 
      top: 50%;
      margin-top: -32px;
      background-image: url(/ResourcePackages/Talon/assets/dist/images/arrow.png);
      width: 50px;
      height: 56px;
      position: absolute;
      background-color: rgba(85, 85, 85, 0.8);
      @include x-rem(font-size, 0px);
      background-size: 7px;
      background-repeat: no-repeat;
      background-position: center center;
      transition: all .2s ease-in; 
    
      &:hover{
     
      }

      &:before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-bottom: 2px solid #fff;
        border-right: 2px solid #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    #cboxSlideshow{
      position:absolute; 
      top:-20px; 
      right:90px; 
      color:#fff;
    }

    #cboxClose{
      position: absolute;
      top:-25px;
      right: -25px;
      display: block;
      background-image: url(/ResourcePackages/Talon/assets/dist/images/cancel.png);
      width: 25px;
      height: 25px;
      background-size: 10px;
      text-indent: -9999px;
      background-position: center center;
      background-repeat: no-repeat;
      background-color:rgba($color-base, 0.75);
      border-radius: 50%;  
    }

    #cboxLoadingOverlay{
      position:absolute; 
      top:0; 
      left:0; 
      width:100%; 
      height:100%;
    }

    #cboxLoadingGraphic {
      position:absolute; 
      top:0; 
      left:0; 
      width:100%; 
      height:100%;
      background:url(/ResourcePackages/Talon/assets/dist/images/loading.gif) no-repeat center center;
    }
  }
}

.cboxIframe{
  width:100%; 
  height:100%; 
  display:block; 
  border:0; 
  padding:0; 
  margin:0;
  background:$color-invert;

}
#cboxError{
  padding:50px;
  border:1px solid #ccc;
}

/* 
    User Style
*/


#cboxContent{

  #cboxCurrent{
    position:absolute; 
    bottom:0; 
    right:0px; 
    color:$color-base;
    @include x-rem(font-size, 13px);
    text-transform: capitalize;
    padding:10px 15px;
  } 

  #cboxTitle{
    position:absolute; 
    bottom:0; 
    left:0; 
    color:$color-base;
    @include x-rem(font-size, 13px);
    padding: 10px 15px;
    margin:0;
  }
}







