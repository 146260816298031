/* ================================================================
   Page Banners
	 ================================================================ */
	 
.page__banner {
	border-bottom: 3px solid $color-secondary;
	text-align: center;
	.container {	
		padding: 3em 0;	
		.txt-container {
			margin: 2em 0;		
		}
	}

	&[data-color="9"] {
		border-bottom: 3px solid $color-green;

		.title {
			color: $color-green;
		}

		.secondary__link--button {
			background-color: $color-green;
		}
	}

	&[data-color="10"] {
		border-bottom: 3px solid $color-dark-grey;
		
		.title {
			color: $color-dark-grey;
		}

		.secondary__link--button {
			color: $color-dark-grey;
			background-color: $color-white;
			border: 1px solid $color-dark-grey;

			&:after {
				border-top: 2px solid $color-dark-grey;
				border-right: 2px solid $color-dark-grey;
			}
		}
	}
}

@media(min-width: $bp-m) {
	.page__banner {	
		text-align: left;

		.txt-container {
			max-width: 500px;
		}
	}
}

.secondary__link--button {
	padding: 0.47em 1.2em;
	margin: 1.2rem 0 0;
	color: $color-invert;
	background-color: $color-primary;
	&:after {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 0 0 0 .5rem;
        border-top: 2px solid $color-invert;
        border-right: 2px solid $color-invert;
        transform: rotate(45deg);
        transition: .2s all linear;
    }
    &:hover,&:focus {
        text-decoration: none;

        &:after {
            margin: 0 0 0 1rem;
        }
    }
}


/* ================================================================
   Hero Banners
	 ================================================================ */

.hero__banner {
	position: relative;

	> .container {
		position: relative;
		height: 100%;
	}
}

.hero__img {
	width: 100%; 
	height: 150px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.hero__content {
	background: $color-invert;
	padding: 1.5em 0;

	h1 {
        margin: 0 0 .2rem;
	}

	.title--h1-styling {
		margin: 0 0 .2rem;
		font-family: $font-heading;
		@include x-rem(font-size, 32px);
		font-weight: $fontWeight-bold; 
		color: $color-secondary; 

		@media (min-width: $bp-mw) {
			@include x-rem(font-size, 40px);
		}
	}

	.text {
		margin-top: 0.5em;
		p {
		margin-bottom: 0.5em;
		}
	}
}

/* Color Themes */
.hero__content[data-color="1"] {
	.hero__link--button {
		color: $color-invert;
		background-color: $color-primary;
		&::after {
			border-color: $color-invert;
		}
	}
}

.hero__content[data-color="2"] {
	.title , a {
        color: $color-base;
	}
	.hero__link--button {
		color: $color-invert;
		background-color: $color-primary;
		&::after {
			border-color: $color-invert;
		}
	}
	color: $color-base;
	background-color: transparent;
	a::after {
		border-color: $color-base;
	}
}

.hero__content[data-color="3"] {
	.title , a {
		color: $color-invert;
	}
	.hero__link--button {
		color: $color-green;
		background-color: $color-invert;
		&::after {
			border-color: $color-green;
		}
	}
	color: $color-invert;
	background-color: $color-green;
	a::after {
		border-color: $color-invert;
	}
}

.hero__content[data-color="4"] {
	.title , a {
		color: $color-invert;
	}
	.hero__link--button {
		color: $color-blue;
		background-color: $color-invert;
		&::after {
			border-color: $color-blue;
		}
	}
	color: $color-invert;
	background-color: $color-blue;
	a::after {
		border-color: $color-invert;
	}
}

.hero__content[data-color="5"] {
	.title , a {
		color: $color-dark-grey;
	}
	.hero__link--button {
		color: $color-dark-grey;
		background-color: $color-invert;
		&::after {
			border-color: $color-dark-grey;
		}
	}
	color: $color-dark-grey;
	background-color: $color-yellow;
	a::after {
		border-color: $color-dark-grey;
	}
}

.hero__content[data-color="6"] {
	.title , a {
		color: $color-green;
	}
	.hero__link--button {
		color: $color-invert;
		background-color: $color-green;
		&::after {
			border-color: $color-invert;
		}
	}
	.text {
		color: $color-base;
	}
	color: $color-green;
	background-color: $color-invert;
	a::after {
		border-color: $color-green;
	}
}

.hero__content[data-color="7"] {
	.title , a {
		color: $color-invert;
	}
	.hero__link--button {
		color: $color-red;
		background-color: $color-invert;
		&::after {
			border-color: $color-red;
		}
	}
	color: $color-invert;
	background-color: $color-red;
	a::after {
		border-color: $color-invert;
	}
}

.hero__content[data-color="8"] {
	.title , a {
		color: $color-dark-grey;
	}
	.hero__link--button {
		color: $color-invert;
		background-color: $color-dark-grey;
		&::after {
			border-color: $color-invert;
		}
	}
	color: $color-dark-grey;
	background-color: $color-invert;
	a::after {
		border-color: $color-dark-grey;
	}
}



.hero__link {
    display: inline-block;
    margin: .6rem 0 0;
    font-weight: 700;
    &:after {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 0 0 0 .5rem;
        border-top: 2px solid $color-primary;
        border-right: 2px solid $color-primary;
        transform: rotate(45deg);
        transition: .2s all linear;
    }
    &:hover,&:focus {
        &:after {
            margin: 0 0 0 1rem;
        }
    }
}

.hero__link--button {
	padding: 0.47em 1.2em;
	margin: 1.2rem 0 0;
	&:hover,&:focus {
        text-decoration: none;
    }
}

@media(min-width: $bp-m) {
	.hero__img {
		height: 242px;
	}

	
}

@media(min-width: $bp-l) {
	.hero__banner {
		height: 440px;
	}

	.hero__img {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
	}

	.hero__content {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		padding: 1.5em;

		&[data-width="1"] {
			max-width: 430px;
		}

		&[data-width="2"] {
			max-width: 560px;
		}

		&[data-width="3"] {
			max-width: 730px;
		}
    }
    /* Color Themes */
    .hero__content[data-color="2"] {
        .title , a {
            color: $color-invert;
        }
        color: $color-invert;
		a::after {
			border-color: $color-invert;
		}
    }
}

/* ================================================================
   Simple Banner
	 ================================================================ */
.simple__banner {
	position: relative;
	.img-container {
		width: 100%;
		background-size: cover;
		height: 150px;
		background-repeat: no-repeat;
		background-position: center;
	}

	.txt-container {
		text-align: center;
		padding: 1em;

		h3 {
			margin-bottom: 0.5em;
		}

		.text {
			font-size: 20px;
			font-weight: $fontWeight-light;
		}

		.btn-box {
			max-width: 100%;		
			background-color: $color-green;
			color: $color-invert;
			border: none;
			border-radius: 3px;

			&:after {
				border-color: $color-invert;
			}

		}
	}
}

@media(min-width: $bp-mw) {
	.simple__banner {
		.img-container {
			height: 242px;
		}

		.txt-container {
			text-align: left;
	    position: absolute;
			top: 5%;
			right: 15%;
			width: 300px;
		}
	}
}

@media(min-width: $bp-l) {
	.simple__banner {	
		.img-container {
			height: 440px;
		}
		.txt-container {
	   	top: 5%;
		}
	}
}

/* ================================================================
   Blue Banner
	 ================================================================ */
.blue__banner{
	display: flex;
	flex-flow: row wrap;

	.img-container {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		min-height: 400px;
		width: 100%;
	}

	.label {
		display: inline-block;
		@include x-rem(font-size, 12px);
		background: $color-dark-blue;
		border-radius: 3px;
		padding: 0.5em 0.8em;
		margin-bottom: 1.5em;
	}

	.banner-video {
		position: relative;
		height: 100%;

		iframe {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	.txt-container {
		width: 100%;
		padding: 2em;
		color: $color-invert;
		background: $color-secondary;
		@include x-rem(font-size, 20px);
		font-weight: 300;

		h2,h3,h4 {
			color: $color-invert;
		}

		.btn-box {
			display: inline-block;
			width: auto;
			@include x-rem(font-size, 16px);
		}
	}

	&.quote {
		.txt-container {
			@include x-rem(font-size, 26px);
			font-style: italic;
			font-weight: 300;
		}
	}

}

@media(min-width: $bp-m) {
	.blue__banner {
		.img-container, .txt-container {
			width: 50%;
		}
	}
}

/* ================================================================
   Persistent Banner Ad
	 ================================================================ */
	 .persistent-banner {
		display:none;
		position: fixed;
		bottom: 0;
		left: 0;
		background: $color-blue-alt;
		color: white;
		border-top: 2px solid white;
		width: 100%;
		padding: 15px;
		font-size: 1rem;
		z-index: 2;
	  }
	  
	  .persistent-banner--hidden {
		display: none !important;
	  }
	  
	  .persistent-banner__container {
		  display: flex;
		  flex-direction: column;
		  align-items: center;
		  position: relative;
	  }

	  .persistent-banner__text {
		margin-bottom: 10px;
		text-align: center;
	  }
	  
	  .persistent-banner__button {
		background: white;
		color: $color-blue-alt;
		font-size: 1.1rem;
		text-decoration: none;
		padding: 8px 15px;
		border-radius: 5px;
	  }
	  
	  .persistent-banner__button:hover,
	  .persistent-banner__button:focus {
		background-color: #333;
		border-color: #333;
		color: #fff;
		text-decoration: none;
	  }
	  
	  .persistent-banner__close {
		position: absolute;
		padding: 20px;
		right: 0;
		bottom: 0;
		background: transparent;
		border: none;
		margin-left: 10px;
		&:hover {
		  cursor: pointer;
		  &::before,
		  &::after {
			background: #333;
		  }
		}
		&::before,
		&::after {
		  content: "";
		  background: white;
		  position: absolute;
		  height: 20px;
		  width: 3px;
		  top: 10px;
		}
		&::before {
		  transform: rotate(45deg);
		  right: 18px;
		}
		&::after {
		  transform: rotate(-45deg);
		  right: 18px;
		}
	  }
	  
	  @media screen and (min-width: $bp-m) {
		.persistent-banner {
		  font-size: 1.6rem;
		}
	  
		.persistent-banner__button {
		  font-size: 1.4rem;
		}
	  
		.persistent-banner__close {
		  right: 40px;
		}
	  }

	  @media screen and (min-width: $bp-mw) {
		.persistent-banner {
			display: block;
		  }
		  
		.persistent-banner__container {
			flex-direction: row;
			justify-content: center;
		}

		.persistent-banner__text {
			margin-bottom: 0;
		}

		.persistent-banner__interactives {
			margin-left: 20px;
		}
	  }

/* ================================================================
   Color Banner
	 ================================================================ */

.color-banner {
	padding: 2rem 0;
	margin-top: 2rem;
	color: $color-white;
	background: $color-primary;
}

.color-banner__heading {
	font-family: $font-secondary;
	color: $color-white;
	font-weight: 700;
}

.color-banner__text {
	font-size: 18px;
	line-height: 26px;
}

.color-banner__link {
	color: $color-white;
	border: 1px solid $color-white;
	border-radius: 10px;
	font-weight: 700;
	padding: 1.25rem 2.5rem;
	text-decoration: none;
	transition: .2s ease-out;

	&:hover,
	&:focus {
		background: $color-white;
		color: $color-font;
		text-decoration: none;
	}
}

@media(min-width: $bp-l) {
	.color-banner {
		padding: 4rem 0;

		.container {
			display: flex;
			align-items: center;

			div:first-child {
				width: 60%;
			}

			div:last-child {
				margin-left: auto;
			}
		}
	}
}