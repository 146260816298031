/* ******************************************************
   NAVIGATION
        - Section (Rail) Navigation
   ****************************************************** */

.section-nav {
  background: $color-primary;
  margin: 2em 0;

  li {
    &.active a {
      font-weight: 800;
    }
  }

  a {
    color: $color-invert;
  }
}

// secondary-nav
.secondary-nav__heading {
  color: $color-blue-alt;
  display: flex;
  align-items: center;
  padding: 10px 15px !important;
  margin-bottom: 0 !important;
  font-size: 1.1rem;
  font-weight: 700;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid $color-base;

  .secondary-nav__title {
    margin-left: 17px;
  }
}

.secondary-nav {
  background: $color-white;
  font-size: 0.9rem;
  color: #333;
  margin-top: 0;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);

  a {
    color: #333333 !important;
    &:hover,
    &:focus {
      color: $color-blue-alt !important;
      text-decoration: none;
    }
  }

  .secondary-nav__list {
    padding: 0.5em;
    display: none;
    &.opened {
      display: block;
    }
  }

  .secondary-nav__item {
    padding-left: 0 !important;

    &:not(.no-sub) {
      margin-left: 26px;
    }

    a {
      margin-left: 1px;
    }

    .has-sub {
      span {
        background: transparent;

        &:after {
          display: block;
          height: 8px;
          width: 8px;
          border-bottom: 2px solid #004e86;
          border-right: 2px solid #004e86;
          border-left: none;
          border-top: none;
          transform: rotate(-45deg);
          cursor: pointer;
          transition: 0.2s ease-in;
          transform-origin: center;
          position: absolute;
          top: 9px;
          left: 5px;
        }
      }
    }

    &.opened {
      .has-sub span:after {
        transform: rotate(45deg);
      }
    }
  }

  .no-sub a {
    padding: 0.3em 0;
    margin-left: 1.9em;
  }

  .secondary-nav__section-title {
    font-weight: 700;
    position: relative;
    padding: 0.3em 0;
    margin-left: 10px;
  }

  .secondary-nav__section-list {
    position: static;

    li {
      padding-left: 0 !important;
    }

    a {
      padding: 0.3em;
    }
  }
}
