/* ================================================================
   Lists
	 ================================================================ */

.site-main .sfContentBlock {
	 ul, ol {
		 padding-left: 0;
		 margin: 1em 0;
		list-style-position: outside !important;
	}
	
	ul {
		list-style: none;
		
		> li {
			position: relative;
			margin-bottom: 0.3em;
			&:before{
				content:"";
				position: absolute;
				top: 10px;
				left: 0;
				background:$color-dark-grey;
				width: 5px;
				height:5px;
				margin-top: -2px;
				border-radius: 50%;margin-right: 12px;
				display: inline-block;
				vertical-align: middle;
			}
		}
		
		&.list--grey {
			> li:before {background: $color-base;}
		}
		
		&.list--white {
			> li:before {background: $color-white-smoke;}
		}
	}
	
	ol {
		padding-left: 0.8em;
	}
	
	ul li {
		padding-left: 0.8em;
	}

	//Checkmark List Styling

	.checkmark-list{
		margin: 0;
		list-style: none;
		li{
			position: relative;
			padding-left: 1.6em !important;
			margin-bottom: 1em;
			&:before {
				content: "";
				display: inline-block;
				position: absolute;
				top: 6px;
				left: 0;
				height: 8px;
				width: 15px;
				background: transparent;
				border-radius:  0;
				border-left: 4px solid $color-green;
				border-bottom: 4px solid $color-green;
				transform: rotate(-55deg);
				margin-right: 12px;
				vertical-align: middle;
			}
		}
	}

	.cross-list{
		list-style: none;
		margin: 0;
		li{
			position: relative;
			padding-left: 1.6em !important;
			margin-bottom: 1em;
			&:before {
				content: "\2715";
				position: absolute;
				top: -3px;
				font-size: 20px;
				left: 0;
				color: #E22922;
				background: transparent;
				border: none;
				margin-right: 12px;
				vertical-align: middle;
			}
		}
	}


  .footnote-list{
    counter-reset: footnote-counter;
		list-style: none;
		margin: 0;
		li{
			font-size: 10px;
			position: relative;
			padding-left: 0;
			margin-bottom: 0;
			line-height: normal;
			&:before {
				background: transparent;
				content: counter(footnote-counter);
				counter-increment: footnote-counter;
				display: inline-block; 
				font-size: 10px;      
				height: auto;
				position: relative;
				top: 0;
				margin: 0 0.5em 0 0;
				vertical-align: super;
				width: auto;
			}
			&:last-of-type {
				margin-bottom: 1em;
			}
		}
	}
}


.list-tool {
	margin-top: 2em;
	margin-bottom: 1em;

	&.accordion-list {
		ul {
			list-style:none;
			margin:0; padding:0;

			li {
				margin-bottom: 1em;
        font-size: 0.875rem;

				> a {
					display:block;
          color: $color-dark-grey;
          font-weight: $fontWeight-bold;
					position:relative;

          &:after {
            content: "";
            display: inline-block;
            width: 8px;
            height: 8px;
            border-top: 2px solid $color-dark-grey;
            border-right: 2px solid $color-dark-grey;
            margin-left: 1em;
            transform: rotate(135deg);
          }

          &.active:after {
            transform: rotate(-45deg);
          }

				}
			}
		}

		&.advisor {
			margin-top: 0;

			ul {
				li {
					padding: 0 !important;
					margin: 0;

					a {
						color: $color-dark-blue;
						font-size: 1.5rem;
						padding: 1rem;
						border-bottom: 1px solid rgba(0,118,214,.37);
						text-decoration: none;

						&:before {
							content: '';
							display: block;
							width: 18px;
							height: 2px;
							background-color: $color-blue-alt;
							position: absolute;
							top: 50%;
							right: 1rem;
							transform: translateY(-50%);
						}

						&:after {
							content: '';
							display: block;
							width: 2px;
							height: 18px;
							background-color: $color-blue-alt;
							position: absolute;
							top: 50%;
							right: 1.5rem;
							transform: translateY(-50%);
							border-top: none;
							border-right: none;
						}

						&.active {
							background: $color-pastel-blue;

							&:after {
								display: none;
							}
						}
					}

					.item-content {
						padding: 1rem;
						color: $color-dark-blue;
						font-size: 1rem;
						background: $color-pastel-blue;
						display: block;
					}
				}
			}
		}
	}
}

//Expandable List
.expand-list {
	.list-container {

		.list-heading {
			cursor: pointer;
			background: $color-invert;
			text-align: left;
			border: none;
			width: 100%;		
			padding: 1em;

			&.active {
				h2:after {
					content: "-";
				}
			 }

		}

		h2 {
			margin: 0;
		}

		h2 {
			color: $color-base;

			&:after {
				content: "+";
				float: right;
				font-size: 46px;
				line-height: 40px;
				font-weight: 300;
				color: $color-blue;
			}
		}

		h3 {
			color: $color-base;
			font-size: 16px;
			font-weight: 400;
			margin-top: 0;
			margin-bottom: 0.3em;
		}

		.list-panel {
			display: none;
			// margin-top: 1em;
			padding: 1em;
			background: $color-invert;

			.item {
				border: none;
				padding: 0.3em 0 0.3em 1em;
			}

			ul > li {
				&:before {
					background: $color-base;
					margin-top: 3px;
				}
			}
		}
	}
}

.k-list-container .k-list li:before {
  display: none;
}

//Global Dropdown

.global-nav {
	display: none;
	position: absolute;
	background: $color-invert;
	z-index: 10;
	right: 0;
	box-shadow: 0 3px 5px 0 rgba(0,0,0,.2);
	min-width: 450px;
	border-radius: 5px;
	padding: 1em;
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;

		li {
			border-bottom: 1px solid $color-primary;

			&:last-child {
				margin-bottom: 0;
			}

			a {
				position: relative;
				display: block;
				padding: 0.5em 1.5em 0.5em 0.5em;
				color: #666666;

				&:after {
					content: "";
					display: block;
					width: 8px;
					height: 8px;
					border-top: 2px solid $color-secondary;
					border-right: 2px solid $color-secondary;
					position: absolute;
					right: 10px;
					top: 50%;
					-webkit-transform: rotate(45deg) translateY(-50%);
					-ms-transform: rotate(45deg) translateY(-50%);
					transform: rotate(45deg) translateY(-50%);
				}

				&:hover,&:focus {
					text-decoration: none;
					color: $color-blue;
					font-weight: $fontWeight-bold;

				}
			}
		}
	}
}

.global-container {
	display: none;
	text-align: right;

	ul {
		text-align: left;
	}
}

.btn-dropdown {
	display: none;
}

.btn-dropdown.active {
 ~ .global-nav {
	 display: none;
 }
}

@media(min-width: $bp-l){
	.global-container {
		display: block;
	}

	.btn-dropdown {
		display: flex;
		align-items: center;
		margin-left: auto;
	}

	.btn-dropdown.active {
		~ .global-nav {
			display: block;
		}
	 }
}

.accordion-list ul li:before{
  display: none;
}