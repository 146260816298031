/* ================================================================
   Global Site CSS Animations
   ================================================================ */


/* Fade In */
@keyframes fadeIn {
  100% { opacity:0; }
  0% { opacity:1; }
}


/* Fade Out */
@keyframes fadeOut {
  0% { opacity:1; }
  100% { opacity:0; }
}





/* ================================================================
   Site Specific Below
   ================================================================ */

