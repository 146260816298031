/* ================================================================
   Blogs
   ================================================================ */

/* Landing page */

.landing-middle {
    // 959
    @media (max-width: $bp-m-max){
        .content-box.greyBg {
            padding: 0;
        }
    }

}
/*  */
.one-col-details {
    // 960
    @media (min-width: $bp-mw){
        float: none;
        max-width: 860px;
        margin: 0 auto;
    }
}

/* share and breadcrumbs container */
.interior-top-tools {
    padding-top: 30px;
}

.blog-posts {

    .summary,
    .content {
        color: $color-dark-grey;

        // 959
        @media (max-width: $bp-m-max){
            @include x-rem(font-size,16px);
        }

        // 960
        @media (min-width: $bp-mw){
            @include x-rem(font-size,20px);
        }
    }

    .more {
        color: $color-primary;
        margin-top: 1em;
        display: inline-block;
        @include x-rem(font-size,16px);
        font-weight: $fontWeight-bold;

        &:after {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            border-top: 2px solid $color-primary;
            border-right: 2px solid $color-primary;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    .item-content {
        display: block;
    }

/* ================================================================
   Blog - Landing
   ================================================================ */

	&.landing {

		.img-container {
				img{
						width: 100%;
				}
		}

		.item {
			background: $color-invert;
			margin: 2em 0;
		}

        .item-link {
            text-decoration: none;
            display: block;
            height: 100%;
        }

        .item-content {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

		.item-description {
			padding: 1em;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
		}

		.item-cta {
			margin-top: auto;
		}

        .caretBtn {
            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

		.title {
				margin-bottom: 0.5em;
				color: $color-primary;
		}

		// 960
		@media (min-width: $bp-mw){
				.item-list {
						display: flex;
						flex-wrap: wrap;

						.item {
								width: 46.5%;
								margin: 1em;
						}

						.title {
								@include x-rem(font-size,25px);
						}


				}
		}

		@media (min-width: $bp-l){
			.item-list {
					.item {
							width: 30.5%;
					}
			}
		}

        &.resource-blogs {
            .item {
                box-shadow: 0 2px 8px rgba(0,0,0,0.25);
            }

            .item-category {
                font-weight: 700;
                color: $color-secondary;
            }

            .title {
                color: $color-font;
                font-family: $font-secondary;
                font-weight: 700;

                @media(min-width: $bp-mw) {
                    font-size: 1.25rem;
                }
            }

            .item-link {
                .caretBtn {
                    color: $color-font;

                    &:after {
                        border-top: 2px solid $color-font;
                        border-right: 2px solid $color-font;
                        width: 8px;
                        height: 8px;
                        margin-left: 0;
                        margin-bottom: 1px;
                    }
                }
            }
        }

	}

    &.talking-points {
        @media(min-width: $bp-m) {
            border-bottom: 1px solid $color-dark-grey;
        }

        .item {
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            border-bottom: 1px solid $color-dark-grey;
        }

        .item-category {
            font-weight: 700;
            color: $color-secondary;
        }

        .title {
            color: $color-font;
            font-size: 1.5rem;
        }

        .caretBtn {
            color: $color-font;

            &:after {
                width: 8px;
                height: 8px;
                margin-left: .25em;
                margin-bottom: 1px;
                border-top: 2px solid $color-font;
                border-right: 2px solid $color-font;
            }
        }
    }

    .talking-points__heading {
        font-family: $font-secondary;
        color: $color-dark-grey;
        position: relative;
        text-align: center;
        border-bottom: 1px solid $color-dark-grey;
        padding-bottom: .25rem;
        font-weight: 700;
        font-size: 1.5rem;
    }

    @media(min-width: $bp-m) {
        &.talking-points {
            .talking-points__top {
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 1px;
                    background: $color-dark-grey;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                }
            }

            .item-list {
                display: flex;
                flex-wrap: wrap;

                .item {
                    width: 29%;
                    margin: 0 2%;
                    border-bottom: none;

                    &:nth-child(2),
                    &:nth-child(3) {
                        position: relative;

                        &:before {
                            content: '';
                            display: block;
                            width: 1px;
                            height: 100%;
                            background: $color-dark-grey;
                            position: absolute;
                            top: 0;
                            left: -25px;
                        }

                    }
                }
            }
        }

        .talking-points__heading {
            text-align: left;
            border-bottom: none;
            display: inline-block;
            padding-right: 10px;
            background: $color-white;
            z-index: 1;
        }
    }

    &.featured {
        margin-top: 1.5rem;

        .item-description {
            padding: 1em 0;
        }

        .img-container {
            max-width: 450px;

            @media(min-width: $bp-m) {
                max-width: none;
            }

            img {
                width: 100%;
            }
        }

        .title {
            font-family: $font-secondary;
            color: $color-font;
            font-weight: 700;
        }

        .item-summary {
            margin-bottom: 1rem;
        }

        .featured__link {
            color: $color-base;
            border: 1px solid $color-base;
            padding: .5rem 1rem;
            text-decoration: none;
            transition: .2s ease-out;

            &:hover,
            &:focus {
                background: $color-base;
                color: $color-white;
            }
        }

        @media(min-width: $bp-m) {
            margin-top: 3rem;

            .item-list {
                .item {
                    width: 100%;
                }

                .item-content {
                    display: flex;
                }

                .img-container {
                    width: 60%;
                }

                .item-description {
                    width: 40%;
                    padding: 1em;
                }
            }
        }

        @media(min-width: $bp-l) {
            .item-list {
                .item-description {
                    padding: 2em;
                }
            }
        }
    }


/* ================================================================
   Blogs - Details
   ================================================================ */

    &.blog-details {

        .title {
            color: $color-primary;
            font-weight: $fontWeight-light;
        }

        // 767
        @media (max-width: $bp-sw-max) {

            .social-share-container {
                flex-direction: column;

                .at-share-btn-elements{margin-left: -15px;}
            }

        }

        // 959
        @media (max-width: $bp-m-max){
            .title {
                @include x-rem(font-size,22px);
            }
        }

        // 960
        @media (min-width: $bp-mw){
            .title {
                @include x-rem(font-size,32px);
            }
        }

    }

    &.blog-details--featured {
        max-width: 840px;
        margin: 4rem auto;

        .item-category {
            color: $color-secondary;
            font-weight: bold;
        }

        .title {
            color: $color-font;
            font-family: $font-secondary;
            font-weight: 700;
            margin-bottom: 1.5rem;

            @media(min-width: $bp-mw) {
                font-size: 2.25rem;
            }
        }

        .meta {
            display: flex;
            align-items: center;
            font-family: $font-secondary;
            margin-bottom: 1.5rem;

            li {
                list-style: none;
                padding-left: 0 !important;
                font-size: 1rem;

                &:before {
                    display: none;
                }
            }

            .author {
                font-weight: 500;
                margin-right: 2rem;
            }

            .date {
                margin-top: 0;
                font-style: normal;
            }
        }

        .content {
            font-size: 1.125rem;
        }

        .blog-details__social-list {
            margin: 2rem 0;
            text-align: center;

            @media(min-width: $bp-m) {
                text-align: left;
            }

            .blog-details__social-icon {
                fill: $color-secondary;
                width: 28px;
                height: 28px;
                margin-right: 2px;
                transition: .2s ease-out;
            }

            .blog-details__social-link:hover,
            .blog-details__social-link:focus {
                .blog-details__social-icon {
                    fill: $color-dark-grey;
                }
            }
        }
    }

    &.related-posts {
        margin-bottom: 0;

       .title {
           color: $color-font;
            text-align: center;
            @include x-rem(font-size,24px);

            &:after {
                content:"";
                display: block;
                width: 44px;
                height: 4px;
                margin: 15px auto;
                background: $color-dark-blue;
            }
        }

        .related-posts-slider {
            padding: 0 3em;

            li {
                padding-left: 0 !important;
            }

            .item { padding:0 15px; }

            .img-container {
                display: block;
                width:100%;
                height:250px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center 15%;
                border-bottom: 3px solid $color-secondary;
            }

            .slide-title {
                @include x-rem("font-size", 20px);
                text-align: center;
                color: $color-font;
                margin: 0.5em 1em;
            }

            .slick-arrow {
                width: 30px;
                height: 30px;
                border-color: $color-secondary;
                z-index: 1;
            }

            .slick-next, .slick-prev {
                top: 35%;

            }

            .slick-next {
                right: 0;
            }

            .slick-prev {
                left: 0;
            }

        }



    }

}

@media screen and (min-width:$bp-m-max) {
    .related-posts-slider {
        padding: 0 3em;

        .img-container {
            background-position: center center;
            margin: 0 0.5em;
        }
    }
}

.related-posts-slider.slick-slider {
    margin-bottom: 0;
}

/* ================================================================
   Blog - Cards
   ================================================================ */

.blog-cards {
    display: flex;
    flex-flow: row wrap;
    margin: 2em -0.9em 3em -0.9em;

    .item {
        width: 100%;
        padding: 0 0.9em;
        display: flex;
        flex-flow: column;

        .card-txt {
            background: $color-invert;
            height: 100%;

            .caption {
                padding: 1em;
            }
        }

        .card-btn {
            background: $color-invert;
            padding: 1em;
        }
    }

    figure {
        margin: 0;
        height: 200px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .btn-box {margin-top: 2em; max-width: none;}

}

@media(min-width: $bp-m) {
    .blog-cards {
        .item {
            width: 50%;
        }
    }

}

@media(min-width: $bp-l) {
    .blog-cards {
        .item {
            width: 33.33%;
        }
    }

}

.card.blog-header-card {
    margin: 0 0 2rem;

    .img-container {
        overflow: hidden;
        position: relative;
        max-width: 1920px;
        background-size:cover;
        background-repeat:no-repeat;
        background-position:center;

        img {
            position: absolute;
            bottom: 0;
        }
    }

    // 767
    @media (max-width: $bp-sw-max){
        .img-container {
            height: 145px;

            img {bottom: auto; top: 0;}
        }
    }

    // 768 - 959
    @media (min-width: $bp-m) and (max-width: $bp-m-max){
        .img-container {
            height: 250px;
        }
    }

    // 960
    @media (min-width: $bp-mw){
        .img-container {
            height: 300px;
        }
    }

    // 1152
    @media (min-width: $bp-l){
        .img-container {
            height: 400px;
        }
    }

}

