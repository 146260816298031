
/* ================================================================
   Slideshow
	 ================================================================ */
  .home-rotator {
    position: relative;    
    margin-bottom: 2em;

    .txt-container {
      position: relative;
      padding: 1em;
      background: $color-invert;
    }       

    .title {		
      color: $color-secondary;
      margin-bottom: 10px;
      font-weight: 600;
      margin: 0;
    }
  
    .img-container {
      width: 100%;
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 150px; 
    }

    p{
      margin-bottom: 5px;
    }
  
    a {
      display: inline-block;
      color: $color-primary;
      @include x-rem(font-size,16px);
      font-weight: $fontWeight-bold;
      margin-top: 0.6em;      
  
      &:after {
        content:"";
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-left: 0.5em;
        border-top: 2px solid $color-primary;
        border-right: 2px solid $color-primary;
        transform: rotate(45deg);
        transition: 0.2s all linear;
      }

      &:hover, &:focus {
        &:after {
          margin-left: 1em;
        }
      }
    }
  
  
    .slick-dots{
      position: absolute;
      left: 50%;
      bottom: -50px;
      transform: translateX(-50%);


      li {
        margin: 0 8px;
        padding-left: 0 !important;
  
        &.slick-active button{
          background: $color-secondary;
        }
      }
  
      li button {
        width: 15px;
        height: 15px;
        background: $color-primary;
        border-radius: 50%;
        transition: all 0.2s linear;
        border: 1px solid #fff;
  
        &:hover,&:focus{
          background: $color-secondary;
        }
  
        &:before {
          display: none;
        }
      }
  
    }
  
    // 960
    @media (min-width: $bp-m){

      .img-container {
        height: 242px;
      }
  
    }

    @media(min-width: $bp-l) {
      .item {
        height: 440px;
        .container {
          position: relative;
          height: 100%;
        }
      }

      .img-container {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;

  
        img{
          display: block;
          min-width: 1920px;
          height: 100%;
        }
      }
  
      .txt-container {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        min-height: 100px;
        padding: 2em;
        padding: 30px 60px 30px 22px;
        width: 460px;
  
        .slide-text {
          font-size: 22px;
          font-weight: 300;
        }

        .title {
          @include x-rem(font-size,36px);
        }
  
        .container {
          padding-right:0;
        }

        &.lc {
          right: auto;
          left: 0;
        }
  
      }

      .slick-dots {
        bottom: 0;
      }
  
    }
  
  }


  /* ================================================================
   Slideshow - Carousel
	 ================================================================ */

   .rotator--carousel .slick-track {
    display: flex;

    .slick-slide {
      float: none;
      height: auto;

      > div {
        height: 100%;
      }
    }
   }

  .rotator--carousel .rotator__item {    
    display: flex !important;
    flex-flow: column;
    height: 100%;

    .rotator__content {
      padding: 2em 1em;
      position: relative;
      z-index: 2;

      h2 {
        font-family: $font-heading;
        font-weight: $fontWeight-extrabold;
        font-size: 2rem;
        line-height: 1.2;
        margin: 30px 0;
      }

      p {
        font-size: 1.125rem;
      }
    }

    .feature-heading {
      color: $color-secondary;
      font-size: 1.25rem;
      font-weight: $fontWeight-bold;
    }
    .rotator__media {
      position: relative;
      margin-top: auto;
      min-height: 350px;

      &:before {
        content: "";
        background: linear-gradient(-180deg, rgba(255,255,255,1) 0, rgba(255,255,255,0) 100%);
        position: absolute;
        top: 0;
        left: 0;
        height: 50%;
        width: 100%;
        z-index: 1;
      }
    }

    .rotator__image {
      object-fit: cover;
      position: absolute;
      width: 100%;
      height: 100%;
    }
    @media(min-width: $bp-mw) {
      position: relative;
      .rotator__media{
        flex: 1 65%;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 60%;

        &:before {
          background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);        
          height: 100%;
          width: 50%;
        }
      }

      .rotator__content {
        display: flex;
        flex-flow: column;
        justify-content: center;
        min-height: 350px;

   
      }

      .rotator__text {
        width: 37%;
      }
    }

    @media(min-width: $bp-l) {
      .rotator__content {
        min-height: 620px;
        h2 {
          font-size: 2.95rem;
        }
      }
    }
  }

  .rotator--carousel .slick-dots {
    background: $color-white-smoke;
    margin: 0 0 2em 0;
    padding: 2em 0;

    li {
      display: none;
      height: auto;
      width: auto;

      &.slick-active {
        display: inline-block;
        span {
          color: $color-dark-grey;
        }
      }

      button:before {
        font-size: 3rem;
      }

      span {
        color: $color-taupe-grey;
        font-weight: $fontWeight-extrabold;
        text-transform: uppercase;
      }
    }

    @media(min-width: $bp-mw) {
      li {
        display: inline-block;
      }
    }
  }

  .rotator--carousel {
    .slick-next, .slick-prev {
      top: 93%;
      height: 15px;
      width: 15px;
    }

    .slick-next {
      right: 5%;
    }

    .slick-prev {
      left: 5%;
    }
  }