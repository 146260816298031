/* ==========================================================================
   FAQs
   ========================================================================== */

.faq-legend {
  display:inline-block;
  padding:$grid-gutter/2 $grid-gutter; margin-bottom:2em;
  border:1px solid hsl(0%, 0%, 80%);
  background:hsl(0%, 0%, 95%);

  ol { margin:0; padding:0 0.5em 0 15px; }

  li {
    list-style-type: square;

    + li { margin-top:0.2em; }

    a {
      text-decoration:none;
      &:hover, &:focus { text-decoration:underline; }
    }
  } 
}

.faq-details {
  .module-list .item + .item { margin-top:$grid-gutter; }

  .item {
    .title { padding-right:40px; }

    .top {
      position:absolute; top:5px; right:0;
      @include x-rem(font-size, 10px);
      text-transform:uppercase; text-decoration:none;
      color:hsl(0%, 0%, 80%);

      &:hover, &:focus { text-decoration:underline; }

      &:before {
        content:'';
        position:absolute; right:100%; top:4px;
        margin-right:4px;
        width:0; height:0;
        border-style:solid; border-width:0 5px 5px 5px; border-color:transparent transparent hsl(0%, 0%, 80%) transparent;
      }
    }
  }
}

//FAQ Module

.question-accordions {
  border: 1px solid #c7c7c7;
  margin: 1.5em 0;
  .question-heading {
    position: relative;
    background-color: $color-invert;
    padding: 1em;
    cursor: pointer;
    border-bottom: 1px solid #c7c7c7;

    h3 {
      font-size: 18px;
      font-weight: $fontWeight-medium;
      color: $color-base;
      margin: 0;

      &:before {
        content: "Q";
        color: $color-secondary;
        margin-right: 0.5em;
        font-size: 18px;
      }
    }

    &:after {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      border-top: 2px solid $color-base;
      border-right: 2px solid $color-base;
      position: absolute;
      right: 20px;
      top: 42%;
      -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(135deg);
    }
  }
  .answer {
    display: none;
    padding: 1em;
    margin: 1em 0;
  }

  .question-item {
    width: 100%;
    text-align: left;
    padding: 0;
    border: none;
    &.active {
      .question-heading:after {
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
  }

  .question-item:last-child {
    .question-heading {
      border-bottom: 0;
    }
  }
}