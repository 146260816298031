/* ================================================================
   Cards
   ================================================================ */

   .jumbotron {
       height: 450px;
   }

    .card{
        background-color: $color-invert;
        width: 100%;
        margin: 2em 0 2em 0;
    
        .img-container {
    
            img {
                width: 100%;
                display: block;
            }
        }   
    
        .card-txt {
            padding: 1em;
        }
    
        .area {
            background: $color-primary;
            margin: 0;
            color: #fff;
            text-transform: capitalize;
            width: 145px;
            text-align: center;
            padding: 2px 10px;
            border-radius: 2px;
            margin-top: -39px;
            position: relative;
        }
    
        .title {
            display: block;
            color: $color-font;
            font-weight: 300;
            @include x-rem(font-size,25px);
            margin: 0 0 0.5em 0;
        }
    
        p {
            @include x-rem(font-size,16px);
        }
    
    
        // 768
        @media (min-width: $bp-m){
            .title {@include x-rem(font-size,18px);}                
            .card-txt {
                display: flex;
                flex-flow: column;
    
                .caption {
                    height: 100%;
                }
            }
        }
    
        // 1152
        @media (min-width: $bp-l){    
            .title {
                @include x-rem(font-size,25px);
            }
    
            .caption p{
                @include x-rem(font-size,16px);
            }
        }
    }

.card.interior-card {

    .img-container {
        
        img {width: 100%;display: block}
    }
    
    &:not(.blog-header-card) {
        // 959
        @media (max-width: $bp-m-max){
            margin-bottom: 20px;
        }
    }
}


.banner {
    padding: 1.5em 2em;
    margin: 1em 0;
    h2 {
        margin: 0 0 0.3em 0;
    }

    .btn-box {
        display: inline-block;
        margin: 0.5em auto 0 auto;
        max-width: 100%;
    }

    .content {
        text-align: center;
        @include x-rem("font-size", 18px);
    } 

    &.green-layout {
        h2,h3 {
            color: $color-invert;
        }

        .caption a {
            color: $color-invert;
        }
    }

}

@media(min-width: $bp-m) {
    .banner { 
    
        .content {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            width: 100%;
            text-align: left;
    
            .caption {
                width: 75%;
                padding: 0 1em;
            }
    
            .card-link {
                width: 25%;
            }
        }
    }

    .banner.green-layout {
        .content {
            align-items: baseline;
            .caption, .card-link {
                width: 49%;
            }
        }
    }

    .banner.white-layout {
        .content {
            .img-container {
                flex: 1 10%;
            }

            .caption {
                flex: 1 70%;
            }

            .card-link {
                flex: 1 20%;

                .btn-box {
                    float: right;
                }
            }
        }
    }
}

@media(min-width: $bp-mw) {
    .banner {
        h2 {
            @include x-rem(font-size, 38px);
        }
    }
}

.blue-card {
    background-color: $color-secondary;
    color: $color-invert;
    margin: 2em 0;

    h3 {
        font-size: 26px;
        font-weight:  $fontWeight-light;
        color: $color-invert;
        padding-left: 0.5em;
    }
    .img-container {
        display: flex;
        flex-flow: row wrap;
        .card-img {
            width: 30%;
        }

        .title {
            width: 70%;
        }
    }
    .card-txt {
        padding: 1.5em;
        font-size: 22px;
        font-weight:  $fontWeight-light;
    }
    .card-action {
        background-color: $color-primary;
        padding: 1.5em;
    }
}

.green-card {
    background-color: $color-green;
    color: $color-invert;
    margin: 1em 0;

    h3 {
        font-size: 26px;
        font-weight:  $fontWeight-light;
        color: $color-invert;
        padding-left: 0.5em;
    }
    .img-container {
        display: flex;
        flex-flow: row wrap;
        .card-img {
            width: 30%;
        }

        .title {
            width: 70%;
        }
    }
    .card-txt {
        padding: 1.5em 1.5em 0 1.5em;
        font-size: 22px;
        font-weight:  $fontWeight-light;

        .caption {
            margin-top: 0.5em;
            margin-bottom: 1.5em;
        }
    }
    .card-action {
        background-color: $color-green;
        padding: 0 1.5em 1.5em 1.5em;

        .btn-box {
            border: none;
            color: $color-green;
        }
    }
}

/* ================================================================
   Product Cards
   ================================================================ */

   .products {
       display: flex;
       flex-flow: row wrap;
       margin: 1em -0.9em;
       justify-content: center;
       .product-item {      
           display: flex;
           flex-flow: column;  
           justify-content: center;  
           width: 100%;
           padding: 0.9em;      
           .product-img {
               width: 100%;
               height: 200px;
               background-size: cover;
               background-repeat: no-repeat;
               background-position: center;
           }

           .product-content {
               position: relative;
               height: 100%;
               background-color: $color-invert;

               .content {
                   padding: 0 1em 1em 1em;
                   font-size: 20px;
                   font-weight: $fontWeight-light;
               }              
           }

           .product-heading {
               position: relative;
               padding: 1em;
           }

           .product-links {
                padding: 1em;
                background-color: $color-invert;
                .btn-green {
                    width: 100%;
                    text-align: left;
                }
            }

           .product-rate {
                position: absolute;
                right: 10px;
                top: -40px;
                width: 80px;
                height: 80px;
                padding: 2em 0.5em;
                font-size: 12px;
                font-weight: $fontWeight-bold;
                color: $color-green;
                text-align: center;
                border-radius: 50%;
                background: $color-invert;
                box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.18);
            }
       }
   }

   @media(min-width: $bp-m) {
       .products {
           .product-item {
               width: 50%;
           }
       }
   }

   @media(min-width: $bp-l) {
    .products {
        .product-item {
            .product-rate {
                right: 5px;
                top: -45px;
            }
        }
    }
}

/* ================================================================
   Simple Cards
   ================================================================ */
.simple-card {
    display: flex;
    flex-flow: row wrap;
    box-shadow: none;
    margin: 0;

    .card-txt {
        padding: 2em 0;

        .caption p {
            font-size: 20px;
        }

        h4 {
            color: $color-secondary;
            font-size: 26px;
            margin-bottom: 0.5em;
        }
    }

    .btn-box {
        display: inline-block;
        width: auto;
        border-color: $color-secondary;
        background-color: $color-secondary;
        color: $color-invert;
        margin-top: 1em;

        &:after {
            border-color: $color-invert;
        }
    }

    .img-container {
        text-align: center;
        padding-top: 2em;
        img {
            display: inline-block;
            width: auto;
        }
    }

    @media(min-width: $bp-m) {
        .card-txt, .img-container {
            flex: 1 50%;
        }
    }
}

/* ================================================================
   Ratings
   ================================================================ */

   .ratings {
       display: flex;
       flex-flow: row wrap;
       margin: 2em 0;

       .rating-box {
           width: 100%;
           border: 2px solid $color-primary;
           padding: 1em;
           margin: 1em;

           .heading {               
            text-align: center;
            padding: 0 10%;

           }

           h3 {
               font-size: 6em;
               font-weight: 800;
               margin-bottom: 0;
           }

           .subtitle {
               font-size: 24px;
               color: $color-primary;
               margin-bottom: 1em;
           }
       }
   }

   @media(min-width: $bp-m) {       
    .ratings {    
            .rating-box {
                width: 45%;
                
                .heading {
                    padding: 0 20%;
                }
            }
        }
    }

    @media(min-width: $bp-l) {       
        .ratings {    
                .rating-box {
                    width: 30%;                
                }
            }
        }


.staff {
    display: flex;
    flex-flow: row wrap;
    width: 100%;

    .staff-box {
        position: relative;
        margin: 0.5em;
        width: 100%;
        background: $color-invert;
        box-shadow: 0 3px 5px 0 rgba(0,0,0,.2);

        .img-container {
            width: 100%;
            border-bottom: 2px solid $color-secondary;
            height: 300px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            flex: none;        
        }

        .title {
            color: $color-primary;
        }
        .position{
           margin: .6em 0;
        }

        .social{
            .icon-linkedin-box{
                fill: $color-blue;
            }
            &:hover,&:focus{
                .icon-linkedin-box{
                    fill: $color-dark-blue;
                }
            }
        }

        .staff-txt {
            padding: 1em;
            flex-grow: 1;

            .caption {
                height: 100%;
                display: flex;
                flex-direction: column;
            }
        }

        .staff-link {
            display: inline-block;
            padding-right: 1em;
            position: relative;
            font-weight: $fontWeight-bold;

            &:after {
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                border-top: 2px solid $color-primary;
                border-right: 2px solid $color-primary;
                position: absolute;
                right: 0;
                top: 50%;
                -webkit-transform: rotate(45deg) translateY(-50%);
                -ms-transform: rotate(45deg) translateY(-50%);
                transform: rotate(45deg) translateY(-50%);
                transition: 0.2s all linear;
            }

            &:hover, &:focus {
                &:after {
                  right: -5px;
                }
              }
        }

        .staff-bio {
            margin-top: 2em;
        }
    }
    
}

.staff-contact {

    .staff-box {
        border: 1px solid #ccc;
        display: flex;
        flex-direction: column;

        .img-container {
            border-bottom: 0;
        }

        .name {
            font-weight: $fontWeight-bold;
            color: $color-secondary;
            margin: 0;
        }

        .position {
            font-weight: $fontWeight-normal;
            color: $color-font;
            margin: 0 0 1em 0;
        }

        .cta {
            color: $color-font;
            margin-bottom: 1em;
            margin-top: auto;
        }

        .contact-method {
            display: flex;
            align-items: center;
            
            + .contact-method {
                margin-top: 10px;
            }

            svg {
                color: $color-secondary;
                fill: currentColor;
            }

            &__email svg {
                width: 30px;
                height: 30px;
                margin-right: 1em;
            }

            &__phone svg {
                width: 45px;
                height: 45px;
                position: relative;
                left: -9px;
            }

            &__linkedin svg {
                width: 30px;
                height: 30px;
                margin-right: 1em;
            }
        }

        .contact__icon {
            width: 16px;
            height: 16px;
            margin-right: 1em;
        }

        .contact__link {
            color: $color-font;
        }
    }
}

@media(min-width: $bp-m) {
    .staff {
        .staff-box {
            width: 46%;            
        }
    }
}

@media(min-width: $bp-l) {
    .staff {
        .staff-box {
            width: calc(25% - 1rem);
            .img-container {
                height: 200px;
            }
        }
    }
}

.staff-detail {
    margin-bottom: 2em;
    .staff-box {
        margin: 2em 0;
    }
   
}

@media(min-width: $bp-m) {
    .staff-detail {
        align-items: flex-start;
        .staff-box {            
            margin: 0;
            width: 40%;
        }
        .staff-bio {
            width: 54%;
            margin-left: 2em;
        }
    }
}

@media(min-width: $bp-l) {
    .staff-detail {
        .staff-box {
            width: 25%;
        }

        .staff-bio {
            width: 72%;
        }
    }
}

/* ================================================================
   Card Callout
   ================================================================ */


   .card-callout {
       margin: 2em 0;
       text-align: center;
       .card-txt {
           margin-top: 1.5em;
       }

       .title {
        font-family:  $font-secondary;
        font-weight: $fontWeight-bold;
       }
   }

   .card-callout--alt {
       .title {
           font-family: $font-primary;
       }
   }

   
   @media(min-width: $bp-mw) {
    .card-container {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        .card-callout {
            flex: 1 25%;
            border-right: 1px solid $color-warm-grey;
 
            &:last-child {
                border-right: 0;
            }
        }       
    }

    .fake-card-container {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        justify-content: space-evenly;
        .sfContentBlock {
            flex: 1 25%;
            border-right: 1px solid $color-warm-grey;
 
            &:last-child {
                border-right: 0;
            }
        }    
    }

   }

   .grey-card {
    margin: 1.5em 0;
    background: $color-white-smoke;
    padding: 2em;

    .card-title {
      font-size: 1.125rem;
      font-weight: $fontWeight-bold;
      color: $color-taupe-grey;
      margin-bottom: 0.5em;
    }

    .feature-heading {
      font-family: $font-secondary;
      font-weight: $fontWeight-bold;
    }

    &.advisor {
        min-height: 222px;
        align-content: flex-start;

        .card-title {
            color: $color-black;
            margin-bottom: 2rem;
        }

        .card-txt {
            padding: 0;
        }
    }
   }

   .blue-stat {
    background: $color-pastel-blue;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 0.3em 0;
    padding: 1.25em 1.5em;

    .blue-stat__number {
      width: 25%;
      font-family: $font-heading;
      font-size: 1.75rem;
      padding-right: 0.5em;
    }

    .blue-stat__desc {
      width: 75%;
      font-size: 0.9rem;
    }

    .blue-stat__number, .blue-stat__desc {
      color: $color-dark-blue;
      font-weight: $fontWeight-bold;
    }
   }

   .card--featured {
    border: 1px solid $color-base;

    .img-container {
      position: relative;
    }

    .title {
      font-family: $font-heading;
      font-size: 1.5rem;
      font-weight: $fontWeight-extrabold;
      position: absolute;
      top: 1em;
      left: 0.8em;
    }

    a, .caretBtn {
      color: $color-secondary;

      &:after {
        border-color: $color-secondary;
      }
    }
   }

   @media(min-width: $bp-mw) {
    .grey-card {
      display: flex;
      flex-flow: row wrap;
      align-content: center;
    }

    .card-txt {
      flex: 1 55%;
    }

    .img-container {
      flex: 1 45%;
    }
   }

/* ================================================================
   Icon Card
   ================================================================ */
   .icon-card {
       box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.10);
       padding: 1.5rem;
       min-height: 341px;
       display: flex;
       flex-direction: column;
       margin-bottom: 1rem;
   }

   .icon-card__heading {
       display: flex;
       align-items: flex-start;
       margin-bottom: 1rem;
   }

   .icon-card__img {
       max-width: 68px;
       margin-right: 1rem;
   }

   .icon-card__title {
       font-size: 1.75rem;
       font-weight: $fontWeight-bold;
       line-height: 2rem;
       color: $color-black;
   }

   .icon-card__link {
       font-size: 1.25rem;
       color: $color-blue-alt;
       margin-top: auto;
       font-weight: $fontWeight-bold;
   }

   .icon-card a {
        font-size: 1.25rem;
        color: $color-blue-alt;
        font-weight: $fontWeight-bold;
   }

/* ================================================================
Blue Box
================================================================ */
.blue-box {
    background: $color-pastel-blue;
    padding: 1.5rem;
    margin-bottom: .5rem;
}

.blue-box__title {
    display: block;
    font-size: 1.75rem;
    font-weight: $fontWeight-bold;
    color: $color-font;
    margin-bottom: 1rem;
}

.blue-box__text {
    margin-bottom: 1rem;
}

.blue-box__link {
    color: $color-blue-alt;
    font-weight: $fontWeight-bold;
}

/* ================================================================
Grey Box
================================================================ */
.grey-box {
    background: $color-advisor-grey;
    padding: 1.5rem;
    margin-bottom: .5rem;
}

.grey-box__title {
    display: block;
    font-size: 1.75rem;
    font-weight: $fontWeight-bold;
    margin-bottom: 1rem;
}

.grey-box a {
    color: $color-blue-alt;
    font-weight: $fontWeight-bold;
}


/* ================================================================
Blue Box CTA
================================================================ */
.blue-box-container {
    margin-top: 0 !important;
    padding-top: 0 !important;
}

.blue-box-cta {
    background: $color-pastel-blue;
}

.blue-box-cta__heading {
    border-bottom: 1px solid rgba(0, 118, 214, .374);
    padding: 1.5rem;
}

.blue-box-cta__title {
    color: $color-dark-blue;
    font-weight: $fontWeight-bold;
    font-size: 1.4rem;
    margin: 0;
}

.blue-box-cta__body {
    padding: 1.5rem;
    color: $color-dark-blue;
}

.blue-box-cta__link {
    font-weight: $fontWeight-bold;
    text-decoration: underline;
}


/* ================================================================
Multiple Card
================================================================ */
.card-multiple {
    img {
        margin-right: 20px;
        width: 50px;
    }
}

.card-multiple__top {
    padding: 30px;
    background: $color-white-smoke;

    a {
        color: $color-black;
        font-size: 1.125rem;
        position: relative;
    }
}

.card-multiple__body {
    padding: 30px;
    background: $color-invert;

    a {
        display: block;
        margin-bottom: 0.5rem;
        text-decoration: underline;
    }
}

/* ================================================================
Flex Card
================================================================ */
.card-flex {
    display: flex;
    align-items: flex-start;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 30px;

    img {
        margin-right: 30px;
    }
}

/* ================================================================
Blue Callout Card
================================================================ */
.blue-callout {
    background: $color-invert;
    text-align: center;
    margin-bottom: 2rem;
}

.blue-callout__title {
    display: block;
    font-size: 3.4375rem;
    color: $color-blue-alt;
    font-weight: $fontWeight-bold;
}

.blue-callout__text {
    font-size: 1.25rem;
    position: relative;
    padding-top: 8px;
    margin-top: 8px;

    &:before {
        content: '';
        display: block;
        height: 1px;
        width: 30px;
        background: $color-blue-alt;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
    }
}