/* ================================================================
   News
   ================================================================ */
.news {
    display: flex;
    flex-flow: row wrap;

    .news-item {
        display: flex;
        flex-flow: column;
        width: 100%;
        padding: 0.8em;

        .item-text {
            height: 100%;
            background-color: $color-invert;
        }
        .item-content {
            padding: 1em;
            .title {
                display: block;
                margin: 0.3em 0;
                font-size: 1.25rem;

                @media(min-width: $bp-mw) {
                    font-size: 1.5rem;
                }
            }

            .btn-box {
                margin-top: 2em;
            }
        }

        .news-link {
            background: $color-invert;
            padding: 1em;
        }

        .item-date {
            font-size: 14px;
            font-weight: $fontWeight-bold;
            color: $color-green;
        }

        .summary {
            font-style: normal;
            font-weight: $fontWeight-normal;
        }

        .news-background-image {
            height: 250px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            margin: 0;
        }
    }
}

@media(min-width: $bp-m) {
    .news {
        .news-item {
            width: 50%;
        }
    }
}

@media(min-width: $bp-l) {
    .news {
        .news-item {
            width: 33.33%;
        }
    }
}

.news.detail {
    margin-bottom: 2em;

    .breadcrumb {
        margin-bottom: 2.5em;
    }

    .news-header {
        width: 100%;
        height: 500px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 2em;
    }

    .item-date {
        margin-bottom: 1em;
        color: $color-green;
    }

}


.news-slider {
    padding: 0 3em;
    .slick-next, .slick-prev {
        top: 30%;
        height: 25px;
        width: 25px;
        border-color: $color-secondary;
        border-width: 4px 4px 0 0;
    }

    .slick-next {
        right: 0;
    }

    .slick-prev {
        left: 0;
    }

    .news-item {
        padding: 0.8em;
        text-align: center;

        .title {
            @include x-rem("font-size", 20px);
            text-align: center;
            color: $color-font;
            margin: 0.5em 1em;
        }
    }
    .news-background-image {
        height: 200px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-bottom: 3px solid $color-secondary;
    }
}

.news__listing {
    .news__item {
        margin-top: 2em;
        border-bottom: 1px solid $color-warm-grey;
        padding: 1em;
    }

    .news__date {
        color: $color-primary;
        font-weight: $fontWeight-bold;
        margin-bottom: 0.5em;
    }

    .title {
        margin-bottom: 0;
        line-height: 40px;
        a {
            color: $color-blue;
        }

        a:hover, a:focus {
            text-decoration: none;
            color: $color-dark-blue;
        }
    }

    .news__summary {
        font-weight: 400;
        font-style: normal;
        margin: 0.5em 0;
    }

    .news__link {
        margin-top: 1em;
    }

    .link {
        color: $color-primary;
        font-weight: $fontWeight-bold;

        &:hover {
            color: $color-dark-blue;
            text-transform: none;

            &:after {
                margin-left: 2.5em;
            }
        }

        &:after {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            border-top: 2px solid $color-primary;
            border-right: 2px solid $color-primary;
            margin-left: 2em;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            transition: 0.2s all linear;
        }
    }
}

@media(min-width: $bp-m) {
    .news__listing{
        .news__item {
            display: flex;
            flex-flow: row wrap;
        }

        .news__media {
            width: 20%;
            margin-right: 2em;
        }

        .news__content {
            width: 75%;
        }
    }
}
